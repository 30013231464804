.popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10;
}

.glass {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.popup_content {
  background-color: var(--navBg);
  padding: 12px 0px 20px 0px;
  border: 1px solid var(--border-dark);
  border-radius: 8px;
  grid-gap: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 24px rgba(104, 118, 132, 0.06);
  backdrop-filter: blur(12px);
}
