@import "zxStyles/functions.scss";
.avatar {
  width: 112px;
  height: 112px;
  border-radius: 20px;
  border: 6px solid cv(background);
}
.dark {
  border: 6px solid cv(border-dark);
}
.image {
  width: 100px;
  height: 100px;
  border-radius: 16px;
}
