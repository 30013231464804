.login_popup {
  min-height: 270px;
  width: 224px;
  position: fixed;

  background-color: var(--base);
  border: 1px solid var(--border-dark);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  flex-direction: column;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 130%;
  /* or 19px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.logo {
  height: 40px;
  width: auto;
  margin-top: 8px;
  margin-bottom: 16px;
}

.list {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);

  padding-inline-start: 16px;
}

.grow {
  flex-grow: 1;
}
