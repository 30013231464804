.net_worth_card {
  position: relative;
  background-size: cover;
  width: 240px;
  height: 160px;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  background-image: url("assets/png/graph_bg.png");
  border: 1px solid var(--border-dark);
  border-radius: 4px;
  flex-shrink: 0;
}

.middle {
  flex-grow: 1;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
  /* identical to box height, or 13px */

  letter-spacing: 0.08em;
  text-transform: uppercase;

  /* Text II */

  color: var(--text-2);
}

.value_number {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.value {
  margin-top: 3px;
}
