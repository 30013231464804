@import "zxStyles/functions.scss";
.infoPill {
  background-color: cv(elevation-2);
  padding: 8px;
  border-radius: 8px;
  width: 150px;
}

.graphContainer {
  display: flex;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;
}

.floorPriceContainer {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid cv(border-dark);
}

.infoPills {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 8px;
  column-gap: 8px;
}
