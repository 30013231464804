.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  /* or 22px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);

  grid-gap: 4px;
}

.mini_profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 8px;
  margin-left: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  /* or 22px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
  width: fit-content;
}

.image {
  height: 20px;
  width: 20px;
  margin-right: 8px;
  border-radius: 50%;
}

.selected_edge {
  padding: 22px;
  overflow-y: scroll;
}

.divider {
  background: #687684;
  opacity: 0.1;
  width: 100%;
  height: 1px;
}

.summary {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.address {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.value {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  text-align: right;
  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.bottom {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  text-align: right;
  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.table_info {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.nft_image {
  height: 20px;
  width: 20px;
  border-radius: 4px;
  margin-right: 4px;
  margin-left: 4px;
}

.nft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.nft_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  text-align: right;
  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.relationship_data {
  margin-top: 12px;
}

.miniProfileText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
