@import "zxStyles/functions.scss";
.container {
  display: flex;
  align-items: flex-start;
  column-gap: 8px;
}
.avatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.commentColumn {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;
  overflow: visible;
}

.comment {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  background-color: cv(elevation-1);
  width: 100%;
  border-radius: 8px;
  border-top-left-radius: 0;
  overflow-y: visible;
  overflow-x: visible;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 100%;
  row-gap: 4px;
}

.engagements {
  margin-left: 12px;
  margin-top: 4px;
  width: 100%;
  display: flex;
  row-gap: 12px;
  align-items: center;
  column-gap: 4px;
}

.padtop {
  padding-top: 12px;
}

.childComments {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.commentActions {
  margin-top: 4px;
  padding: 0 16px;
}

.toggleContainer {
  // margin-left: -18px;
  z-index: 2;
}
