.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  /* identical to box height, or 26px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.notificationTile {
  cursor: pointer;
}

.icon_with_chain {
  position: relative;
}

.notificationDot {
  border-radius: 9px;
  border: 2px solid var(--base);
  background: var(--error);

  font-size: 12px;
  font-weight: 600;
  color: white;
  padding: 2px 10px;
  flex-shrink: 0;
  margin-top: -2px;
  border-radius: 14px;
}

.mounted {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 2px 6px;
  font-size: 9px;
  margin-top: 0;

  @media screen and (min-width: 1400px) {
    display: none;
  }
}

.tileTitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.highlighted {
  font-weight: 500;
}

.highlighted:hover {
  text-decoration: underline;
  cursor: pointer;
}

.tileSubtitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);

  /* avoid text overflow */
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
  overflow: hidden;
  max-width: 100%;
}

.titleWrap {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
}

.sourcedByAvatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.profiles {
  display: flex;
  flex-direction: column;
}

.profileInitial {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
}
.profileInitial img,
.profileSeries img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.profileSeries {
  width: 30px;
  height: 30px;
  position: absolute;
  margin-left: 20px;
  border-radius: 50%;
  z-index: 2;
  border: 1px solid var(--base);
}

.notifications {
  padding-bottom: 40px;
}

.noNotifications {
  color: var(--text-2);
  font-size: 15px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* or 20px */

  letter-spacing: normal;
}
