.explorer_card_container {
  position: relative;
  width: 100%;
  height: 180px;
  display: flex;
  flex-grow: 0;

  min-width: 0;
}

.graph_container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.3;
}

.explorer_card {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  padding: 8px 8px;
  border-radius: 4px;
  border: 1px solid var(--border-dark);
  animation: all 0.2s ease;
}

.explorer_card:hover {
  background-color: rgba(6, 72, 215, 0.03);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.04);
  border: 1px solid var(--card-hover);
}

.explorer_card_wrapper {
  border-radius: 4px;
  width: 265px;
}

.subtitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: normal;
  color: var(--text-3);
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 130%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: var(--text-2);
  display: inline;
  background: var(--base-80);
}

.value {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: normal;
  color: var(--text-1);
  background: var(--base-80);
  display: inline;
  padding-right: 8px;
}

.viewExplorerText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: normal;
  color: var(--text-2);
}

.graphBottomText {
  background: var(--base);
  display: inline;
}

@media (max-width: 768px) {
  .explorer_card {
    flex-shrink: 0;
  }
}
