.checkbox {
  position: relative;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: #ffffff;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.checkbox::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-image: url("assets/svg/checkbox_selected.svg");
  background-size: cover;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.checkbox:not([disabled])::before {
  background-image: url("assets/svg/checkbox_selected.svg"); /* Replace with your toggleable image */
}

.checkbox:checked:not([disabled])::before {
  opacity: 1;
}

.checkbox:not(:checked) {
  border: 2px solid rgba(104, 118, 132, 0.15);
}

.checkbox:disabled::before {
  background-image: url("assets/svg/checkbox_disabled.svg"); /* Replace with your non-toggleable image */
  opacity: 1;
}
