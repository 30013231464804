.nftHoldings {
  font-family: Roboto;
  display: flex;
  flex-direction: column;
  background: var(--base);
  border: 1px solid var(--border-dark);
  border-radius: 6px;
  font-family: Roboto;
}

.nftCollectionItem {
  margin-bottom: 40px;
}
.nftItems {
  display: grid;
  grid-template-columns: repeat(auto-fill, 174px);
  grid-auto-rows: minmax(0, 1fr);
  gap: 20px;
}

.footerWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}
.footer {
  display: flex;
  flex-shrink: 0;
  gap: 8px;
  font-size: 11px;
  letter-spacing: normal;
  padding: 16px 0;
}

.footer .label {
  font-weight: 400;
  font-size: 11px;
  color: var(--text-2);
}

.footer .buttonLabel {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: Roboto;
  font-size: 11px;
  font-weight: 600;
  padding: 0;
  background: none;
  color: var(--text-2);
}
.footer .arrow {
  font-weight: 600;
  display: flex;
  align-items: center;
}
.divider {
  border-bottom: 1px solid var(--border-dark);
  height: 1px;
  width: 100%;
  display: flex;
  align-items: center;
}
.nftItemsWrapper {
  padding: 8px 0;
}
@media (max-width: 768px) {
  .nftItemsWrapper {
    flex-direction: column;
    width: 100%;
  }
}

.scrollContainer {
  display: "flex";
  overflow: "auto";
  width: "270px";
  padding: "0";
  gap: "12px";
}
.scrollContainer::-webkit-scrollbar {
  display: none;
}
