.box {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-left: 22px;
  cursor: pointer;
}

.headerRow {
  display: flex;
  flex-direction: row;
  gap: 16px;
  border-bottom: 1px solid var(--border-dark);
  padding: 0px 20px 0px 20px;
}

.headerByText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.text {
  padding-left: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  text-align: right;
  letter-spacing: normal;
  /* Text II */
  color: var(--text-2);
}

.chainIcon {
  height: 10px;
  width: 10.5px;
}

.icon {
  /* height: 30px; */
  width: 12px;
  font-size: 13px;
  filter: invert(51%) sepia(9%) saturate(804%) hue-rotate(169deg)
    brightness(86%) contrast(83%);
}

.chainIcon {
  height: 10px;
  width: 10.5px;
  filter: invert(51%) sepia(9%) saturate(804%) hue-rotate(169deg)
    brightness(86%) contrast(83%);
}

.buttonPadding {
  padding: 16px 20px 20px 20px;
  backdrop-filter: blur(5px);
}

.horizontalPadding {
  padding-left: 20px;
  padding-right: 20px;
}

.formPadding {
  padding: 17px 0px 0px 0px;
  flex-direction: column;
  display: flex;
  height: 341px;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 150%;
  /* identical to box height, or 16px */

  letter-spacing: 0.08em;
  text-transform: uppercase;

  /* Text II */

  color: var(--text-2);
}

.subtitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;
  /* identical to box height, or 16px */

  text-align: right;
  letter-spacing: normal;

  /* Text III */

  color: var(--text-3);
}
.appliedFiltersList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;
  gap: 8px;
}

.chainFiltersList {
  padding-top: 16px;
  overflow: auto;
  padding-bottom: 20px;
}

.scrollList {
  height: 300px;
  overflow-y: scroll;
}
