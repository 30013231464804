.shimmer-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: loading 1s infinite;

  &__shimmer {
    width: 50%;
    height: 100%;
    background: var(--elevation-3);
    transform: skewX(-20deg);
    box-shadow: 0 0 30px 30px var(--elevation-2);
  }
}

@keyframes loading {
  0% {
    transform: translateX(-150%);
  }
  50% {
    transform: translateX(-60%);
  }
  100% {
    transform: translateX(150%);
  }
}
