.nftItem {
  flex-shrink: 0;
  background: var(--base);
  border: 1px solid var(--border-dark);
  border-radius: 4px;
  overflow: hidden;
}

.infoWrapper {
  padding: 8px;
  flex-grow: 1;
  /* background: var(--base); */
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: baseline;
}
.infoWrapper.centerAlign {
  justify-content: center;
}
.title {
  font-family: Roboto;
  font-size: 13px;
  line-height: 17px;
  font-weight: 600;
  letter-spacing: normal;
  color: var(--text-1);
  background: none;
  padding: 0;
  text-align: left;
  display: flex;
  width: 100%;
}
.truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
}
.subTitle {
  font-size: 11px;
  line-height: 14px;
  font-weight: 400;
  letter-spacing: normal;
  color: var(--text-2);
  width: 100%;
}

.rightPane.nftItem {
  display: flex;
  width: 100%;
  height: 56px;
}

.rightPane.extraNfts.infoWrapper {
  flex-direction: row;
  gap: 8px;
  background-color: none;
}
.rightPane.extraNfts.infoWrapper .title {
  font-size: 11px;
  font-weight: 400;
  color: var(--text-2);
}
@media (max-width: 768px) {
  .nftItem {
    display: flex;
    width: 100%;
    height: 56px;
  }
}

.externalLinkButton {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-2);
}

.iconWrapper {
  padding: 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconWrapper:hover {
  background-color: var(--elevation-2);
}
