.pricesContainer {
  background-color: var(--elevation-1);
  display: flex;
  border-radius: 8px;
}

.borderLeft {
  border-left: 1px solid var(--elevation-2);
}

.pricePill {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 8px;
  width: 68px;
}

.pillsContainer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 16px;
}

.detailsPill {
  flex: 45%;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  background-color: var(--elevation-1);
  border-radius: 8px;
  padding: 12px;
}

.addressContainer {
  background-color: var(--elevation-1);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;
}

.deployerImage {
  width: 24px;
  height: 24px;
  border-radius: 24px;
}
