.left_side_bar {
  height: 100vh;
  border-right: 1px solid var(--border-dark);
  padding: 16px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  background: var(--base);
  width: 250px;
  position: fixed;
  z-index: 7;
}

.links {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.left_side_bar_header {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;
}

.left_side_bar_tile {
  display: flex;
  padding: 8px;
  flex-direction: row;
  cursor: pointer;
  transition: width 0.3s ease-in-out;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
}

.divider {
  background-color: rgba(35, 36, 39, 0.1);
  width: 24px;
  height: 1px;
}

.appIcon {
  width: 40px;
  height: 40px;
  margin-left: 0px;
}

.active_bar {
  position: absolute;
  width: 3px;
  height: 24px;
  left: 0px;
  top: 10px;
  margin-top: 10px;
  background: #0648d7;
}

.icon {
  width: 200px;
  height: 20px;
  fill: #0648d7;
}

.left_side_bar_tile_text {
  position: relative;
  width: 100%;
  text-align: left;
  display: flex;
}

.progressCircle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notificationDot {
  border-radius: 9px;
  border: 2px solid var(--base);
  background: var(--error);

  font-size: 12px;
  font-weight: 600;
  color: white;
  padding: 2px 10px;
  flex-shrink: 0;
  margin-top: -2px;
  border-radius: 14px;
}

.secondaryDot {
  background-color: var(--elevation-3);
  color: var(--text-3);
  border-color: transparent;
}
.minimize {
  width: 70px;
}
.minimize .left_side_bar_tile:has(.notificationDot) {
  position: relative;
}
.minimize .notificationDot {
  position: absolute;
  top: 2px;
  right: 0;
  font-size: 9px;
  padding: 2px 6px;
}
.minimize .left_side_bar_tile_text {
  display: none;
}
.minimize .logo_text {
  display: none;
}
.minimize .smallScreenHidden {
  display: none !important;
}

@media (max-width: 768px) {
  .left_side_bar {
    z-index: 6;
    height: 52px;
    width: 100vw;
    bottom: 0;
    right: 0;
    left: 0;
    top: auto;
    border-top: 1px solid var(--border-dark);
    flex-direction: column-reverse;
    justify-content: center;
  }
  .icon_tray {
    flex-direction: row;
    margin: 0;
  }
  .links {
    flex-direction: row;
    justify-content: space-evenly;
  }
  .mobileHidden {
    display: none;
  }
}
