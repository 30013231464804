.borderTop {
  border-top: 1px solid var(--border-dark);
}

.tabbar {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  background-color: var(--base);
  border-bottom: 1px solid var(--border-dark);
  position: sticky;
  top: 52px;
  z-index: 3;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
