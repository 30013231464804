.galleryContainer {
  outline: none;
  background: none;
  backdrop-filter: blur(0px);
}
.galleryModal {
  background: rgba(0, 0, 0, 0);
  color: var(--text-1);
  display: flex;
  height: 100%;
  position: relative;
  overflow: hidden;
  font-family: Roboto;
  justify-content: center;
  align-items: center;
}
.modalContent {
  border-radius: 8px;
  border: 1px solid var(--border-dark);
  background: var(--base);
  max-height: 90vh;
  width: 475px;
  overflow: auto;
}
.title {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  flex-grow: 1;
  padding: 20px;
}
.titleRow {
  border-bottom: 1px solid var(--border-dark);
}

.downloadAppButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
  border-radius: 24px;
  height: 32px;
  width: 32px;
  color: var(--text-2);
  background: var(--elevation-1);
  border: 1px solid var(--border-dark);
  cursor: pointer;

  &:hover {
    background: var(--elevation-2);
  }
}
