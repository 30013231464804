.container {
  min-width: 300px;
  padding: 16px;
}

.image {
  border-radius: 50%;
  height: 48px;
  width: 48px;
  object-fit: cover;
}

.tokenImage {
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  /* identical to box height, or 26px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}
.subtitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.bio {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* or 22px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.up_icon {
  width: 9px;
  height: 6px;
  transform: rotate(180deg);
  fill: var(--success);
}

.down_icon {
  width: 9px;
  height: 6px;
  fill: var(--error);
}

.valueChange {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  /* identical to box height, or 16px */

  letter-spacing: normal;

  /* Success */

  color: var(--success);
}

.valueChangeDown {
  color: var(--error);
}

.valueChangeUp {
  color: var(--success);
}
