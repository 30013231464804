.connectButton {
  font-family: "Roboto";
  height: 38px;
  width: auto;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: var(--elevation-2);
  color: var(--text-2);
  cursor: pointer;
  position: relative;
  font-size: 13px;
  font-weight: 500;
  padding: 6px 12px;
  color: var(--primary-color);
}

.requestPending {
  /* content: "!"; */
  background-color: var(--warning-light);
  color: var(--warning);
}
.requestAccepted {
  /* content: '\10003'; */
  background-color: var(--success-light);
  color: var(--success);
}

.count {
  padding: 2px 8px;
  border-radius: 8px;
  font-size: 10px;
  background-color: var(--error);
  color: var(--text-white);
  margin-left: 4px;
}
