.trending_query {
  padding: 20px 16px;
  background-color: var(--base);
  border: 1px solid var(--border-dark);
  border-radius: 6px;
  width: 392px;
}

.heading {
  padding-bottom: 18px;
  color: var(--text-1);
  font-size: 15px;
  font-family: Roboto;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
