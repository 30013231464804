.ghost {
  background: var(--elevation-3);
  overflow: hidden;
  margin: 10px 0;
  border-radius: 4px;
  &__text {
    width: 100%;
    height: 12px;
    margin-bottom: 2px;
  }
  &__label {
    display: inline-block;
    width: 50%;
    height: 18px;
    margin-bottom: 3px;
  }
  &__circle {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }
  &__user-image {
    min-width: 64px;
    height: 64px;
    border-radius: 0px;
  }
  &__mini-block {
    width: 40px;
    height: 14px;
  }
}
