.cancelButton {
  border-radius: 4px;
  background: var(--elevation-1);
  /* Border Dark */
  border: 1px solid var(--border-dark);
  width: 100%;
  height: 36px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  text-align: center;
  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.errorButton {
  border-radius: 4px;
  background: var(--error);
  /* Border Dark */
  border: 1px solid var(--border-dark);
  width: 100%;
  height: 36px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  text-align: center;
  letter-spacing: normal;

  /* Text II */

  color: white;
}

.rounded {
  width: max-content;
  border-radius: 40px;
  padding: 4px 16px;
}

.roundedSmall {
  width: max-content;
  border-radius: 20px;
  padding: 2px 8px;
  height: auto;
}

.popupButton {
  background: var(--primary-color);
  border-radius: 4px;
  width: 100%;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  text-align: center;
  letter-spacing: normal;

  /* Text White */

  color: #ffffff;

  height: 36px;
}

.popupButton:disabled {
  opacity: 0.5;
}

.disabled {
  opacity: 0.5;
}

.removeAllButton {
  border: 1px solid var(--border-dark);
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  padding: 0px 8px 0px 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 28px;
}

.removeAllText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 130%;
  /* or 14px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.primaryButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  background: var(--primary-color);
  border-radius: 40px;
  padding: 4px 16px 4px 16px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  /* height: 36px; */
  line-height: 150%;
  /* identical to box height, or 16px */

  text-align: center;

  /* Text White */

  color: #ffffff;
}

.primaryButton:disabled {
  cursor: auto;
  opacity: 0.5;
}

.transparentButton,
.transparentLinkButton {
  background: transparent;
  border-radius: 40px;
  padding: 4px 8px 4px 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  text-align: center;
}

.transparentButton:disabled,
.transparentLinkButton:disabled {
  cursor: auto;
  opacity: 0.5;
}

.transparentLinkButton {
  display: flex;
  gap: 8px;
  color: var(--primary-color);
  text-decoration: none;
  align-items: center;
  justify-content: center;
}

.secondaryButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: white;
  border-radius: 50%;
  background: linear-gradient(180deg, #ee57ac 0%, #ee577b 100%);
}
