.redirectContainer {
  /* padding-top: 40vh;
  height: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 24px; */
  margin-top: 40vh;
}
