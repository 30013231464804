@import "zxStyles/functions.scss";
.priceContainer {
  border: 1px solid cv(border-dark);
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
}
.priceChange {
  padding: 8px;
  border-right: 1px solid cv(border-dark);
  width: 100%;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.last {
  border-right: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.first {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.activeGreen {
  background-color: cv(success-light);
}
.activeRed {
  background-color: cv(error-light);
  //   background-color: cv(success-light);
}
