.switchLabel {
  font-family: "Roboto";
  font-size: 13px;
  line-height: 150%;
  letter-spacing: normal;
}
.switchUnselectedText {
  font-weight: 400;
  color: var(--text-3);
  font-size: 13px;
}

.switchSelectedText {
  font-weight: 600;
  color: var(--text-2);
  font-size: 13px;
}

.disabled {
  opacity: 0.5;
}
