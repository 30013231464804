.graph_component_refetch {
  width: 100%;
  display: flex;
  align-items: center;
}

.graph {
  border-radius: 4px;
}

.graphLoader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdownButton {
  cursor: pointer;
  color: var(--text-2) !important;
  z-index: 10;
}

.dropdownList {
  width: 44px !important;
  z-index: 10;
}
