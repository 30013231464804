.footer {
  display: flex;
  gap: 8px;
  border-top: 1px solid var(--border-dark);
  font-size: 13px;
  letter-spacing: normal;
  padding: 16px 20px 0 20px;
}

.footer .label {
  font-weight: 400;
  font-size: 13px;
  color: var(--text-2);
}

.footer .buttonLabel {
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 600;
  padding: 0;
  background: none;
  color: var(--text-2);
}
.footer .arrow {
  display: flex;
}
