@import "zxStyles/functions.scss";
.container {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  border-radius: 8px;
}

.profit_dark {
  background: linear-gradient(
    90deg,
    rgba(7, 157, 57, 0.084) 0%,
    rgba(7, 157, 57, 0.28) 100%
  );
}

.profit_light {
  background: linear-gradient(
    90deg,
    rgba(7, 157, 57, 0.084) 0%,
    rgba(7, 157, 57, 0.28) 100%
  );
}

.loss_dark {
  background: linear-gradient(
    90deg,
    rgba(226, 76, 76, 0.108) 0%,
    rgba(226, 76, 76, 0.36) 100%
  );
}

.loss_light {
  background: linear-gradient(
    90deg,
    rgba(226, 76, 76, 0.084) 0%,
    rgba(226, 76, 76, 0.28) 100%
  );
}
