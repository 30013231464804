.actorImage {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}

.actorName {
  cursor: pointer;
  transition: all 0.1s ease;
}
.actorName:hover {
  text-decoration: underline;
}
.actorCell {
  display: flex;
  align-items: center;
  column-gap: 4px;
  justify-content: space-between;
  width: 100%;
}

.userHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.filterIcon {
  cursor: pointer;
}
.filterIcon:hover {
  color: var(--text-1);
}
.filterInput {
  flex: 1;
  height: 36px;
  width: 100%;
  padding: 8px 12px;
  border: 1px solid var(--border-light);
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  color: var(--text-1);
  background-color: var(--elevation-1);
  transition: all 0.2s ease;
}

.filterInput:focus {
  border: 1px solid var(--border-dark);
  background-color: var(--base);
}

.filterButtons {
  padding: 8px 16px;
}

.rowsContainer {
  height: 300px;
  min-height: 300px;
  overflow: auto;
}

.searchpill {
  background-color: var(--elevation-2);
  border-radius: 12px;
  padding: 4px 8px;
  color: var(--text-2);
  cursor: pointer;
  overflow: auto !important;
}

.searchContainer {
  position: relative;
  min-height: auto;
}

.searchResults {
  width: 100%;
  max-height: 250px;
  overflow: scroll;
}
