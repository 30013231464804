.net_worth_card {
  display: flex;
  flex-direction: column;
}

.container_class {
  width: 240px;
  height: 160px;
  background-image: url("assets/png/graph_bg.png");
  border: 1px solid var(--border-dark);
  border-radius: 4px;
}

.middle {
  flex-grow: 1;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
  /* identical to box height, or 13px */

  letter-spacing: 0.08em;
  text-transform: uppercase;

  /* Text II */

  color: var(--text-2);
}

.value_number {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.value {
  margin-top: 3px;
}

.top {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  align-items: center;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  letter-spacing: 0.08em;
  text-transform: uppercase;

  /* Text II */

  color: var(--text-2);
}

.bar {
  height: 1px;
  background: var(--text-2);
  opacity: 0.1;
  flex-grow: 1;
  margin-left: 16px;
}

.ghost {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  margin-top: 24px;
}
