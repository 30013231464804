.lendingTitle {
  display: flex;
  justify-content: space-between;
}

.moreInfo {
  display: flex;
  color: var(--text-3);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: normal;
}
.healthFactor::after {
  content: " • ";
  margin: 0 5px;
}
.apy.borrowed {
  color: var(--error);
}
.positiveApy {
  color: var(--success);
}
.negativeApy {
  color: var(--error);
}
