.tokenLiquidity {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px 20px;
  background: var(--base);
  border-radius: 6px;
  font-family: Roboto;
}
.createdAt {
  white-space: nowrap;
}
.poolImage {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  overflow: hidden;
}
.overlap {
  margin-right: -5px;
  z-index: 1;
}
