.searchInputContainer {
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  border-radius: 4px;
  margin-bottom: 8px;
  background: var(--base);
  border: 1px solid var(--border-dark);
}
.searchInputContainer.focus {
  border-color: var(--primary-color);
}
.searchInputContainer .searchInput {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
}
.searchInputContainer .searchInput .searchIcon {
  width: 13px;
  height: 13px;
}
.searchInputContainer .searchInput input {
  border: none;
  background: transparent;
  width: 100%;
  color: var(--text-1);
}
.searchInputContainer .searchInput input,
.searchInputContainer .searchInput input::placeholder {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: normal;
}
.searchInputContainer .searchInput input::placeholder {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}
.searchInputContainer .searchInput input:focus {
  outline: none;
}
.searchInputContainer .searchOptions {
  display: flex;
  align-items: center;
  gap: 17px;
}
.searchInputContainer .searchOptions .shortcutKey {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
  text-align: right;
  letter-spacing: normal;
  color: var(--text-3);
}
.searchInputContainer .searchOptions .advancedFiltersButton {
  padding: 0;
  background: transparent;
}
.searchInputContainer .searchOptions .advancedFiltersButton .filtersIcon {
  height: 12px;
  width: 12px;
  display: flex;
}
