@import "zxStyles/functions.scss";
.cardsContainer {
  display: flex;
  column-gap: 16px;
  padding: 0 16px;
  overflow-x: auto;
  width: 100%;
}

.cardsContainer::-webkit-scrollbar {
  display: none;
}
.link {
  z-index: 0;
}
.card {
  z-index: 0;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.infoContainer {
  display: flex;
}

.info {
  width: 100%;
  border-right: 1px solid cv(border-dark);
  padding: 0;
}

.info:last-child {
  border-right: none;
}

.verticalContainer {
  background-color: cv(base);
}

.priceBar {
  width: fit-content;
}
