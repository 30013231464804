.invite {
  margin: 16px 0 16px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 72px;
  height: 72px;
  border: 1px solid var(--border-light);
  position: relative;
}

.inviteCounter {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--error);
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50%;
}

.summary {
  background-color: var(--elevation-1);
  padding: 16px 20px;
  margin: 16px 0;
}

.rightPanel {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 37.5%;
  background: var(--elevation-1);
  border-radius: 10px;
  padding-bottom: 86px;
  position: fixed;
  overflow: auto;
}

.header {
  display: flex;
  flex-direction: column;
  background: var(--base);
  border-bottom: 1px solid var(--elevation-2);
  padding: 24px 20px 24px 20px;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  padding-bottom: 20px;
  letter-spacing: normal;
  color: var(--text-1);
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: var(--text-2);
}

.rankInfo {
  line-height: 18px;
}

.claimBanner {
  position: relative;
  background-color: var(--elevation-1);
  border-radius: 8px;
  color: var(--text-1);
  margin-bottom: 12px;
  font-size: 14px;
}

.claimButton {
  padding: 8px 16px;
  border-radius: 16px;
  min-width: 120px;
  line-height: 16px;
  font-size: 13px;
  background-color: var(--text-1);
  opacity: 0.95;
}

.copyBtn.copied {
  background-color: var(--border-light);
  color: var(--text-2);
}

.copyBtn {
  transition: all 0.3s ease;
}
