.modalContainer {
  position: absolute;
  top: 36%;
  left: calc(50% + 25px);
  transform: translate(-61%, -50%);
  width: 600px;
  background-color: var(--base);
  font-family: Roboto;
  border-radius: 6px;
}

.modalTitleWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 16px 20px 0;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--border-dark);
  position: relative;
}
.close {
  display: flex;
  background: none;
  padding: 0;
}
.close svg {
  stroke: var(--text-1);
}
.title {
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: normal;
  color: var(--text-1);
}
.modalContentWrapper {
  margin: 16px 20px;
}

@media (max-width: 768px) {
  .modalContainer {
    width: 95vw;
    left: 50%;
  }
}
