.container {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  width: calc(100vw - 250px);

  @media (max-width: 768px) {
    width: 100vw;
  }
  @media (max-width: 1400px) {
    width: calc(100vw - 70px);
  }
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: normal;
  color: var(--text-1);
}

.subtitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.headerQueryRow {
  padding-top: 8px;
  border-bottom: 1px solid var(--border-dark);
}

.editColumnIcon {
  width: 12.5px;
  height: 10px;
}

.editColumnsText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  text-align: right;
  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.cursorDiv {
  cursor: pointer;
}

.author_profile {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.author_link {
  display: flex;
  flex-direction: row;
  grid-gap: 2px;
  align-items: center;
}

.filterBadge {
  background: var(--primary-color);
  width: auto;
  height: 14px;
  color: var(--base);
  font-size: 12px;
  font-weight: 600;
  border-radius: 20px;
  padding: auto 8px;
}
