.container {
  width: 800px;
  min-height: calc(100vh - 52px);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-left: -330px;
  /* margin-left: -240px; */
  /* margin-right: -160px; */
}

.pageContent {
  border: 1px solid var(--elevation-2);
  overflow-x: auto;
  width: 100%;
  overflow-y: scroll;
  margin-top: 16px;
  padding-top: 8px;
  background-color: var(--base) !important;
  min-height: calc(100vh - 48px);
  border-radius: 12px;
}
.loadingContainer {
  margin-top: 0;
  padding-top: 0;
}
.rightBar {
  position: fixed;
  top: 64px;
  right: 0;
  width: 320px;
  border-radius: 8px;
  border-top-right-radius: 0;
  height: calc(100vh - 52px);
  overflow-y: scroll;
}

.whiteBg {
  background-color: var(--base);
  border: 1px solid var(--elevation-2);
}

/* loader */
.loader-container {
  display: grid;
  grid-template-columns: 7fr 3fr;
  height: 98vh;
  width: 100%;
  /* margin-left: -160px; */
}

.loader-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* background-color: red; */
}

.loader-header-buttons {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.loader-left,
.loader-right {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  border-right: 1px solid var(--elevation-2);
  padding-right: 12px;
}

.loader-tabs {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.loader-charts {
  height: 380px;
}

.loader-charts .loader-chart {
  transform: none;
  --webkit-transform: none;
}

/* popup */

.icon_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border-right: 1px solid var(--border-dark);
  padding-right: 8px;
}

.edit_icon {
  margin-left: 8px;
}

.edit_form_heading {
  color: var(--text-1);
  font-size: 18px;
  font-family: Roboto;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: normal;

  margin-top: 10px;
}

.loader_button {
  width: 124px;
  height: 36px;
  border-radius: 18px;
}
