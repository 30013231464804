@import "zxStyles/functions.scss";
.container {
  display: flex;
  padding: 8px 0;
  border-radius: 8px;
  border: 1px solid cv(border-dark);
}

.pill {
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid cv(border-dark);
}

.pill:last-child {
  border-right: none;
}
