.errorWrapper {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid var(--border-dark);
  height: 48px;
  background: var(--base);
  margin-top: 24px;
}

.contentWrapper {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 12px 28px;
}
.errorIconWrapper {
  display: flex;
}
.errorIcon {
  height: 20px;
  width: 20px;
}
.errorMessage {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: normal;
  color: var(--error);
}
