.wrapper {
  background-image: url("assets/svg/404_bg.svg");
  height: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}
.content {
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 92px;
}

@media (max-width: 768px) {
  .wrapper {
    position: unset;
    margin-top: -60px;
  }
  .content {
    top: 17%;
    left: 50%;
    transform: translateX(-50%);
    gap: 0;
  }
}
