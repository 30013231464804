@import "zxStyles/functions.scss";
.container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 8px;
}

.trait {
  cursor: pointer;
  width: 49%;
  background-color: cv(elevation-2);
  border-radius: 8px;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
