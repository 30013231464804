.box {
  padding-bottom: 40px;
}

.text {
  padding-left: 60px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.table {
  display: flex;
  flex-direction: column;
  border-collapse: collapse;
  width: 100%;
}

.table .tableList {
  font-size: 10px;
  color: var(--text-2);
  letter-spacing: 0.08em;
}

.tableRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  gap: 16px;
}

.backgroundWhite {
  background: var(--base);
  padding-bottom: 12px;
  padding-top: 12px;
  padding-left: 40px;
}
.backgroundColor {
  background: var(--elevation-1);
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 40px;
}

.table .tableHeader {
  font-size: 10px;
  color: var(--text-2);
  letter-spacing: 0.08em;
  padding: 12px 0px;
  background: var(--elevation-1);
  border-top: 1px solid var(--border-dark);
  border-bottom: 1px solid var(--border-dark);
  padding-left: 40px;
}

.table .tableRow .tableCell {
  padding: 10px 0;
  color: var(--text-1);
}
.tableCell:nth-of-type(1) {
  flex-basis: 17.3%;
}
.tableCell:nth-of-type(2) {
  flex-basis: 32%;
}
.tableCell:nth-of-type(3) {
  flex-basis: 9.8%;
}
.tableCell:nth-of-type(4) {
  flex-basis: 16.6%;
}
.tableCell:nth-of-type(5) {
  flex-grow: 1;
}
.alignRight {
  text-align: right;
}
.alignLeft {
  text-align: left;
}

.avatar {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.profileRow {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
}

.textRow {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.index {
  padding-left: 20px;
  flex: 0 0 56px;
}

.headerText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 16px */

  text-align: right;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Text II */

  color: var(--text-2);
}
