.positionCategories {
  display: flex;
  gap: 4px 8px;
  height: 24px;
  overflow-x: auto;
  position: relative;
  flex-wrap: nowrap;
  text-overflow: ellipsis;
  white-space: nowrap;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
}
.positionCategory {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 4px;
  border: 1px solid var(--border-dark);
  border-radius: 4px;
  padding: 4px 6px;
  background: var(--elevation-1);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: normal;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.remainingItems {
  font-size: 11px;
  font-weight: 400;
  letter-spacing: normal;
  color: var(--text-3);
  align-self: center;
}

.label {
  text-transform: capitalize;
  flex-shrink: 0;
}
.textOverflow {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.positionPair {
  display: flex;
}
.positionPair .position:not(:last-child)::after {
  content: " + ";
  margin: 0 4px;
}
.position {
  display: flex;
  align-items: center;
  gap: 2px;
  flex-shrink: 0;
  color: var(--text-1);
}
.positionPair:not(:last-child)::after {
  content: ",";
  margin-right: 8px;
}
.position:only-child::after,
.position:last-child::after,
.positionPair:only-child::after,
.positionPair:last-child::after {
  content: none;
  margin-left: 0;
}
.position .icon {
  display: flex;
}
.position .icon img {
  height: 12px;
  width: 12px;
  border-radius: 50%;
}

@media (max-width: 768px) {
  .positionCategories {
    height: auto;
    max-width: 34vw;
  }
  .positionCategory {
    flex-wrap: wrap;
    height: auto;
  }
}
