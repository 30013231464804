.bioContent {
  width: 100%;
  max-width: 600px;
  text-align: center;
  padding-top: 40px;
}

.header {
  margin-bottom: 40px;
  text-align: left;
}

.mainTitle {
  font-family: "Catamaran", sans-serif;
  font-weight: 700;
  font-size: 28px;
  color: #1b1f22;
  margin: 0;
}

.subtitle {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #687684;
  margin: 8px 0 0 0;
}

.inputContainer {
  display: flex;
  width: 100%;
  margin-bottom: 40px;
}

.input {
  flex: 1;
  height: 48px;
  padding: 12px 16px;
  border: 1px solid transparent;
  border-radius: 12px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #1b1f22;
  background-color: var(--elevation-1);
  transition: all 0.2s ease;
}

.input:focus {
  border: 1px solid var(--primary-color);
  background-color: #fff;
  box-shadow: 0px 4px 8px 0px rgba(6, 72, 215, 0.12);
}

.pasteButton {
  margin-left: 16px;
  height: 48px;
  padding: 12px 16px;
  background: #0648d7;
  border: none;
  border-radius: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  cursor: pointer;
}

.pasteButton.disabled {
  background: #cccccc;
  cursor: not-allowed;
}

.continueButton {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0648d7;
  color: #fff;
  border-radius: 12px;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
  border: none;
  margin-top: 15px;
  border-radius: 12px !important;
  height: 48px !important;
  margin-bottom: 20px;
}

.pasteDisabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.noInviteCode {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 17px;
  color: #0648d7;
  background-color: var(--elevation-2);
  padding: 16px;
  text-decoration: none;
  border-radius: 8px;
  cursor: pointer;
}
