.stepsContainer {
  border-radius: 4px;
  border: 1px solid var(--border-dark);
  background: var(--base);
  overflow: hidden;
  padding: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.verticalDivider {
  width: 1px;
  background: var(--border-dark);
  height: 100%;
}

.step2Container {
  width: "100%";
  display: "flex";
  justify-content: "center";
  align-items: "center";
  display: flex;
  flex-direction: column;
}

.step2image {
  height: 64px;
}

.step3Container {
  width: "100%";
  display: "flex";
  justify-content: "center";
  align-items: "center";
}

.step3image {
  height: 194px;
}

.step1Info {
  color: var(--text-1);
  text-align: center;
  font-family: "Roboto";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normalpx;
}

.step2style1 {
  color: var(--text-2);
  text-align: center;
  font-family: "Roboto";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 17.6px */
  letter-spacing: normalpx;
}

.step2style2 {
  color: var(--text-1);
  font-family: "Roboto";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: normalpx;
  text-align: center;
}
