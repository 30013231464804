.createGroupButton {
  background-color: transparent;
  background-color: var(--primary-color);
  color: var(--text-white);
  border-radius: 4px;
  padding: 8px;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 13px;
  white-space: nowrap;
}

.createGroupButtonContainer {
  padding: 8px;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}
