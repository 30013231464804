.searchResultsContainer {
  position: absolute;
  top: 38px;
  border: 1px solid var(--border-light);
  padding: 4px 0;
  width: 100%;
  list-style: none;
  box-shadow: 0px 0px 16px var(--elevation-1);
  background: var(--base);
  border-radius: 4px;
}
.itemInfoWrapper {
  display: flex;
  /* flex-direction: column; */
  gap: 12px;
  /* align-items: center; */
}
.searchResultItem,
.showAllWrapper {
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  font-family: "Roboto";
}
.showAllWrapper {
  flex-direction: row;
  gap: 12px;
}

.searchResultItem .avatar,
.showAllWrapper .avatar {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 50%;
}
.searchResultItem .infoWrapper,
.showAllWrapper .infoWrapper {
  font-weight: 400;
  line-height: 17px;
  letter-spacing: normal;
}
.searchResultItem .infoWrapper .info {
  display: flex;
  gap: 8px;
}
.info {
  align-items: center;
}
.searchResultItem .infoWrapper .info .name {
  font-size: 13px;
  color: var(--text-1);
  letter-spacing: 0;
}
.searchResultItem .infoWrapper .info .meta {
  font-size: 11px;
  color: var(--text-2);
}
.matchedWrapper {
  display: flex;
  gap: 7px;
  color: var(--text-2);
  font-size: 11px;
  align-items: baseline;
  line-height: 14px;
}
.matchedWrapper:not(:first-of-type) {
  margin-top: 2px;
}

.matchedWrapper .matchedIcon {
  width: 15px;
  height: auto;
}

.matchedWrapper .matchedDetails {
  display: flex;
  gap: 4px;
}

.showAllWrapper {
  width: 100%;
  background: none;
}
.searchResultItem:hover,
.showAllWrapper:hover,
.selected {
  background: var(--elevation-1);
}
.showAllWrapper .showMoreIcon {
  width: 24px;
}
.showAllWrapper .showMoreIcon img {
  height: 16px;
  width: 16px;
}
.showAllWrapper .showAllText {
  font-weight: 400;
  font-size: 13px;
  color: var(--text-2);
  letter-spacing: normal;
}

@media (max-width: 768px) {
  .info .name {
    word-break: break-word;
  }
}
