.protocol_card_container {
  margin: 16px 0px;
  border: 1px solid var(--border-dark);
  border-radius: 6px;
  padding: 9px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.protocol_details {
  flex-grow: 1;
}

.protocol_name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);

  margin-left: 8px;
}

.protocol {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 2px;
}

.protocol_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.open_in_new_tab {
  height: 40px;
  color: var(--text-2);
  margin-right: 16px;
}
.layers_icon {
  height: 40px;
  color: var(--text-2);
  margin-right: 6px;
}

.view_protocol {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.view_protocol_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  text-align: right;
  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.icon {
  margin-left: 12px;
}

.protocol_image {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

@media (max-width: 768px) {
  .creator_details_container {
    flex-wrap: wrap;
    gap: 6px;
  }
}
