.rightPanel {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 37.5%;
  background: var(--base);
  position: fixed;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.header {
  padding: 24px 20px 0 20px;
  border-bottom: 1px solid var(--elevation-2);
}

.tabBar {
  padding: 24px 20px 0 20px;
  border-bottom: 1px solid var(--elevation-2);
}

.animationContainer {
  padding: 24px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.footer {
  padding: 0 20px 30px 20px;
}

.form {
  padding: 20px 20px 0 20px;
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  color: var(--text-1);
}

.formWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  padding-bottom: 20px;
  letter-spacing: normal;
  color: var(--text-1);
}

.graphic {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--graphic-background);
  background-repeat: no-repeat;
  background-position: right top;
  border-radius: 8px;
  min-height: 150px;
}

.optionsSection {
  display: flex;
  gap: 20px;
  width: 100%;
  margin-top: 30px;
}

.option,
.optionSmall {
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  cursor: pointer;
  font-weight: 500;
  color: var(--text-2);
  background: var(--elevation-1);
}

.optionSmall {
  padding: 12px;
  gap: 8px;
  flex-direction: row;
}

.active {
  background-color: var(--primary-color12);
  color: var(--primary-color);
  font-weight: 600;
}

.help {
  border-radius: 8px;
  padding: 16px;
  width: 100%;
}

.helpIcon {
  object-fit: contain;
  margin-right: 8px;
}

.nextButton {
  width: 100%;
  height: 58px;
}

.tabNumber,
.tabActiveNumber {
  border-radius: 50%;
  display: inline-flex;
  width: 17px;
  height: 17px;
  font-size: 13px;
  line-height: 17px;
  justify-content: center;
  color: var(--text-white);
  background-color: var(--text-2);
}

.tabActiveNumber {
  background-color: var(--text-1);
}

.checkBoxIcon {
  margin-right: 8px;
}
