.container {
  display: flex;
  overflow-x: auto;
  column-gap: 16px;
  padding: 0 16px;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
}

.cardContainer {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.emptyContainer {
  height: 142px;
  width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 24px;
  position: relative;
}

.welcomeText {
  width: 140px;
  top: 70px;
  position: absolute;
}

.commentsContainer {
  display: flex;
  overflow-x: auto;
  column-gap: 8px;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
}

.threadsContainer {
  min-height: 142px;
}

.commentActor {
  height: 24px;
  width: 24px;
  border-radius: 24px;
}
