@import "zxStyles/functions.scss";
.duration {
  font-size: 13px;
  color: cv(text-2);
  font-weight: 400;
  padding-bottom: 4px;
  cursor: pointer;
  transition: all 0.1s ease;
}
.selected {
  font-weight: 600;
  border-bottom: 2px solid cv(text-2);
}
