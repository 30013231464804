.empty-list {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  width: 100%;
  flex-direction: column;
  padding: 50px;
  font-family: Roboto;
  &__full-height {
    height: 100%;
    padding: 0;
    .empty-list__label {
      font-size: 16px;
    }
  }
  &__icon {
    height: 100px;
    width: 100px;
    margin-bottom: 10px;
    &--error {
      filter: hue-rotate(160deg);
    }
  }
  &__label {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: normal;
    color: var(--text-2);
    margin-bottom: 5px;
  }
}
