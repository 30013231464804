.postOnImage {
  height: 20px;
  width: 20px;
}

.container {
  border-radius: 8px;
  padding: 8px 0;
  border: 1px solid var(--border-dark);
  margin: 12px 0;
}
