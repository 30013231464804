.mention,
.mention:visited {
  color: var(--primary-color);
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
  padding-left: 2px;
  padding-right: 2px;
  text-decoration: none;
}

.mentionSuggestions {
  border: 1px solid var(--border-dark);
  position: absolute;
  min-width: 220px;
  max-width: 440px;
  border-radius: 2px;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transform-origin: 50% 0%;
  transform: scaleY(0);
  background: var(--base);
}

.mentionSuggestionsEntryContainer {
  display: table;
  width: 100%;
}

.mentionSuggestionsEntryContainerLeft,
.mentionSuggestionsEntryContainerRight {
  display: table-cell;
  vertical-align: middle;
}

.mentionSuggestionsEntryContainerRight {
  width: 100%;
  padding-left: 8px;
}

.mentionSuggestionsEntry {
  background: var(--base);
  color: var(--text-2);
  padding: 7px 10px 3px 10px;
}

.mentionSuggestionsEntry:active {
  background-color: var(--gradient-base-color-1);
}

.mentionSuggestionsEntryFocused {
  background: var(--base);
  color: var(--text-1);
  padding: 7px 10px 3px 10px;
  background-color: var(--gradient-base-color-2);
}
.mentionSuggestionsEntry,
.mentionSuggestionsEntryFocused {
  display: flex;
  gap: 8px;
  align-items: center;
}

.mentionSuggestionsEntryText,
.mentionSuggestionsEntryTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.resultType {
  font-size: 11px;
  color: var(--text-2);
}
.loader {
  display: flex;
  justify-content: left;
  padding-left: 14px;
}
