@import "zxStyles/functions.scss";
@keyframes animateGreen {
  0% {
    opacity: 0.2;
    background-color: cv(success-light); /* Start fully transparent */
  }
  25% {
    opacity: 0.5;
    background-color: cv(success-light); /* End fully opaque and red */
  }
  50% {
    opacity: 1;
    background-color: cv(success-light); /* End fully opaque and red */
  }
  75% {
    opacity: 0.5;
    background-color: cv(success-light); /* End fully opaque and red */
  }
  100% {
    opacity: 1;
    background-color: transparent; /* End fully opaque and red */
  }
}

@keyframes animateRed {
  0% {
    opacity: 0.2;
    background-color: cv(error-light); /* Start fully transparent */
  }
  25% {
    opacity: 0.5;
    background-color: cv(error-light); /* End fully opaque and red */
  }
  50% {
    opacity: 1;
    background-color: cv(error-light); /* End fully opaque and red */
  }
  75% {
    opacity: 0.5;
    background-color: cv(error-light); /* End fully opaque and red */
  }
  100% {
    opacity: 1;
    background-color: transparent; /* End fully opaque and red */
  }
}
// animate 0 to 1 over 4-5 stages
@keyframes opacityRed {
  0% {
    left: -30%;
    opacity: 0;
    background-color: cv(error-light); /* Start fully transparent */
  }
  25% {
    opacity: 0.2;
    background-color: cv(error-light); /* Start fully transparent */
  }
  50% {
    opacity: 0.5;
    background-color: cv(error-light); /* End fully opaque and red */
  }
  75% {
    opacity: 0.75;
    background-color: cv(error-light); /* End fully opaque and red */
  }
  100% {
    left: 0;
    background-color: transparent; /* End fully opaque and red */
    opacity: 1;
  }
}

@keyframes opacityGreen {
  0% {
    left: -30%;
    opacity: 0;
    background-color: cv(success-light); /* Start fully transparent */
  }
  25% {
    opacity: 0.5;
    background-color: cv(success-light); /* Start fully transparent */
  }
  50% {
    opacity: 0.5;
    background-color: cv(success-light); /* End fully opaque and red */
  }
  75% {
    opacity: 0.75;
    background-color: cv(success-light); /* End fully opaque and red */
  }
  100% {
    left: 0;
    background-color: transparent; /* End fully opaque and red */
    opacity: 1;
  }
}

.animateBuy {
  animation-name: opacityGreen;

  animation-duration: 0.5s; /* Adjust the duration as needed */
  animation-timing-function: ease;
  animation-iteration-count: 1; /* Adjust the timing function for the fade effect */
}

.animateSell {
  animation-name: opacityRed;

  animation-duration: 0.5s; /* Adjust the duration as needed */
  animation-timing-function: ease;
  animation-iteration-count: 1; /* Adjust the timing function for the fade effect */
}

.preAnimate {
  opacity: 0;
}
