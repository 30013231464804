.profile_details {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.profile_body {
  width: 100%;
  height: 100%;
}

.related_wallets {
  min-height: 200px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;
}

.infobar {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .profile_body,
  .related_wallets {
    width: 100%;
  }
}

.capsule {
  padding: 8px 12px;
  background-color: var(--base);
  border: 1px solid var(--border-dark);
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: flex;
  font-size: 13px;
  color: var(--text-1);
  cursor: pointer;
}
.capsule.dark {
  border: 2px solid var(--border-dark);
}

.capsule.selected {
  background: rgb(27, 31, 34);
  color: rgba(255, 255, 255, 0.9);
  font-weight: 600;
}

.selected.dark {
  border: 2px solid var(--primary-color);
}

.tabbar {
  position: sticky;
  top: 51px;
  background-color: var(--base);
  z-index: 2;
}
