@media (max-width: 768px) {
  .container {
    padding: 40px 26px;
    width: 100%;
  }
  .commentsContainer {
    padding-bottom: 50px;
  }
}

.refetchContainer {
  margin-top: 200px !important;
}