.searchInputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 16px;
  background: var(--base);
  border: 1px solid var(--border-dark);
  border-radius: 6px;
}
.searchInputContainer.focus {
  border: 1px solid var(--primary-color);
}
.searchInputContainer .searchInput {
  display: flex;
  align-items: center;
  gap: 18px;
  flex-grow: 1;
}
.searchInputContainer .searchInput .searchIcon {
  width: 13px;
  height: 13px;
}
.searchInputContainer .searchInput input {
  border: none;
  background: transparent;
  width: 100%;
  color: var(--text-1);
}
.searchInputContainer .searchInput input {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text III */
  color: var(--text-1);
}
.searchInputContainer .searchInput input::placeholder {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text III */

  color: var(--text-3);
}
.searchInputContainer .searchInput input:focus {
  outline: none;
}
