.rightPanelContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: var(--elevation-1);
  padding-bottom: 20px;
}

.simulatedRibbon {
  background: var(--warning);
  height: 24px;
  color: var(--text-white, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 19.5px */
  letter-spacing: 1.04px;
}

.header {
  display: flex;
  flex-direction: column;
  background: var(--base);
  border-bottom: 1px solid var(--elevation-2);
  padding: 24px 20px 0px 20px;
}

.crossIcon {
  margin-right: 8px;
  margin-bottom: 16px;
  cursor: pointer;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  padding-bottom: 20px;
  /* or 23px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.detailsInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
}

.detailsInfoRowTile {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.infoText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.detailsRow {
  display: flex;
  flex-direction: row;
  flex: 3;
  align-items: center;
  gap: 8px;
}
.detailsRow.Failed .text {
  color: var(--error);
}
.detailsRow.Failed .icon {
  color: var(--error);
}

.icon {
  font-size: 15px;
  line-height: 150%;
  letter-spacing: normal;
  color: var(--success);
}
.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Success */

  color: var(--success);
}
.subText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text III */

  color: var(--text-3);
}
.detailsTextRow {
  display: flex;
  flex-direction: row;
  flex: 3;
  align-items: center;
  gap: 8px;
}

.tableRowValueText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  color: var(--text-1);
  overflow: auto;
}
.chainIcon {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.open_in_new_tab {
  width: 20px;
  size: 20px;
  color: var(--text-2);
  margin-right: 8px;
}

.openInNewTab {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 18px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.externalLinks {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 18px */

  letter-spacing: normal;

  /* Grey */

  color: var(--text-2);
}

.websiteLink {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 18px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.smallPrimaryLabel {
  color: var(--text-1);
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: normal;
}
.usdValue {
  color: var(--text-2);
  text-align: right;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: normal;
}

.layers_icon {
  height: 40px;
  color: var(--text-2);
  margin-right: 6px;
}

.view_protocol {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.view_protocol_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  text-align: right;
  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.rightPaneTokenIcon {
  height: 24px;
  width: 24px;
  margin-top: -1px;
  border: 1px solid var(--base);
}

.rightPaneTokenName {
  color: var(--text-1);
  font-size: 13px;
  font-weight: 600;
}

.rightPaneTokenWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 3px 0;
}
