.top_users {
  background-color: var(--base);
  border: 1px solid var(--border-light);
  border-radius: 6px;
  padding: 0 20px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 16px;
  width: 100%;
}

.tab {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
  margin-right: 16px;
  cursor: pointer;
}

.tab_active {
  font-weight: 600;
  color: var(--text-1);
  cursor: default;
}

.duration {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 16px */

  text-align: center;
  letter-spacing: normal;

  /* Text III */

  color: var(--text-3);
  margin-left: 12px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .duration:first-of-type {
    margin-left: 0;
  }
}

.duration_active {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  font-size: 13px;

  /* identical to box height, or 16px */

  text-align: center;
  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
  cursor: default;
  /* border-bottom: 1px solid var(--text-2); */
}

.error {
  height: 250px;
  width: 100%;
}

.right_panel {
  display: flex;
  align-items: center;
}

.shimmer {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  padding-bottom: 24px;
  margin-top: 16px;
}

.shimmer_line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 20px;
}

.durationPill {
  cursor: pointer;
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 13px;
  color: var(--text-1);
  background-color: var(--base);
  cursor: pointer;
  background-color: var(--base);
  border: 1px solid var(--border-dark);
  &:hover {
    background-color: var(--primary-color10);
  }
}

.activeDuration {
  background-color: var(--primary-color10);
  color: var(--primary-color);
}
