.profile_graphs {
  background: var(--base);
  /* Border Light */

  border: 1px solid var(--border-dark);
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 8px;
}

.right_panel {
  display: flex;
  align-items: center;
  height: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  border-bottom: 1px solid var(--border-dark);
}

.tab {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
  margin-right: 16px;
  cursor: pointer;
}

.tab_active {
  font-weight: 600;
  color: var(--text-1);
  cursor: default;
}

.duration {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 16px */

  text-align: center;
  letter-spacing: normal;

  /* Text III */

  color: var(--text-3);
  margin-left: 12px;
  cursor: pointer;
}

.duration_active {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 16px */

  text-align: center;
  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
  cursor: default;
  /* border-bottom: 1px solid var(--text-2); */
}

.graph_component {
  height: 350px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
