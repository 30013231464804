.actionButtons {
  width: 100%;
}

.toolbar {
  position: absolute;
  top: 0;
  margin: 15px;
}

.slideButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 15px;
}

.slideButtonPrev {
  left: 15px;
}

.slideButtonNext {
  right: 15px;
}

.actionButton {
  color: #f9f9f9;
  background: #000;
  border-radius: 50%;
  padding: 5px;
  line-height: 0;
  text-align: center;
  height: 40px;
  width: 40px;
  margin: 5px;
}

.actionButton:hover {
  color: #333;
  background-color: #ddd;
}

.actionButton:disabled {
  cursor: unset;
  color: #333;
  background: #999;
}
