@import "zxStyles/functions.scss";
.bar {
  display: flex;
  column-gap: 4px;
  height: 5px;
  border-radius: 4px;
}

.sell {
  border-radius: 4px;
  background-color: cv(error);
}

.buy {
  border-radius: 4px;
  background-color: cv(success);
}
