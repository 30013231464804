.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* identical to box height, or 20px */

  letter-spacing: normal;
  word-wrap: break-word;

  /* Text I */

  color: var(--text-1);
}

.addressRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.suffix_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;
  text-align: right;
  letter-spacing: normal;
  color: --var(--text-3);
}

.actionIcons {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.icon {
  margin-left: 16px;
}
.custom_button {
  background-color: transparent;
  border: none;
  padding: 0;
}

.orientation_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* padding-bottom: 8px; */
}

.divider {
  border-bottom: 1px solid var(--border-dark);
}

.avatar {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-right: 8px;
}
.avatarName {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.textIcon {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}

.titleText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */
  letter-spacing: normal;
  /* Text I */

  color: var(--text-1);
}

.sourcedBy {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.sourcedByProfiles {
  display: flex;
  position: relative;
  width: 36px;
  gap: 0px;
  justify-content: flex-start;
  align-items: center;
  /* flex-grow: 1; */
  height: 20px;
}

.sourcedByAvatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.sourcedByAvatar1 {
  width: 22px;
  position: absolute;
  height: 22px;
  border-radius: 50%;
  z-index: 2;
  margin-right: 16px;
  border: 1px solid var(--base);
}

.sourcedByAvatar2 {
  width: 20px;
  position: absolute;
  margin-left: 16px;

  height: 20px;
  border-radius: 50%;
  z-index: 1;
}

.paddingLeft {
  padding-left: 4px;
}
.buttons_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  gap: 16px;
}

.countText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;
  /* identical to box height, or 16px */

  letter-spacing: normal;
  padding-left: 4px;

  /* Grey */

  color: var(--text-3);
}
