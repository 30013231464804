.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  padding: 15px;
  background-color: var(--base);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}
.weekContainer {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  grid-template-rows: 1fr;
  grid-auto-flow: row;
  border-radius: 12px;
  margin-top: 15px;
  border: 1px solid var(--border-dark);
}

.dayName {
  background: var(--elevation-1);
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--border-1);
}

.caretContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
}

.day {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
}

.day[data-disabled="true"] {
  opacity: 0.5;
  cursor: not-allowed;
}

.day[data-selected="true"] {
  background-color: var(--primary-color);
  color: var(--base);
}

.timePicker {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-auto-flow: row;
  gap: 8px 8px;
  margin-top: 20px;
  text-align: left;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 16px;
  border-radius: 8px;
  cursor: pointer;
  background-color: var(--primary-color);
  color: var(--base);
  font-size: 15px;
  font-weight: 500;
}

.button[disabled] {
  opacity: 0.5;
}

.clearButton {
  background-color: transparent;
  border: 0;
  color: var(--primary-color);
  font-size: 15px;
  font-weight: 500;
}
