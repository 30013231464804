.tabBarPosition {
  position: -webkit-sticky;
  position: sticky;
  backdrop-filter: blur(16px);
  z-index: 3;
}

.indicatorPositionContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.indicatorPositionContainerTop {
  flex-direction: column-reverse;
}

.borderContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--border-dark);
}

.itemsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.tabItemWrapper {
  flex-shrink: 0;
}
@media (max-width: 768px) {
  .itemsContainer {
    align-items: flex-start;
    gap: 16px;
  }
}
.link {
  font-family: "Roboto";
  font-style: normal;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
  cursor: pointer;
}

.active_bar {
  position: absolute;
  align-items: flex-end;
  background: var(--primary-color);
  border-radius: 6px 6px 0 0;
}
.tabbarlink {
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.tabbarlinkSmall {
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.tabbarlinkLarge {
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.active {
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;
  align-items: bottom;

  /* Text I */

  color: var(--text-1);
}
