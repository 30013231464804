.protocol_header {
  width: 100%;
  padding-top: 12px;
}

.cards {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 800px;
  grid-gap: 20px;
}

@media (max-width: 768px) {
  .cards {
    width: auto;
  }
}

.horizontal {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  grid-gap: 20px;
}

.protocol_header_ghost {
  display: flex;
  flex-direction: column;
}

.refetch_container {
  height: 100vh;
}

.bio {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* or 22px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}
