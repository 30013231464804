.parentContainer {
  display: flex;
  font: "Roboto";
  flex-direction: column;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--Elevation-I, rgba(104, 118, 132, 0.05));
  background: var(--Elevation-I, rgba(104, 118, 132, 0.04));
  margin-bottom: 12px;
  padding: 8px;
}

.queryLineContainer {
  display: flex;
  justify-content: space-between;
  gap: 4px;
}

.filterOptions {
  display: flex;
  gap: 20px;
  font-family: "Roboto";
  color: var(--text-2);
  font-size: 12px;
  margin-left: 10px;
  cursor: pointer;
  width: fit-content;
}

.queryOperator {
  width: 61px;
  height: 24px;
  border-radius: 30px;
  background: var(--Elevation-II, rgba(104, 118, 132, 0.08));
}

.operatorButton {
  width: 61px !important;
  height: 24px !important;
  padding-inline: 8px !important;
  border-radius: 30px !important;
  background: var(--Elevation-II, rgba(104, 118, 132, 0.08)) !important;
  margin-bottom: 10px !important;
  margin-left: 8px !important;
}

.operatorList {
  width: 61px !important;
}

.operatorList > div > div {
  font-size: 13px !important;
}

.queryLineElement {
  width: 230px !important;
  height: 34px !important;
}

.dropdownList {
  width: 230px !important;
  height: 200px !important;
  overflow: auto !important;
}

.dropdownList > div > div {
  font-size: 13px !important;
}

.compOperator {
  width: 61px !important;
  height: 34px !important;
}

.compOperatorList {
  width: 61px !important;
}

.compOperatorList > div > div {
  font-size: 13px !important;
}

.chainFilter {
  width: 200px !important;
  height: 34px !important;
}

.chainFilterList {
  width: 200px !important;
  height: 200px !important;
  overflow: auto !important;
}

.chainFilterList > div > div {
  font-size: 13px !important;
}

.compValue {
  min-width: 100px;
  height: 34px;
  padding: 4px 10px 4px 30px;
  flex-grow: 1;
  border: 1px solid var(--border-dark);
  background: var(--base);
  color: var(--text-1);
}

.dollarIcon {
  position: absolute;
  top: 10px;
  left: 10px;
}

.compValue::placeholder {
  color: var(--text-1);
}

.deleteIcon {
  cursor: pointer;
}

.searchDropDown {
  color: var(--text-1);
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 6px;
  font-family: "Roboto";
  font-size: 12px;
  &:hover {
    cursor: pointer;
    background-color: var(--Elevation-II, rgba(104, 118, 132, 0.08));
  }
}

.resetBtn {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: "Roboto";
  font-size: 12px;
  color: var(--Primary, #0648d7);
  cursor: pointer;
}
