.filterWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
}
.filtersContainer {
  display: flex;
  gap: 10px;
  height: 24px;
}

.filterLabel {
  border-radius: 4px;
  padding: 2px 6px;
  transition:
    background-color 0.3s,
    color 0.3s;
  display: flex;
  align-items: center;
  background-color: var(--elevation-2);
  color: var(--text-1) !important;
  font-size: 16px;
}

.filterCheckbox {
  padding: 0 !important;
}

.filterLabel.MuiFormControlLabel-root {
  margin-left: 5px;
}

.filterCheckbox.Mui-checked + .MuiFormControlLabel-label {
  color: var(--text-1) !important;
}

.filterCheckbox .MuiSvgIcon-root {
  font-size: 18px;
  padding: 0;
}

.filterCheckbox .Mui-checked {
  background-color: var(--elevation-2);
  color: var(--primary-color);
}

.checkedLabel {
  background-color: var(--primary-color10);
  color: var(--primary-color) !important;
  font-weight: 500 !important;
}

.MuiCheckbox-root {
  color: white;
  padding: 0;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: var(--primary-color);
  padding: 0;
}
