.container {
  width: 100%;
  border-radius: 4px 4px 0 4px;
  border-left: 4px solid var(--primary-color10);
  cursor: pointer;
}

.message {
  color: var(--text-1);
  font-size: 13px;
}
