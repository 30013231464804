.timeline_slider {
  background-color: var(--elevation-1);
  position: absolute;
  top: 0;
  left: 10;
  padding-left: 10px;
}

.explorer {
  display: flex;
  flex-direction: column;
  margin-left: 0;
  width: calc(100vw - 250px);
  @media (max-width: 1400px) {
    width: calc(100vw - 70px);
    margin-left: -16px;
  }
  @media (max-width: 768px) {
    margin-left: 0;
    width: 100vw;
  }
}
.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: var(--base);
}
.top_bar {
  height: 52px;
  width: 100%;
  background-color: var(--navBg);

  border-bottom: 1px solid var(--border-dark);
  border-top: 1px solid var(--border-dark);

  box-shadow: 0px 0px 16px rgba(104, 118, 132, 0.04);
  backdrop-filter: blur(40px);

  padding-left: 20px;
  padding-right: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.body {
  display: flex;
  flex-direction: row;
  background-color: var(--base);
}
.selected_panel {
  width: 480px;
  background-color: var(--navBg);

  /* Border Dark */

  border-right: 1px solid var(--border-dark);
  box-shadow: 0px 0px 16px rgba(104, 118, 132, 0.04);
  backdrop-filter: blur(40px);
  /* Note: backdrop-filter has minimal browser support */
}

.timeline_slider {
  height: 44px;
  position: absolute;
  top: 10px;
  width: 100%;
}

.wallet_identity_slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 12px;
}

.timeline_container {
  height: 44px;
  position: relative;
  border-top: var(--border-dark);
}

.results_for_container {
  display: flex;
  flex-direction: row;
  grid-gap: 8px;
  max-width: 70%;
}

.results_for {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  width: max-content;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);

  display: flex;
  align-items: center;
}

.selected {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  text-align: right;
  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.not_selected {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  text-align: right;
  letter-spacing: normal;

  /* Text II */

  color: var(--text-3);
}

.horizontal_divider {
  height: 16px;
  width: 1px;
  background-color: var(--text-3);
  margin-left: 24px;
}

.top_bar_right {
  display: flex;
  align-items: center;
}
