.container {
  display: flex;
  flex-direction: column;
}

.nftWrapper {
  width: 330px;
  height: 330px;
  border-radius: 12px;
  border: 4px solid var(--base);
  box-shadow: 0px 12px 16px 0px #00000014;
}
.nftWrapper img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.avatar,
.collectionAvatar {
  width: 24px;
  height: 24px;
  border-radius: 24px;
}
.collectionAvatar {
  border: 1px solid var(--border-dark);
}

.nftDetails {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 0;
}

.similarNftsContainer {
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 16px;
  margin-left: 8px;
}
