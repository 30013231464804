.container {
  display: flex;
  column-gap: 12px;
  overflow-x: auto;
  padding: 0 0;
  padding-right: 16px;
  padding-left: 16px;
}

.container::-webkit-scrollbar {
  display: none;
}
.cardContainer {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  overflow-x: hidden;
  overflow-y: auto;
}
.commentsContainer {
  margin-top: 8px;
  display: flex;
  column-gap: 8px;
  overflow: auto;
}
.commentsContainer::-webkit-scrollbar {
  display: none;
}
.engagementContainer {
  overflow: visible;
}
