.channel-preview__container {
  height: 72px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
}

.channel-preview__container:hover {
  background: var(--elevation-1);
}

.str-chat.dark .channel-preview__container:hover {
  background: #2c2c30;
}

.channel-preview__container.selected {
  background: var(--primary-color10);
}

.channel-preview__content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
  width: 100%;
}

.channel-preview__content-top,
.channel-preview__content-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  height: 18px;
  margin-bottom: 4px;
}

.channel-preview__content-name {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: var(--text-1);
  margin: 0;
  max-width: 158px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.str-chat.dark .channel-preview__content-name {
  color: #ffffff;
}

.channel-preview__content-time {
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  color: var(--text-2);
  margin: 0;
}

.channel-preview__content-message {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
  color: var(--text-2);
  height: 16px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.channel-preview__content-new-message {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  color: var(--text-2);
  height: 16px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.channel-preview__container .str-chat__avatar {
  margin-right: 0;
}

.channel-preview__avatars {
  display: flex;
  align-items: center;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 20px;
  overflow: hidden;
}

.channel-preview__avatars.two div:first-child {
  position: relative;
  right: 10px;
}

.channel-preview__avatars.two div:nth-child(2) {
  position: relative;
  right: 30px;
}

.channel-preview__avatars.two span {
  width: 20px;
  overflow: hidden;
}

.channel-preview__avatars.three span {
  width: 20px;
  overflow: hidden;
}

.channel-preview__notification-dot {
  border-radius: 9px;
  background: var(--error);

  font-size: 12px;
  font-weight: 600;
  color: white;
  padding: 2px 10px;
  flex-shrink: 0;
  border-radius: 14px;
}
