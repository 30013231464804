.tileContentContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.subtitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
  text-transform: capitalize;
}

.tileContainer {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.borderSideContainer {
  display: flex;
  width: 6px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: var(--border-dark);
}

.borderSideContainer .borderSide {
  width: 6px;
  border-radius: 0px;
  background: var(--border-dark);
}

.templatingContainer {
  flex-grow: 1;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.body {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* or 20px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
  overflow: auto;
}

.heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.tableTitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  letter-spacing: 0.08em;
  text-transform: uppercase;

  /* Text II */

  color: var(--text-2);
}

.tableItemText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
  text-overflow: ellipsis;
  overflow: hidden;
}

.contractMethodValue {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  color: #2d8690;
}

.contractIcon {
  height: 18px;
  width: 18px;
  border-radius: 50%;
}

.contractName {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}
