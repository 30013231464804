@import "zxStyles/functions.scss";
.container {
  padding: 12px;
  border-radius: 8px;
  border: 1px solid cv(elevation-2);
}
.summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
