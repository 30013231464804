:root {
  --app-height: 100%;
  /* --dark-grey: var(--border-dark); */
  --second-font: "Roboto", sans-serif;
  --grey-gainsboro: var(--base);
  --black: var(--text-1);
  --white: var(--base);
  --white-snow: var(--elevation-1);
  --overlay-dark: var(--text-3);
  --grey-whisper: var(--elevation-1);
  --grey: var(--text-2);
  --border-radius: 8px;
}
.messaging .str-chat__messaging-input {
  width: 100%;
}
.str-chat.str-chat-channel.messaging {
  background: var(--base);
}
.str-chat.str-chat-channel-list.messaging {
  background: var(--base);
  width: 330px;
}
.str-chat.str-chat-channel-list.messaging::-webkit-scrollbar {
  background: transparent;
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 0;
  min-width: 250px;
  border-left: 1px solid var(--border-dark);
}

.messaging.str-chat .str-chat__list .str-chat__reverse-infinite-scroll {
  padding-top: 0;
}
.str-chart__list {
  padding-bottom: 16px;
}

.str-chat-channel.messaging
  .str-chat__main-panel:not(:last-child)
  .messaging__channel-header {
  border-radius: 10px 0px 0px 0px;
}

.str-chat__message-simple-status {
  display: none;
}

.messaging.str-chat.dark .str-chat__message--system__date {
  color: var(--text-2);
}

.messaging.str-chat.dark .str-chat__message--system__text p {
  color: var(--text-1);
}

.messaging.str-chat.dark .str-chat__message--system__line {
  background: var(--border-dark);
}

.str-chat__message--system {
  padding: 20px;
}

.str-chat__channel-search {
  background: var(--elvation-1);
  padding: 0px 0px;
}
.str-chat__channel-search input {
  border-radius: 0px;
  height: 60px;
  background-color: var(--base);
}
.str-chat {
  background: var(--base);
  height: calc(100vh - 100px);
}
.str-chat__channel-search-results-header {
  font-size: 12px;
  padding: 8px 16px;
}
.str-chat__channel-preview-messenger--active {
  background: var(--primary-color10);
  box-shadow: none;
  border-radius: 0px;
}
.str-chat__channel-preview-messenger {
  margin: 0;
}
.str-chat__date-separator {
  justify-content: center;
}
.str-chat__date-separator-date {
  font-size: var(--sm-font);
  font-weight: 400;
  padding: 4px 12px;
  background-color: var(--elevation-2);
  border-radius: 8px;
  border: 1px solid var(--border-light);
  color: var(--text-2) !important;
}
.str-chart__input {
  margin: 16px 0 0 0;
}
.str-chat__date-separator-line {
  display: none;
}
.str-chat__message-replies-count-button svg {
  fill: var(--text-2);
}
.str-chat__attachment-list-scroll-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.str-chat__attachment-list-scroll-container:has(div) {
  padding: 8px;
}
.str-chat__attachment-preview-image .str-chat__base-image {
  height: 100px;
  object-fit: contain;
  border: 1px solid var(--border-dark);
  border-radius: 4px;
  margin-top: 8px;
}
.str-chat__attachment-preview-image {
  position: relative;
}
.str-chat__attachment-preview-delete {
  background: var(--base);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.str-chat__attachment-preview-delete:hover {
  background-color: var(--primary-color10), var(--base);
}

.rta__list-header {
  background-color: var(--navBg);
}

.str-chat__avatar-image {
  cursor: pointer;
}
/* Mobile View */
@media screen and (max-width: 768px) {
  .str-chat-channel {
    height: var(--app-height);
  }

  .str-chat-channel-list.messaging {
    float: unset;
    width: 330px;
  }

  #mobile-channel-list {
    width: 100vw;
    height: calc(100vh - 60px);
    position: fixed;
    top: 0;
    left: 0;
    transform: translate3d(-100vw, 0, 0);
    transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  }

  #mobile-channel-list.show {
    transform: translate3d(0vw, 0, 0);
    z-index: 1000;
  }
}

.headerTab {
  font-size: 13px;
}

.requestTabContainer {
  position: relative;
}

.headerNotificationDot {
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: var(--error);
  border-radius: 50%;
  left: 24px;
}

.messagingAppContainer {
  width: 1150px;

  /* margin-right: calc((100vw - 250px) / 2); */
  border-right: 1px solid var(--border-dark);
  border-left: 1px solid var(--border-dark);

  @media (max-width: 1400px) {
    width: 100%;
    margin-right: 0;
  }
}

/* image modal */
.messaging .str-chat__modal__inner {
  padding: 2px;
  background-color: var(--base);
  opacity: 0.95;
  border-radius: 8px;
}

.messaging .str-chat__modal__inner img {
  height: auto;
  max-height: 700px;
  border-radius: 8px;
}

.messaging .str-chat__quoted-message-preview {
  background-color: var(--border-light);
  padding: 4px;
  border-radius: 4px;
  margin-top: 4px;
}

.messaging .str-chat__quoted-message-preview .str-chat__quoted-message-bubble {
  background-color: transparent !important;
}

.messaging .streaming-transaction-message__text {
  font-size: 14px;
  padding: 8px 0;
}

.messaging .streaming-transaction-message {
  padding: 8px 4px;
}
