.nftCollectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  gap: 16px;
}
.info {
  display: flex;
  gap: 12px;
  align-items: center;
  flex-shrink: 0;
}

.nftAvatar {
  position: relative;
}
.avatar {
  height: 28px;
  width: 28px;
  border-radius: 50%;
}
.chainIcon {
  position: absolute;
  top: -2px;
  right: -2px;
  height: 15px;
  width: 15px;
  border: 1px solid var(--base);
  background: var(--base);
  border-radius: 50%;
}
.name {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: normal;
  color: var(--text-1);
}
.borderWrapper {
  border-bottom: 1px solid var(--border-dark);
  height: 1px;
  width: 100%;
  display: flex;
  align-items: center;
}
.stats {
  display: flex;
  align-items: center;
  color: var(--text-2);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: normal;
  flex-shrink: 0;
}

.divider {
  margin: 0 4px;
}
