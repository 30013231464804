.container {
  padding: 12px;
  border-radius: 8px;
  background-color: var(--base);
  border: 1px solid var(--border);
}

.icon {
  position: relative;
}

.activityIcon {
  width: 28px;
  height: 28px;
  border-radius: 38px;
}

.chainIcon {
  position: absolute;
  top: 0;
  left: 20px;
  width: 12px;
  height: 12px;
  border-radius: 20px;
  border: 1px solid var(--border);
}

.side {
  background-color: rgba(7, 157, 57, 0.1);
  color: #079d39;
  padding: 0 4px;
  border-radius: 4px;
}

.dot {
  width: 4px;
  height: 4px;
  background: rgba(104, 118, 132, 0.6);
  border-radius: 9999px;
}

.row2 {
  padding: 8px 0;
  margin: 8px 0;
  border: 1px solid var(--border-light);
  border-width: 1px 0;
}

.yesLine {
  height: 5px;
  border-radius: 4px;
  background-color: var(--success);
}

.noLine {
  height: 5px;
  border-radius: 4px;
  background-color: var(--error);
}

.moreButton {
  font-family: "Roboto";
  background-color: var(--elevation-1);
  color: var(--primary-color);
  padding: 8px 0;
  font-size: 13px;
  font-weight: 500;
  border-radius: 4px;
}
