.signature {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-1);
  padding: 8px 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: normal;
  text-align: left;
}

.signature.isTransaction {
  border-bottom: none;
  padding-bottom: 0;
}
.signatureIcon {
  padding-left: 8px;
  display: flex;
  align-items: center;
}
.content {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  margin-top: 4px;
}
.label {
  flex-shrink: 0;
}

.avatarsWrapper {
  display: flex;
}
.actorAvatar {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid var(--base);
}
.actorNamesWrapper {
  display: flex;
  gap: 4px;
}
.nameWrapper {
  display: flex;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
}
.nameWrapper:hover {
  text-decoration: underline;
  text-decoration-color: var(--text-2);
}
.actorName {
  color: var(--text-1);
}
.avatarsWrapper > div {
  line-height: initial;
}
.avatarsWrapper > a:not(:first-of-type) {
  margin-left: -10px;
}
.viewThreadText {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: normal;
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .content {
    flex-wrap: wrap;
  }
  .actorNamesWrapper {
    flex-wrap: wrap;
  }
}
