.searchResultItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  justify-content: center;
  width: 100%;
}

.emptyState {
  width: 100%;
}

.watchlistBanner {
  background: var(--elevation-2);
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
}

.emptyState {
  display: flex;
  margin-top: 24px;
  justify-content: flex-start;
  height: 280px;
  text-align: center;
}

.watchlistItems {
  width: 100%;
  min-height: 200px;
  max-height: 320px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--text-3);
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--text-3);
  }
}
