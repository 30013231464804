.search_item {
  padding: 12px 0;
  border-bottom: 1px solid var(--border-dark);
}

.search_item_expanded {
  background-color: var(--elevation-1);
}

.header {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.display_picture {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
  margin-left: 10px;
}

.button {
  width: 260px;
  height: 36px;

  /* Primary */

  background: var(--primary-color);
  border-radius: 4px;
  margin-top: 16px;
  color: var(--text-white);

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  text-align: center;
  letter-spacing: normal;

  /* Text White */

  color: var(--text-white);
}
