.galleryContainer {
  outline: none;
  background: none;
  backdrop-filter: blur(0px);
}
.galleryModal {
  background: rgba(0, 0, 0, 0.9);
  color: var(--text-1);
  display: flex;
  height: 100%;
  position: relative;
  overflow: hidden;
  font-family: Roboto;
}
.modalContent {
  position: relative;
  width: 65%;
}
.galleryRightPanel {
  padding: 20px;
  width: 35%;
  background: var(--base);
  position: relative;
  overflow-y: auto;
}
.closeButton {
  background: none;
  padding: 0;
}
.galleryTitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: normal;
}
.closeButton svg {
  height: 15px;
  width: 15px;
  stroke: var(--text-1);
}
.nftItemWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.indicatorIcon {
  position: absolute;
  left: 0;
}
.nftTitle {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: normal;
}
.nftCount {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: normal;
}
.mediaPreview {
  width: 100%;
  height: 100%;
}
.mediaImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
  max-width: 90%;
}

@media (max-width: 500px) {
  .galleryModal {
    flex-direction: column;
  }
  .galleryRightPanel {
    width: auto;
  }
  .modalContent {
    width: auto;
  }
  .mediaPreview {
    height: 60vh;
  }
}
