.net_worth_card {
  position: relative;
  background-size: cover;
  width: 240px;
  height: 160px;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  background-image: url("assets/png/graph_bg.png");
  border: 1px solid var(--border-dark);
  border-radius: 4px;
}

.middle {
  flex-grow: 1;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  /* identical to box height, or 13px */

  letter-spacing: 0.08em;
  text-transform: uppercase;

  /* Text II */

  color: var(--text-2);
}

.value_number {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.value_percent {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: normal;
  line-height: 150%;

  /* Text I */

  color: var(--text-1);
  display: flex;
  align-items: flex-end;
}

.value_container {
  display: flex;
  flex-direction: row;
  margin-top: 3px;
  align-items: flex-end;
}

.value {
  margin-left: 4px;
  display: flex;
  align-items: flex-end;
}

.bottom {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.up {
  color: var(--success);
}

.down {
  color: var(--error);
}

.percent_container {
  margin-left: 4px;
  display: flex;
  align-items: center;
  padding-bottom: 1px;
}

.logo {
  height: 32px;
  width: 32px;
  position: absolute;
  top: 20px;
  right: 20px;
}
