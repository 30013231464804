.chartPreview {
  position: relative;
}
/* .chartPreview:before {
  content: "";
  position: absolute;
  width: 100%;
  right: 0;
  top: 0;
  background: linear-gradient(to right, rgba(255,255,255,0.2), transparent);
  height: 100%;
  backdrop-filter: blur(1px);
} */

@media (max-width: 768px) {
  .chartPreview:before {
    width: 15px;
  }
}
