.container {
  background-color: var(--twitter-banner);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-left {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.logo {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
