.bioContent {
  position: inherit;
  margin-top: 40px;
  width: 100%;
}

.header {
  margin-bottom: 20px;
}

.mainTitle {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 28px;
  letter-spacing: normal;
  line-height: 130%;
  color: #1b1f22;
}

.subtitle {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: normal;
  line-height: 160%;
  color: #687684;
}

.bioInput {
  width: 100%;
  height: 120px;
  padding: 16px;
  border: 1px solid transparent;
  border-radius: 12px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: var(--text-1);
  resize: none;
  line-height: 24px;
  background-color: var(--elevation-1);
  transition: all 0.2s ease;
}

.bioInput:focus {
  border: 1px solid #0648d7;
  background-color: #fff;
  box-shadow: 0px 4px 8px 0px rgba(6, 72, 215, 0.12);
}

.inspiration {
  margin: 40px 0px 30px 0px;
}

.inspirationTitle {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 20px;
  text-align: left;
  letter-spacing: normal;
  color: #1b1f22;
}

.slider {
  margin-top: 20px;
}

.loaderCard {
  min-height: 130px;
  background: #6876840a;
  padding: 20px;
  border-radius: 12px;
  margin-top: 12px;
}

.bioCard {
  width: 300px;
  min-height: 130px;
  background: #6876840a;
  padding: 20px;
  border-radius: 12px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #1b1f22;
}

.bioCardHeader {
  display: flex;
  align-items: center;
}

.errorMsg {
  color: var(--error);
  font-size: 13px;
  margin-top: 8px;
  font-weight: 500;
}

.avatar {
  width: 28px;
  height: 28px;
  border-radius: 8px;
  margin-right: 8px;
}

.bioHandle {
  font-weight: 600;
  font-size: 17px;
  color: #1b1f22;
}

.bioDescription {
  margin-top: 8px;
  font-weight: 400;
  color: #1b1f22;
  font-size: 15px;
  font-family: "Roboto";
  line-height: 24px;
  letter-spacing: -0.3;
}

.continueButton {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0648d7;
  color: #fff;
  border-radius: 12px;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
  border: none;
  margin-top: 48px;
  margin-bottom: 15px;
  height: 48px !important;
}

.continueButton:disabled {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0648d7;
  color: #fff;
  border-radius: 12px;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 17px;
  border: none;
  margin-top: 48px;
  margin-bottom: 15px;
  height: 48px !important;
  opacity: 0.5;
}

.slick-dots {
  bottom: -30px;
  margin-bottom: 16px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #1b1f22;
}
:global(.slick-dots li) {
  margin: 0px 2px;
}

@media only screen and (max-width: 576px) {
  .bioContent {
    margin-top: 24px;
  }
}

.iconContainer {
  background-color: var(--elevation-1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 8px;
}
.iconContainer img {
  width: 24px;
  height: 24px;
}

.bioDp {
  width: 80px;
  height: 80px;
  border-radius: 16px;
}

.completeButtons {
  position: absolute;
  bottom: -72px;
  width: 100%;
  left: 0;
}
.completedBioContainer {
  border-radius: 8px;
  border: 1px solid var(--border-dark);
  background-color: var(--text-white);
}
.completedBioContainer textarea {
  background-color: var(--text-white);
}
/* .completedBioContainer:hover {
  border: 1px solid var(--border-dark);
} */
.completedBioContainer:focus-within {
  border: 1px solid var(--primary-color);
}

.completedBioContainer.errorBorder {
  border: 1px solid var(--error);
}

.errorText {
  margin-left: 12px;
}
