.container {
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.addressRowTile {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding-right: 12px;
}

.addressColumnTile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.infoText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
  word-break: break-all;
}

.valueTitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}
.subtitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
  overflow: hidden;

  text-overflow: ellipsis;
}

.profileImage {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-top: 6px;
}

.amountTileColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1 0 0; /* added flex-basis: 0 */
  overflow: hidden;
  text-overflow: ellipsis;
}

.amountTokenImage {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-left: 6px;
  margin-right: 4px;
}

.amountRowTile {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.headerText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  letter-spacing: 0.08em;
  text-transform: uppercase;

  /* Text II */

  color: var(--text-2);
}

.iconWrapper {
  border: 1px solid var(--border-dark);
  border-radius: 50%;
  height: 20px;
  padding: 3px;
  width: 20px;
  display: flex;
  align-items: center;
}
