.personProfileWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}
.avatarWrapper {
  display: flex;
  align-items: center;
}
.actorAvatar {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid var(--base);
  background: var(--base);
}
.actorName {
  color: var(--text-1);
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: normal;
}
.actorImageWithName {
  display: flex;
  gap: 4px;
  align-items: center;
  color: var(--text-1);
}
