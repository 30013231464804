.holdingTokenImage {
  height: 24px;
  width: 24px;
  border-radius: 50%;
}

.waitlistTokenImage {
  height: 14px;
  width: 14px;
  border-radius: 50%;
}

.label {
  font-weight: 400;
  font-size: 13px;
  color: var(--text-2);
}

.holdingsContainer {
  width: 100%;
  max-height: 320px;
  overflow-y: auto;
  padding-right: 4px;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--text-3);
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--text-3);
  }

  > :not(:last-child) {
    border-bottom: 1px solid var(--border-dark);
  }
}

.rightPanelContent {
  width: 50% !important;

  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}
