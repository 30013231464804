.searchBar {
  max-width: 800px;
  width: 100%;
  margin: 0px 12px;
  position: relative;
  border-radius: 40px;
}

.newFeed {
  max-width: 800px;
}

@media (max-width: 768px) {
  .searchBar {
    display: block;
    margin: auto;
    width: 95vw;
    margin-bottom: 6px;
    margin-top: 6px;
  }
}
