.rightPanel {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);
  width: 100%;
  padding: 24px;
  background: var(--base);
  padding-bottom: 20px;
  overflow: auto;
}
.container {
  width: 37.5%;
  height: 100%;
  position: fixed;
}
.crossIcon {
  margin-right: 8px;
  margin-bottom: 20px;
  cursor: pointer;
}
.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 21px;
  padding-bottom: 24px;
  /* or 23px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 18px */

  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Text II */

  color: var(--text-2);
}

.centerDivider {
  background: var(--border-dark);
  width: 100%;
  height: 1px;
}

.checkboxText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.checkbox {
  position: relative;
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 1px;
  background-color: var(--base);
  outline: none;
  transition: background-color 0.2s ease-in-out;
}

.checkbox::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-image: url("assets/svg/checkbox_selected.svg");
  background-size: cover;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.checkbox:not([disabled])::before {
  background-image: url("assets/svg/checkbox_selected.svg"); /* Replace with your toggleable image */
}

.checkbox:checked:not([disabled])::before {
  opacity: 1;
}

.checkbox:not(:checked) {
  border: 1px solid var(--text-3);
}

.checkbox:disabled::before {
  background-image: url("assets/svg/checkbox_disabled.svg"); /* Replace with your non-toggleable image */
  opacity: 1;
}

.inputBox {
  border: 1px solid var(--border-dark);
  padding: 2px 8px;
  border-radius: 2px;
  margin-top: 6px;
  margin-left: 24px;
  flex-wrap: wrap;
  display: flex;
  gap: 4px;
  align-items: center;
}

.verticalDivider {
  background: var(--border-dark);
  width: 1px;
  height: 14px;
}

.tokenImage {
  height: 14px;
  width: 14px;
  border-radius: 50%;
}

.tokenText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.inputFieldIcon {
  height: 8px;
  width: 8px;
  cursor: pointer;
}

.applyButton {
  width: 100%;
  height: 56px;
  background: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text White */

  color: #ffffff;
}
