.button {
  cursor: pointer;
  border-radius: 4px;
  padding: 10px 32px 10px 32px;
  align-items: center;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 27px */

  letter-spacing: 0.2px;
  transition: all 0.2s ease;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
