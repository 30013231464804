.container {
  min-width: 300px;
  padding: 16px;
}
.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  /* identical to box height, or 26px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}
.subtitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.subtitle2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 130%;
  /* identical to box height, or 17px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}
.tokenImage {
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.tokenIconWrapper {
  padding: 2px 0;
}

.networth {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}
