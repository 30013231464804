.audit-flags ol {
  padding-inline-start: 20px;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: normal;
}

.audit-flags li {
  margin-bottom: 2px;
}
