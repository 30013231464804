.picturesContainer {
  display: flex;
  column-gap: 0;
  align-items: center;
}
.userImage {
  width: 28px;
  height: 28px;
  border-radius: 24px;
  z-index: 2;
}
.secondaryImages {
  width: 32px;
  height: 32px;
  margin-left: -6px;
  border: 1px solid var(--base);
  z-index: 1;
}
