@import "zxStyles/functions.scss";
.container {
  display: flex;
  padding: 0 16px;
  overflow-x: auto;
  column-gap: 16px;
}

.container::-webkit-scrollbar {
  display: none;
}

.attribution {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: cv(elevation-2);
  padding: 8px 8px;
  border-radius: 4px;
  font-size: 12px;
  color: cv(text-2);
  height: auto;
  margin-bottom: 8px;
}

.attribution img {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.capitalize {
  text-transform: capitalize;
}
