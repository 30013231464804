.box {
  display: flex;
  flex-direction: column;
  background: var(--base);
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid var(--border-dark);
}

.iconTextRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
}

.contentBox {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: space-between;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 9px;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 6px;
  background: var(--base);
}

.indicator {
  border-radius: 0px 0px 6px 6px;
  height: 3px;
}

.success {
  background: var(--success);
}

.info {
  background: var(--primary-color);
}

.neutral {
  background: var(--text-2);
}
.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Success */
}

.textColorSuccess {
  color: var(--success);
}
.textColorNeutral {
  color: var(--text-2);
}

.textColorInfo {
  color: var(--text-1);
}

.iconColorSuccess {
  color: var(--success);
}
.iconColorNeutral {
  color: var(--primary);
}

.iconColorInfo {
  color: var(--text-2);
}
