.protocol_details_ghost {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.vertical {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  grid-gap: 20px;
}
