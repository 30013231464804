.deployedContracts {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 16px;
  padding: 16px 20px;
  background: var(--base);
  border: 1px solid var(--border-dark);
  border-radius: 6px;
  font-family: Roboto;
}
.createdAt {
  white-space: nowrap;
}
