.details_found {
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid var(--border-dark);
}

.header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 150%;
  /* identical to box height, or 15px */

  letter-spacing: 0.08em;
  text-transform: uppercase;

  /* Text II */

  color: var(--text-2);
}

.divider {
  height: 1px;

  /* Border Dark */
  margin-top: 8px;
  margin-bottom: 12px;

  background: var(--border-dark);
}

.use_details {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Primary */

  color: var(--primary-color);
  background-color: inherit;
  padding-left: 0;
  padding-right: 0;
}

.discard {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);

  margin-left: 16px;
  background-color: inherit;
  padding-left: 0;
  padding-right: 0;
}

.actionButton {
  display: flex;
  flex-direction: row;
}

.img_and_title {
  display: flex;
  flex-direction: row;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);

  margin-left: 8px;
}

.img {
  height: 22px;
  width: 22px;
  border-radius: 50%;
}

.description {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);

  margin-top: 8px;
}
