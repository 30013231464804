.reactionContainer {
  display: flex;
  padding: 8px 8px 4px;
  border-radius: 40px;
  border: 1px solid var(--border-dark);
  background: var(--base);
  box-shadow: 0px 4px 4px 0px rgba(28, 28, 34, 0.04);
  width: fit-content;
}

.reactionItem {
  position: relative;
  cursor: pointer;
}

.reactionIcon {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  .animated {
    display: none;
  }
  .normal {
    display: block;
  }
}

.tooltip {
  position: absolute;
  border-radius: 24px;
  background: var(--solid-bg);
  bottom: 45px;
  padding: 2px 7px;
  width: max-content;
  left: 50%;
  transform: translate(-50%, 0);
  display: none;
  color: var(--solid-fg);
  font-size: 13px;
  z-index: 1;
}

.reactionItem:hover {
  .reactionIcon {
    transform: scale(1.4);
    transition: transform 0.2s;
    transform-origin: bottom;
  }

  .animated {
    display: block;
  }
  .normal {
    display: none;
  }
  .tooltip {
    display: block;
  }
}

.postedReactionContainer {
  display: flex;
  color: var(--text-2);
  font-size: 12px;
  align-items: center;
  margin-inline: 8px 8px;
}

.postedReactionIcon {
  margin-left: -4px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: var(--elevation-2);
  display: flex;
  justify-content: center;
  padding: 2px;
  align-items: center;
  border: 1px solid var(--base);
}

.smallReaction {
  height: 16px;
  width: 16px;
}

.postedReactionIcon svg {
  width: 18px;
}

.reactionWrapper {
  position: absolute;
  bottom: 20px;
  padding-bottom: 12px;
  left: -10px;
}

.btnWrapper svg {
  width: 20px;
  height: 20px;
}
