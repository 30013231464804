.gifContainer {
  width: auto;
  position: absolute;
  background-color: var(--base);
  padding: 10px;
  border: 1px solid var(--border-dark);
  border-radius: 8px;
  z-index: 1000;
  margin-left: 12px;
}

.gifGrid {
  height: 350px;
  overflow: auto;
  margin-top: 10px;
}