.positionType {
  display: flex;
  align-items: center;
  height: 20px;
  padding: 4px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: normal;
  margin-top: 4px;
}
.positionType.long {
  color: var(--success);
  background-color: var(--success-light);
}
.positionType.short {
  color: var(--error);
  background-color: var(--error-light);
}
.leverage::before {
  content: "|";
  margin: 0 4px;
}
.priceItem {
  display: flex;
  flex-shrink: 0;
  gap: 4px;
}
.pnlValue {
  font-weight: 500;
}
.pnlChangePercentage {
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: normal;
}

.up {
  color: var(--success);
}
.down {
  color: var(--error);
}
