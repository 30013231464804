@import "zxStyles/functions.scss";

.container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  min-height: calc(100vh - 84px);
  row-gap: 16px;
}

.actors {
  background-color: cv(elevation-1);
  border-radius: 8px;
  width: 100%;
  justify-content: space-between;
}

.actor {
  padding: 4px;
  border-radius: 40px;
  background-color: cv(base);
  width: 350px;
}

.walletCount {
  background-color: cv(elevation-2);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  column-gap: 8px;
}
.otherActor {
  background-color: cv(primary-color);
}

.walletRow {
  background-color: cv(base);
  border: 1px solid cv(border-light);
  border-radius: 12px;
  cursor: pointer;
}

.walletRow.selected {
  background-color: cv(primary-color10);
}

.disabled {
  cursor: not-allowed;
}

.buttonRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
}

.wallets {
  height: 400px;
  overflow: scroll;
}

.requestRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.count {
  padding: 2px 8px;
  border-radius: 8px;
  font-size: 10px;
}

.red {
  background-color: cv(error);
  color: cv(text-white);
}

.black {
  color: #000;
  background-color: cv(text-white);
}

.newRequestBtn {
  cursor: pointer;
}

.followingContainer {
  height: 400px;
  overflow: scroll;
  margin-top: 16px;
}

.followingRow {
  cursor: pointer;
}
