.tokenHoldings {
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
  background: var(--base);
  border: 1px solid var(--border-dark);
  border-radius: 6px;
  font-family: "Roboto";
}

.tokenHoldingsTile {
  overflow: clip;
  cursor: pointer;
}

.tokenHoldingsTile:hover {
  background-color: var(--primary-color10);
}

.tokenActionButton {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 4px;
  background-color: transparent;
  color: var(--primary-color);
  font-weight: 600;
  font-size: 13px;
}

.tokenActionButton:hover {
  background-color: var(--primary-color10);
}

.disabled {
  opacity: 0.5;
}
/* 
.hoverCardAnchor {
  position: relative;
}

.hoverCard {
  position: absolute;
  z-index: 6;
  top: 0;
  right: 0;
  display: none;
} */

.tokenRow:hover {
  background-color: var(--primary-color10);
}

.tokenRow {
  transition: background-color 0.2s;
}
