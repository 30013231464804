.container {
  padding-top: 10px;
  border-top: 1px solid var(--border-dark);
  margin-top: 10px;
  color: var(--text-2);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: normal;
  color: var(--text-2);
}

.followers {
  display: flex;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: normal;
  color: var(--text-2);
  align-items: flex-start;
  padding-top: 4px;
}

.link {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: normal;
  color: var(--text-2);
  margin-left: 4px;
}

.last_index {
  margin-left: 4px;
}

.icon {
  margin-right: 8px;
}

@media (max-width: 768px) {
  .container {
    flex-wrap: wrap;
  }
}

.wrap {
  flex-wrap: wrap;
}

.wrap {
  display: flex;
  column-gap: 4px;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.wrap .icon {
  min-width: 24px;
  margin-right: 0;
  margin-top: 4px;
}

.wrap .followers {
  display: flex;
  flex-wrap: wrap;
  border-top: none;
  min-height: 40px;
}
