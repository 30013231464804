.activityActionButton {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  color: var(--text-2);
  font-family: Roboto;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0px;
  background: none;
}
.menu.menu {
  border: 1px solid var(--border-dark);
  box-shadow: 0px 2px 24px var(--elevation-2);
  background: var(--base);
  color: var(--text-1);
}
.iconWrapper {
  display: flex;
  padding: 5px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}
.shareIcon {
  fill: var(--text-2);
}
.activityActionButton:hover .shareIcon {
  fill: var(--success);
}
.copyButton {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 11px;
  line-height: 130%;
  letter-spacing: normal;
  color: var(--text-1);
}
.copyIcon {
  height: 10px;
  width: 10px;
}
