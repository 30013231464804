.container {
  padding-top: 8px;
  position: relative;
}
.imageContainer {
  width: 48px;
  height: 48px;
  border-radius: 12px;
}

.imageContainer img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.row {
  display: grid;
  grid-template-columns: 4.5fr 7.5fr 2fr 48px;
  align-items: center;
}

.nftRow {
  grid-template-columns: 8fr 2fr 32px;
}

.avatar {
  width: 24px;
  height: 24px;
  border-radius: 24px;
}

.priceTimeContainer {
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
  justify-content: flex-end;
  align-items: flex-end;
  row-gap: 4px;
}
