.container {
  min-width: 160px;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  letter-spacing: 0.08em;
  text-transform: uppercase;

  /* Text II */

  color: var(--text-2);
}

.notificationType {
  cursor: pointer;
  padding: 6px 0px;
  background: var(--base);
}

.isActive {
  background: var(--elevation-1);
}

.typeText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.isActiveText {
  color: var(--text-1);
  font-weight: 600;
}
