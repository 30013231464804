.str-chat__messaging-input {
  background: var(--base);
  /* box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.04); */
  border-top: 1px solid var(--border-dark);
  display: flex;
  align-items: center;
  position: relative;
  color: var(--text-1);
}

.messaging-input__container {
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.messaging-input__input-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  height: fit-content;
  z-index: 100;
  background: var(--base);
  color: var(--text-1);
  /* border: 2px solid rgba(0, 0, 0, 0.16); */
  transition: border 0.2s ease-in-out;
  position: relative;
}

.str-chat__messaging-input > *:not(:first-of-type) {
  margin-left: 8px;
}

.str-chat__textarea {
  display: flex;
  align-items: center;
}

.str-chat__textarea textarea {
  font-size: 14px;
  line-height: 16px;
  min-height: 0;
  transition: box-shadow 0.2s ease-in-out;
  background: var(--base);
  color: var(--text-1);
  border: none !important;
  outline: none !important;
  padding: 11px;
}

.str-chat__textarea textarea::placeholder {
  color: var(--text-2);
}

.str-chat__textarea textarea:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.str-chat__input--emojipicker {
  z-index: 100;
  right: 36px;
}

.str-chat__thread .str-chat__input--emojipicker {
  position: fixed;
  top: 25% !important;
  right: 330px;
  left: auto;
}

.str-chat__messaging-input .emoji-mart-bar,
.str-chat__messaging-input .emoji-mart-search input {
  border-color: rgba(0, 0, 0, 0.36);
}

.str-chat.dark .str-chat__messaging-input .messaging-input__button svg path {
  fill: white;
}

.giphy-icon__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 24px;
  width: 63px;
  background: #005fff;
  border-radius: 12px;
  margin-left: 8px;
}

.giphy-icon__text {
  font-family:
    Helvetica Neue,
    sans-serif;
  font-weight: bold;
  font-size: 11px;
  line-height: 8px;
  color: #ffffff;
}

div.rfu-dropzone {
  width: 100%;
}

div.rfu-dropzone:focus {
  outline: none;
}

.rfu-image-previewer {
  flex: none;
  margin-left: 12px;
}

.rfu-image-previewer__image {
  margin-bottom: 0;
}

div.rta__autocomplete.str-chat__emojisearch {
  z-index: 10;
  position: absolute;
  width: 30%;
  background: var(--navBg);
  margin: 4px 10px;
  border-radius: 16px !important;
}

.str-chat__thread div.rta__autocomplete.str-chat__emojisearch {
  width: 100%;
  left: -10px;
}

.str-chat__user-item {
  color: var(--text-1);
}

.str-chat__user-item:hover {
  cursor: pointer;
}

.rta__entity {
  color: var(--text-1);
}

.rta__entity--selected {
  background: var(--primary-color60);
}

.str-chat__slash-command:hover {
  background: var(--base);
  color: var(--text-1);
  cursor: pointer;
}

.rta__list-header {
  font-family: "Roboto";
  font-size: 12px;
  color: var(--text-2);
  mix-blend-mode: normal;
}

@media screen and (max-width: 768px) {
  div.rta__autocomplete.str-chat__emojisearch {
    width: unset;
  }

  .str-chat__textarea textarea {
    font-size: 16px;
  }
}
