.container {
  position: relative;
}

.net_worth {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 42px;
  left: 736px;
  padding: 2px 4px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 130%;
  /* identical to box height, or 17px */

  letter-spacing: normal;
}

.left_bar {
  height: 32px;
  width: 6px;
  margin-right: 14px;
}

.content_top {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.content_value {
  margin-right: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.content_change {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  /* identical to box height, or 16px */

  letter-spacing: normal;
  padding-bottom: 2px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.content_bottom {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
  /* identical to box height, or 13px */

  letter-spacing: 0.06em;
  text-transform: uppercase;

  /* Text II */

  color: #687684;
}

.icon {
  margin-bottom: 4px;
  margin-right: 2px;
}

.hover_card {
  border-radius: 8px;
  padding: 4px 12px;
  background-color: var(--elevation-2);
}

.value {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.time {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
  /* identical to box height, or 13px */

  letter-spacing: 0.06em;
  text-transform: uppercase;

  /* Text II */

  color: #687684;
}
