.box {
  display: flex;
  flex-direction: column;
  padding: 0px 20px 0px 20px;
  cursor: pointer;
}

.spacingRowTile {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 13px;
  padding-top: 13px;
  justify-content: space-between;
}

.rowTile {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  padding-left: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.subtitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;
  /* identical to box height, or 16px */

  text-align: right;
  letter-spacing: normal;

  /* Text III */

  color: var(--text-3);
}

.chainIcon {
  padding-left: 18px;
  height: 16px;
  width: auto;
  border-radius: 50%;
}

.tokenIcon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.tokenChainIcon {
  position: absolute;
  height: 12px;
  width: 12px;
  right: -2px;
  top: -3px;
}

.tokenIconWrapper {
  position: relative;
  padding-left: 18px;
}

.active {
  background: var(--elevation-1);
}

.pointer {
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
