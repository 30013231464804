.container {
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
  cursor: pointer;
  background-color: var(--farcaster-connect);
}

.connected {
  background-color: var(--elevation-1);
  color: var(--text-1);
}

.disabled {
  cursor: not-allowed !important;
}

.header {
  margin-bottom: 20px;
}

.mainTitle {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 28px;
  letter-spacing: normal;
  line-height: 36px;
  color: #1b1f22;
  margin-bottom: 4px;
}

.subtitle {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: normal;
  line-height: 160%;
  color: #687684;
}

.socialsContainer {
  display: flex;
  gap: 20px;
  margin-top: 40px;
  flex-direction: column;
}

.socialButton {
  width: 160px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 12px;
  cursor: pointer;
  padding: 20px;
  position: relative;
  transition: transform 0.3s;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}

.socialButton:hover {
  transform: translateY(-5px);
}

.farcasterButton {
  background: #855dcd;
  color: #fff;
}

.twitterButton {
  background: rgba(27, 31, 34, 1);
  color: #fff;
}

.twitter_icon {
  margin-bottom: 5px;
}

.lensButton {
  background: #42673b;
  color: #fff;
}
.lensLogo,
.farcasterLogo {
  width: 20px;
  height: 20px;
}
