@import "./functions.scss";
@mixin title-1 {
  color: cv(text-1);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@mixin title-2 {
  color: cv(text-1);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@mixin body-3 {
  color: cv(text-1);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

@mixin body-2 {
  color: cv(text-1);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

@mixin body-1 {
  color: cv(text-1);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
}

@mixin body-label {
  color: cv(text-1);
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
}

@mixin secondary-label {
  //styleName: S1 HeadlineMedium;
  color: cv(text-2);
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-align: left;
}

@mixin secondary-1 {
  color: cv(text-2);
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@mixin secondary-2 {
  color: cv(text-3);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@mixin secondary-title {
  color: cv(text-2);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@mixin table-header-text {
  color: cv(text-2);
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.25px;
  text-transform: uppercase;
}
