.trendingTile,
.additionalInfo {
  height: 100%;
  width: 100%;
  min-width: 392px;
  padding: 16px;
  background: var(--base);
  border: 1px solid var(--border-light);
  position: relative;
}
.trendingTile.fadingGradient:before {
  content: "";
  height: 64px;
  width: 100%;
  background: linear-gradient(0deg, var(--base), transparent);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px;
  pointer-events: none;
}

.transferAvatar {
  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  overflow: hidden;
  padding: 2px;
  border: 1px solid var(--success);
}
.sold {
  border: 1px solid var(--error);
}
.actorName {
  font-size: 15px;
  color: var(--text-1);
  font-family: Roboto;
  line-height: 150%;
  letter-spacing: normal;
  max-width: 110px;
}
.textOverflow {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}
.tileIcon {
  height: 14px;
  width: 14px;
}

@media (max-width: 768px) {
  .additionalInfoWrapper {
    flex-wrap: wrap;
  }
}
