.userDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0px;
}

.iconRow {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding-top: 24px;
  padding-bottom: 20px;
}

.avatar {
  height: 40px;
  width: 40px;
  border-radius: 24px;
}
