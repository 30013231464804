.buttons {
  position: absolute;
  right: 20px;
  bottom: 100px;
  color: var(--text-2);
}

.single_button {
  color: var(--text-2);
  cursor: pointer;
  background-color: var(--base);
  padding: 8px;
  padding-bottom: 4px;
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.zoom_to_fit {
  margin: 4px;
}
