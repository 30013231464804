.rightPanel {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 37.5%;
  background: var(--elevation-1);
  border-radius: 10px;
  margin-bottom: 20px;
  position: fixed;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--base);
  border-bottom: 1px solid var(--elevation-2);
  padding: 24px 20px 0px 20px;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  padding-bottom: 20px;
  /* or 23px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.listContainer {
  padding: 16px 20px;
  overflow: auto;
}

.rectionContainer {
  color: var(--text-1);
  display: flex;
  padding-left: 20px;
  font-size: 16px;
  align-items: center;
}

.reactionBox {
  padding: 12px;
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
}

.reactionBox svg {
  width: 18px;
}

.reactionBox:hover {
  background: var(--elevation-2);
}
