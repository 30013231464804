.name-value-pair {
  margin: 6px 0;
  font-size: 12px;
  &.space-between {
    justify-content: space-between;
  }
  &.flex {
    display: flex;
    align-items: center;
  }
  &.no-flex {
    display: block;
    .name-value-pair__value {
      display: block;
      margin: 5px;
    }
  }
  &__name {
    display: flex;
    align-items: center;
    color: #687684;
    width: 150px;
    font-weight: 600;
    font-size: 12px;

    &--help-icon {
      background: #ebebeb;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      text-align: center;
      font-weight: normal;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  &__value {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
  }
}
