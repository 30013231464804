.linkPreviewsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 4px;
}
.linkPreviewsWrapper.singleLink .container {
  flex-grow: 1;
  align-items: center;
  border-radius: 6px;
  overflow: hidden;
}
.linkPreviewsWrapper.singleLink .lowerContainer {
  width: 100%;
  padding: 10px;
}
.linkPreviewsWrapper.singleLink .title {
  font-size: 15px;
}
.linkPreviewsWrapper.singleLink .secondary {
  font-size: 14px;
  /* for very long links in single link preview, add ellipsis and hide the overflowing text   */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.linkPreviewsWrapper.singleLink .imageWrapper {
  margin: 0;
}
.linkPreviewsWrapper.singleLink .fallbackImage {
  object-fit: contain;
}
.container {
  text-align: left;
  background-color: var(--base);
  display: flex;
  border-radius: 7px;
  border: 1px solid var(--border-dark);
  color: var(--text-1);
  transition: 0.3s all ease;
  align-items: flex-start;
}

.container:hover {
  background-color: var(--elevation-2);
  cursor: pointer;
}

.secondary {
  color: var(--text-2);
  font-size: 12px;
}

.lowerContainer {
  padding: 10px 10px 10px 5px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.title {
  font-size: 13px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: normal;
}
.imageWrapper {
  margin-top: 10px;
  padding: 8px 16px;
  border-right: 1px solid var(--border-dark);
}
.image {
  width: 48px;
  height: 48px;
  object-fit: cover;
}

/* sm */
@media (max-width: 640px) {
  .description {
    display: none;
  }
}

/* md */
@media (min-width: 641px) and (max-width: 768px) {
  .description {
    display: none;
  }
}
