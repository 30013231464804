@import "zxStyles/functions.scss";
.container {
  background-color: cv(base);
  border: 1px solid cv(border-dark);
  background-color: var(--base);
  width: 540px;
  padding: 16px;
  border-radius: 8px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.closeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 24px;
}
