.buy {
  background: var(--primary-color);
}

.sell {
  background: var(--secondary-color);
}

.button {
  width: 72px;
  border-radius: 36px;
  padding: 4px 12px;
}
