.tweetButton {
  width: 218px;
  background: var(--text-1);
  border-radius: 4px;
  padding: 12px 24px;
  margin-left: 20px;
  color: var(--text-1);
  font-size: 16px;
  font-family: "Outfit";
  font-weight: 400;
  line-height: 150%;
  text-align: center;
  cursor: pointer;
}

.xImage {
  height: 20px;
  width: 20px;
  color: white;
  fill: white;
}

.indicatorRow {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 600px;
}

.indicatorBorder {
  display: flex;
  align-items: center;
}

.border {
  width: 40px;
  height: 6px;
  background: #1b1f22;
  border-radius: 3px;
}

.talkToUsButton {
  display: flex;
  align-items: center;
  background: #e2e6eb;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  font-family: "Roboto";
  font-size: 13px;
  color: #687684;
  cursor: pointer;
}

.container {
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.tweetButtonContainer {
  width: 100%;
  height: 58px;
  background-color: #1b1f22;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.skipLink {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #0648d7;
  background-color: var(--elevation-2);
  border-radius: 8px;
  padding: 16px;
  border: none;
  cursor: pointer;
  text-align: center;
  width: 100%;
  text-decoration: none;
}

.tweetText {
  font-family: "Roboto";
  font-size: 15px;
  color: #1b1f22;
  white-space: pre-line;
}
