.mutualAvatar {
  border-radius: 8px;
  width: 52px;
  height: 52px;
}
.mutualHolding {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  justify-content: center;
  align-items: center;
}

.mutualHoldingAvatar {
  height: 32px;
  width: 32px;
  border-radius: 32px;
  border: 1px solid var(--border-dark);
  display: flex;
  align-items: center;
  justify-content: center;
}
