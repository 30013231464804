.container {
  display: flex;
  justify-content: space-between;
  height: 100vh;
  background-color: #fff !important;
}

.leftSide {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.backgroundGradient {
  background: linear-gradient(
    180deg,
    rgba(38, 81, 237, 1) 0%,
    rgba(238, 87, 123, 1) 100%
  );
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logoGroup {
  display: flex;
  gap: 20px;
}

.logo {
  width: 100px;
  height: 100px;
  box-shadow: 0px 5.333px 16px 0px rgba(8, 23, 56, 0.08);
  border-radius: 28px;
}

.trackText {
}

.trackDescription {
  max-width: 400px;
}

.rightSide {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
}

.web3Text {
}

.web3Description {
  /* max-width: 455px; */
}

.primaryButton {
  width: 455px;
  height: 56px;
  background: #0648d7;
  color: #ffffff;
  font-size: 17px;
  font-weight: 600;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.continueButton,
.loginButton {
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0648d7;
  color: #fff;
  border-radius: 12px;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  margin-top: 14px;
  border-radius: 12px !important;
  height: 48px !important;
}

.loginButton {
  background-color: var(--elevation-2);
  color: var(--primary-color);
}

.inviteButton {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--base);
  border-radius: 12px;
  border: 1px solid var(--elevation-2);
  padding: 12px 16px;
  column-gap: 16px;
}

.popupOption {
  padding: 12px;
  border-radius: 8px;
  border: 1px solid var(--border-dark);
  cursor: pointer;
}

.popupOptionIcon {
  width: 20px;
  height: 20px;
}

.button {
  display: flex;
  padding: 12px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 12px;
  width: 174px;
  background-color: #fff;
  border: 1px solid var(--border-dark);
  font-size: 15px;
  font-family: "Roboto";
  font-weight: 400;
  transition: all 0.3s ease;
}

.selected {
  font-weight: 500;
}

@media only screen and (min-width: 1441px) {
  .container {
    padding-top: 80px;
  }
}

.heroBtn {
  background-color: var(--elevation-2);
  color: var(--text-2);
  padding: 12px 0;
  width: 180px;
  border-radius: 4px;
  font-family: "Roboto";
}

.selected {
  font-weight: 500;
  font-family: "Roboto";
  background-color: var(--text-white);
  color: var(--text-1);
}

.heroBtnContainer {
  border: 2px solid var(--elevation-1);
  background-color: var(--elevation-1);
  border-radius: 8px;
}
.login {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.signup {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
