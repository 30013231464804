.selected_node {
  padding: 22px;
}

.image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.info {
  margin-left: 16px;
}

.selected_node_header {
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
  align-items: center;
}

.selected_node_header_link {
  display: flex;
}
.name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.aliases {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.active_since {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);

  margin-top: 12px;

  display: flex;
  justify-content: space-between;

  padding: 9px 0;
  border-bottom: 1px solid var(--border-dark);
  border-top: 1px solid var(--border-dark);
}

.active_since_left {
  display: flex;
  flex-direction: row;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.text,
.count {
  font-family: "Roboto";
  font-style: normal;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  text-align: right;
  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.text {
  font-weight: 400;
}

.count {
  font-weight: 600;
}

.inline_margin {
  margin-left: 16px;
}

.networth {
  margin-left: 6px;
}
