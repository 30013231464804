/* This module needs class renaming and cleanup */
.protocol_header {
  width: 100%;
  padding-top: 12px;
}

.cards {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 800px;
  grid-gap: 20px;
}

.horizontal {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  grid-gap: 20px;
}

.protocol_header_ghost {
  display: flex;
  flex-direction: column;
}

.refetch_container {
  height: 100vh;
}

.bio {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* or 22px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 20px;
  justify-content: flex-start;
}

.top {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
}

.cards {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 800px;
  grid-gap: 20px;
}

.name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  /* identical to box height, or 26px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.contract_tag {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  /* identical to box height, or 16px */

  letter-spacing: normal;

  /* Base */

  color: var(--base);
  border-radius: 24px;
  background-color: var(--text-3);
  width: 44px;
  height: 18px;

  margin-left: 8px;
  padding: 1px 6px;
}

.chain_icon {
  height: 18px;
  width: 18px;
  margin-left: 8px;
}

.bottom {
  display: flex;
  flex-direction: row;
  margin-top: 2px;
}

.deployed_by_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 130%;
  /* or 19px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.creator_image {
  height: 20px;
  border-radius: 50%;
  width: 20px;
  margin-left: 8px;
}

.protocol_image {
  height: 20px;
  border-radius: 50%;
  width: 20px;
}

.deployed_by_name {
  margin-left: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 130%;
  /* or 19px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.protocol_card_container {
  margin: 16px 0px;
  border: 1px solid var(--border-dark);
  border-radius: 6px;
  padding: 9px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.protocol_details {
  flex-grow: 1;
}

.protocol_name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);

  margin-left: 8px;
}

.protocol {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 2px;
}

.protocol_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.open_in_new_tab {
  height: 40px;
  color: var(--text-2);
  margin-right: 16px;
}

.layers_icon {
  height: 40px;
  color: var(--text-2);
  margin-right: 6px;
}

.view_protocol {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.view_protocol_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  text-align: right;
  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.icon {
  margin-left: 12px;
}

.socials {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.metamask_logo_img {
  height: 16px;
  width: 16px;
  margin-top: 3px;
}

.nativeTokenLogo {
  height: 20px;
  width: 20px;
  object-fit: cover;
}

.modalContainer {
  position: absolute;
  top: 50%;
  left: calc(50% + 25px);
  transform: translate(-50%, -50%);
  width: 800px;
  background-color: var(--base);
  font-family: Roboto;
  border-radius: 6px;
}

.modalTitleWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 16px 20px 0;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--border-dark);
}
.close {
  display: flex;
  background: none;
  padding: 0;
}
.close svg {
  stroke: var(--text-1);
}
.title {
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: normal;
  color: var(--text-1);
}

.transactionPageLogo {
  width: 64px;
  height: 64px;
}
@media (max-width: 768px) {
  .modalContainer {
    width: 95vw;
    left: 50%;
  }
}
.dappImage {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.navigateButtons {
  color: var(--base);
  padding: 0px 8px;
  border-radius: 5px;
  height: 30px;
}

.row {
  width: 0px;
  flex-shrink: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.row.open {
  width: 180px;
}
.blockChainLogo {
  width: 40px;
  height: 40px;
}
