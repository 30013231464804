.container {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: -8px;
}

.header {
  padding-left: 16px;
}

.headerImage {
  width: 24px;
  height: 24px;
}

.loaders {
  padding-left: 32px;
  display: flex;
  column-gap: 16px;
  overflow-x: hidden;
}

.loaders ::-webkit-scrollbar {
  display: none;
}
