.container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 12px 24px;
  background: linear-gradient(90deg, #d4d4ff 0%, #e1f2ff 100%);
}
.containerFeed {
  position: relative;
  background-color: #000000;
  margin-bottom: 0px;
  margin-top: 16px;
  border-radius: 8px;
}
.modal {
  padding-top: 12px;
}
.button {
  padding: 16px;
  height: 58px;
  margin-right: 48px;
}
.content {
  display: flex;
  justify-content: space-between;
}
.textContainer {
  display: flex;
  align-items: center;
}

.text {
  color: var(--text-1);
  font-size: 17px;
  line-height: 26px;
  letter-spacing: normal;
}

.textBold {
  font-weight: 600;
}

.containerFeed .text {
  font-size: 13px;
  font-weight: 400;
  color: #ffffff;
  margin-right: 0;
}

.containerFeed .button {
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  height: auto;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.9);
  margin: 4px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.containerFeed .appleLogo {
  filter: invert(1);
}

.containerFeed .playStoreLogo,
.containerFeed .appleLogo {
  height: 20px;
}
.containerFeed .textWrapper {
  padding: 10px;
}

.qrCodeContainer {
  padding: 8px;
  border: 1px solid var(--border-dark);
  border-radius: 12px;
}

@media only screen and (max-width: 576px) {
  .qrCodeContainer {
    width: max-content;
    margin-top: 16px;
    align-self: center;
  }
  .content {
    flex-direction: column;
    row-gap: 8px;
  }
  .button {
    width: 260px;
  }
}
