@import "zxStyles/functions.scss";
.tab {
  background-color: cv(base);
  color: cv(text-1);
  font-weight: 400;
  border: 1px solid cv(border-dark);
  padding: 8px 16px;
  border-radius: 16px;
  font-family: "Roboto";
  transition: background 0.2s ease;
  font-weight: 400;
  display: flex;
  gap: 4px;
  align-items: center;
}

.selected {
  background-color: cv(text-1);
  color: cv(base);
  font-weight: 500;
}
