.container {
  position: relative;
}

.stickyButtons {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  pointer-events: none;
  z-index: 2;
}

.container:hover .stickyButtons {
  opacity: 1;
  pointer-events: auto;
}

.arrowButton:hover {
  background-color: var(--base);
}

.arrowButton {
  background-color: var(--base-80);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  padding: 2px;
}

.leftArrow {
  margin-left: 10px;
  left: 0;
}

.rightArrow {
  margin-right: 10px;
  right: 0;
}
