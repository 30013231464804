.range {
  display: flex;
  align-items: baseline;
  gap: 5px;
}
.rewardsItem {
  display: flex;
  gap: 8px;
}
.rewardsToken {
  width: 50px;
}
.rewardsUsdValue {
  color: var(--text-2);
}
.rangeValue {
  width: min-content;
}
.rangeValueWrapper {
  display: flex;
  gap: 5px;
}
.spacedColumns {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
