.contract_item {
  /* Base */

  background: var(--base);
  /* Border Dark */

  border: 1px solid var(--border-dark);
  border-radius: 6px;

  padding: 8px 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.chain_icon {
  height: 20px;
  width: 20px;
  margin-left: 16px;
  margin-right: 16px;
}

.label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  /* identical to box height, or 16px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
  padding: 2px 8px;
  background: var(--base);
  /* Text II */

  border: 1px solid var(--text-2);
  border-radius: 24px;
}

.contract_item_right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chevron_right {
  color: var(--text-2);
  font-size: 16px;
}

.link {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contract_item_left_top {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.contract_item_left_bottom {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
  margin-top: 4px;
}
