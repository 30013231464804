.checkboxWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
  }
  
  .checkbox {
    opacity: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .checkmark {
    position: absolute;
    right: 25px;
    top: 50%;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: white;
    padding: 2px;
    border: 2px solid #d1d5db;
    transform: translateY(-50%);
  }
  
  .active {
    border-color: #d1d5db;
    background-color: #d1d5db;
  }
  