.container {
  padding: 16px 20px;
  /* Base */

  background: var(--base);
  /* Border Light */

  border: 1px solid var(--border-light);
  border-radius: 6px;
  margin-top: 20px;
}

.header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  /* identical to box height, or 26px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);

  margin-bottom: 16px;
}
