.add_to_existing_profile {
  box-sizing: border-box;
  width: 260px;
  height: 36px;

  /* Base */

  background: var(--base);
  /* Border Dark */

  border: 1px solid var(--border-dark);
  border-radius: 4px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 12px;

  cursor: pointer;
}

.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.icon {
  color: var(--text-3);
}
