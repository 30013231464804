.media-type--image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  transform: translate(-50%, -50%);
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fallback-image {
  width: 60px;
  object-fit: contain;
}
.media-type--video {
  height: 100%;
  width: 100%;
}

.media-placeholder {
  position: relative;
  height: 100%;
  width: 100%;
  &__label {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    color: #fff;
    background: #0000003d;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
