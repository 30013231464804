@import "zxStyles/imports.scss";
@import "zxStyles/mixins.scss";
.container {
  // this is to break words and fit into width
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.text {
  color: cv(text-1);
}

.body-1 {
  @include body-1;
}

.body-2 {
  @include body-2;
}
.body-3 {
  @include body-3;
}
.title-1 {
  @include title-1;
}

.title-2 {
  @include title-2;
}

.secondary-1 {
  @include secondary-1;
}

.secondary-2 {
  @include secondary-2;
}

.secondary-title {
  @include secondary-title;
}

.body-label {
  @include body-label;
}

.secondary-label {
  @include secondary-label;
}

// .secondary-3 {
//   @include secondary-3;
// }

.primary {
  color: cv(primary-color);
}

.secondary {
  color: cv(secondary-color);
}

.text-1 {
  color: cv(text-1);
}

.text-2 {
  color: cv(text-2);
}

.text-3 {
  color: cv(text-3);
}

.success {
  color: cv(success);
}

.error {
  color: cv(error);
}

.purple {
  color: cv(purple);
}

.closed {
  color: cv(closed-text);
}

.base {
  color: cv(base);
}

.warning {
  color: cv(warning);
}
.white {
  color: cv(text-white);
}

.underline:hover {
  text-decoration: underline;
}
