@import "zxStyles/functions.scss";
.inputContainer {
  background-color: cv(elevation-1f);
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid transparent;
  transition: background-color 0.3s ease;
  cursor: text;
  position: relative;
}
.inputContainer:focus-within,
.independentTextArea:focus,
.independentTextArea:hover,
.inputContainer:hover {
  border: 1px solid cv(primary-color);
  background-color: cv(base);
  .input {
    background-color: cv(base);
  }
}

.input,
.independentTextArea {
  background-color: cv(elevation-1f);
  border-radius: 8px;
  border: 1px solid transparent;
  outline: none;
  font-size: 13px;
  padding: 2px 0;
  font-family: "Roboto", sans-serif;
  border: 1px solid transparent;
  color: cv(text-1);
  transition: all 0.3s ease;
  //   flex-shrink: 1;
}
.input {
  border-radius: 0;
}
.input:focus,
.input:hover {
  outline: none;
  background-color: cv(base);
}
.input:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}
.label {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  color: cv(text-1);
}

.independentTextArea {
  padding: 8px 12px;
  line-height: 1.5;
}
.input:focus.error,
.inputContainer:hover.error,
.inputContainer:focus-within.error,
.error {
  border: 1px solid cv(error);
}
