.wrapper {
  display: flex;
  overflow-x: scroll;
  overflow-y: clip;
  gap: 16px;
  scroll-snap-type: x mandatory;
}

.generatedCommentContainer {
  display: flex;
  column-gap: 4px;
  border-radius: 8px;
  padding: 8px;
  width: max-content;
  align-items: center;
  background-color: var(--elevation-1);
}

.skeletons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  flex-wrap: nowrap;
  overflow-x: hidden;
}

.postContainer {
  width: 650px;
  flex-shrink: 0;
  border-left: 1px solid var(--border-dark);
  border-right: 1px solid var(--border-dark);
  background-color: var(--base);
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
}

.postTitle {
  max-width: calc(100% - 200px);
}

.outsideScroller {
  width: auto !important;
  border-bottom: 1px solid var(--border-dark);
}

.topStrip {
  width: 100%;
  height: 3px;
  background: linear-gradient(
    to right,
    #363d42 0%,
    #363d42 25%,
    #0648d7 25%,
    #0648d7 45%,
    #2d8690 45%,
    #2d8690 70%,
    #edd557 70%,
    #edd557 85%,
    #ee57ac 85%,
    #ee57ac 100%
  );
}

.postHeader {
  padding: 50px 22px 0px;
  background: url("assets/svg/recently_joined.svg") no-repeat 98% 45px/130px;
}

.appLogo {
  width: 72px;
  height: 72px;
}

.avatar {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  object-fit: cover;
}

.avatarColumnOne {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 8px;
  margin: -10px 0;
}

.avatarColumnTwo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 8px;
}

.miniAvatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.memberCountContainer {
  display: flex;
  flex-shrink: 0;
  width: 330px;
  min-height: 368px;
  background: var(--base);
  scroll-snap-align: start;
  border-radius: 8px;
  overflow: hidden;
}

.skeletonContainer {
  width: 100%;
  min-height: 390px;
}

@property --item-count {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

.memberCount {
  transition: --item-count 1s;
  counter-reset: item-count var(--item-count);
}

.memberCount::after {
  content: counter(item-count);
  font-size: 56px;
  font-weight: 700;
  color: var(--text-1);
}

.nameLink {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.engagements {
  margin: 16px 22px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.divider {
  width: 16px;
  height: 1px;
  background: var(--text-2);
  opacity: 0.24;
}

.name {
  color: var(--text-1);
  font-size: 24px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 36px;
  word-wrap: break-word;
}

.badge {
  padding-top: 12px;
  margin-left: 12px;
}

.commenterProfilePic {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}
.newCommentWrapper {
  align-self: baseline;
}

.commentsList {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.addCommentButton {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: var(--elevation-1);
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid var(--border-dark);
}

.commentsWrapper {
  width: 100%;
  flex-grow: 1;
}
.comment {
  align-items: center;
}

.welcomeBio {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: normal;
  color: var(--text-1);
  margin-top: 4px;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 20px 60px 0px 0;
}

.welcomeBio a {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;
  letter-spacing: normal;
  color: var(--primary-color);
  cursor: pointer;
}

.welcomeBio a:hover {
  text-decoration: underline;
}

.networth {
  margin-top: 6px;
}

.outerWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.container {
  position: relative;
}

.stickyButtons {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  pointer-events: none;
  z-index: 2;
}

.container:hover .stickyButtons {
  opacity: 1;
  pointer-events: auto;
}

.arrowButton:hover {
  background-color: var(--base);
}

.arrowButton {
  background-color: var(--base-80);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.leftArrow {
  margin-left: 10px;
}

.rightArrow {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .postContainer,
  .memberCountContainer {
    width: calc(100svw - 32px);
  }
  .avatarColumnOne,
  .avatarColumnTwo {
    padding: 0 0;
  }
  .newCommentWrapper .commenterProfilePic {
    margin-top: 8px;
  }
  .commentsWrapper {
    padding: 8px 4px;
  }
  .comment {
    background: var(--elevation-1);
    padding: 4px 8px;
    border-radius: 40px;
  }
  .actorName {
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-basis: 18%;
  }
  .descriptionWrapper {
    flex-basis: 60%;
  }
  .timeLabel {
    white-space: pre;
  }
  .postHeader {
    background: url("assets/svg/recently_joined.svg") no-repeat 20px 0%/80px;
  }
  .postTitle {
    max-width: 100%;
  }
}
