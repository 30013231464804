.people_item {
  display: flex;
  flex-direction: row;
  padding: 16px 20px;
  box-sizing: border-box;
  align-items: center;
  background: #ffffff;
  border-bottom: 1px solid rgba(43, 50, 58, 0.1);
  width: 100%;
}

.peopleItemUserInteracted {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.people_item_cursor {
  cursor: pointer;
}

.radio_button {
  margin-top: 14px;
  margin-right: 0px;
  margin-left: 10px;
}

.image {
  border-radius: 8px;
  height: 40px;
  width: 40px;
  margin-top: 4px;
}

.content {
  margin-left: 16px;
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.body {
  text-align: left;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #687684;
  word-wrap: break-word;
  margin-top: 4px;
}

.transactionSummary {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* or 18px */

  letter-spacing: 0.01em;

  color: #687684;
  word-wrap: break-word;
}

.body a {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* or 18px */

  letter-spacing: normal;

  color: #687684;
  word-wrap: break-word;
}

.active_since {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* or 18px */

  letter-spacing: normal;

  color: rgba(104, 118, 132, 0.6);
  word-wrap: break-word;
}

.header {
  display: flex;
  align-items: center;
  gap: 6px;
}

.title {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 24px */

  letter-spacing: 0.01em;

  color: #1b1f22;
}

.net_worth {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  /* identical to box height, or 18px */

  letter-spacing: 0.01em;

  color: #687684;
  margin-left: 4px;
}

.circle {
  color: rgba(104, 118, 132, 0.24);
}

.checked {
  color: #0648d7;
}

.selected.selected {
  background: #fbfcfe;
}

.followButtonText {
  font-family: "Outfit";
  font-size: 16px;
}

@media (max-width: 768px) {
  .content {
    max-width: 70%;
  }
}

.networth {
  font-size: 13px;
  letter-spacing: normal;
  height: 0.12;
  color: #687684;
  font-weight: 400;
}
