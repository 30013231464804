// @import ""
@import "zxStyles/functions.scss";
@import "zxStyles/mixins.scss";
.table {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid cv(border-light);
}

.thead {
  display: flex;
  flex-direction: row;
  @include table-header-text;
}

.theadCell {
  display: flex;
  border-left: 1px solid cv(border-light);
  padding: 8px 16px;
  border-bottom: 1px solid cv(border-light);
}

.thead:first-child {
  border-left: none;
}

.trow {
  display: flex;
  border-bottom: 1px solid cv(border-light);
}

.skeletonCell {
  margin: 4px 8px;
}

.trowCell {
  display: flex;
  border-left: 1px solid cv(border-light);
}

.tableBody {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
}

.tableBody::-webkit-scrollbar {
  display: none;
}

.empty {
  margin-top: 120px;
}

.hideBorders {
  border: none;
}

.tokenPageTable {
  .theadCell {
    background-color: cv(elevation-1);
    padding: 12px 16px !important;
    color: cv(text-3);
    letter-spacing: 1px;
    font-size: 11px;
  }
}
