.query {
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 4px;
  padding-left: 4px;

  font-size: 15px;
  font-family: "ui-monospace";

  /* font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 180%;
  /* or 23px */

  /* Text I */

  color: var(--text-1);
}

.query .input {
  /* font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 180%;
  /* or 23px */

  /* Text I */

  font-size: 15px;
  font-family: "ui-monospace";

  color: var(--text-1);
}

.icon {
  cursor: pointer;
}

.search_option_icon {
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.selected_search {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 100px;
  max-width: 300px;
  background-color: inherit;
}

.icon_with_chain {
  position: relative;
  height: 16px;
  width: 16px;
  margin-right: 4px;
}

.search_chain_icon {
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: 1px solid var(--base);
  top: -2px;
  right: 0;
}

.option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 4px;
}
.dropdownOption:hover {
  background-color: var(--elevation-2);
}
