@import "components/Feed/feed";
@import "components/FeedMediaType/feedMediaType";
@import "components/GalleryModal/ActionButtons/actionButtons";
@import "components/GalleryModal/GhostGalleryFeedItem/ghostGalleryFeedItem";
@import "components/ValueType/valueType";
@import "components/ValueType/Copyable/copyable";
@import "shared/0xUI/PPLxNameValuePair/PPLxNameValuePair";
@import "shared/0xUI/PPLxHoverCard/PPLxHoverCard";
@import "shared/EmptyList/emptyList";
@import "shared/GhostLoader/Shimmer/shimmer";
@import "shared/GhostLoader/GhostElement/ghostElement";

* {
  box-sizing: border-box;
  margin: 0;
}

*:focus {
  outline: none;
}

#root {
  min-height: 100vh;
  display: block;
  color: black;
  font-family: "Roboto", sans-serif;
}

.App {
  height: 100vh;
  width: 100%;
}

button {
  border: none;
  cursor: pointer;
}
a {
  text-decoration: none;
  color: #0648d7;
}

.full-page-loader {
  height: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 64px;
    width: 64px;
  }
}

.bio-tooltip {
  border-radius: 16px !important;
  border: 1px solid rgba(104, 118, 132, 0.5) !important;
  box-shadow: 0px 24px 24px rgba(104, 118, 132, 0.1) !important;
  width: 400px;
  opacity: 1 !important;
}

.ghost-wrapper {
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.error {
  color: #bf1650;
  font-size: 12px;
  white-space: normal;
  line-height: 12px;
}

img {
  font-size: 12px;
}

.snackbar-alert {
  min-width: 400px;
}

@media screen and (max-width: 576px) {
  .snackbar {
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    .snackbar-alert {
      min-width: 100%;
      border-radius: 4px 4px 0px 0px !important;
    }
  }
}

.custom-table {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  gap: 10px;
  &__thead--tr,
  &__tbody--tr {
    display: flex;
    justify-content: space-between;
    .th,
    .td {
      font-size: 12px;
      width: -webkit-fill-available;
    }

    &.hide {
      display: none;
    }
  }
  &__thead--tr {
    border: none;
    border-radius: 4px;
    .th {
      height: 18px;
      font-weight: 600;
    }
  }
  &__tbody {
    .td {
      display: flex;
      align-items: center;
    }
  }
}
