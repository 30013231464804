.contract_info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 20px;
  justify-content: flex-start;
}

.top {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
}

.cards {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 800px;
  grid-gap: 20px;
}

.name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  /* identical to box height, or 26px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.contract_tag {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  /* identical to box height, or 16px */

  letter-spacing: normal;

  /* Base */

  color: var(--base);
  border-radius: 24px;
  background-color: var(--text-3);
  height: 18px;

  margin-left: 8px;
  padding: 1px 6px;
}

.chain_icon {
  height: 18px;
  width: 18px;
  margin-left: 8px;
}

.bottom,
.deployed_by_link {
  display: flex;
  flex-direction: row;
}

.deployed_by_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 130%;
  /* or 19px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.creator_image {
  height: 20px;
  border-radius: 50%;
  width: 20px;
  margin-left: 8px;
}

.protocol_image {
  height: 20px;
  border-radius: 50%;
  width: 20px;
}

.deployed_by_name {
  margin-left: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 130%;
  /* or 19px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.protocol_card_container {
  margin: 16px 0px;
  border: 1px solid var(--border-dark);
  border-radius: 6px;
  padding: 9px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.protocol_details {
  flex-grow: 1;
}

.protocol_name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);

  margin-left: 8px;
}

.protocol {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 2px;
}

.protocol_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.open_in_new_tab {
  height: 40px;
  color: var(--text-2);
  margin-right: 16px;
}
.layers_icon {
  height: 40px;
  color: var(--text-2);
  margin-right: 6px;
}

.view_protocol {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.view_protocol_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  text-align: right;
  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.icon {
  margin-left: 12px;
}

.socials {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.aliases {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
  flex-wrap: wrap;
  padding-top: 2px;
}

.address {
  margin-right: 12px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 130%;
  /* or 19px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.creator_details_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  border-top: 1px solid var(--border-dark);
  border-bottom: 1px solid var(--border-dark);
  padding-top: 9px;
  padding-bottom: 9px;
  margin-top: 20px;
}

.dot {
  padding-right: 12px;
  padding-left: 0px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 130%;
  /* or 19px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.edit_icon {
  margin-left: 8px;
}

.edit_form_heading {
  color: var(--text-1);
  font-size: 18px;
  font-family: Roboto;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: normal;

  margin-top: 10px;
}

.loader_button {
  width: 124px;
  height: 36px;
  border-radius: 18px;
}

.icon_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border-right: 1px solid var(--border-dark);
  padding-right: 8px;
}

@media (max-width: 768px) {
  .creator_details_container,
  .protocol_card_container {
    flex-wrap: wrap;
    gap: 6px;
  }
  .top {
    flex-wrap: wrap;
    gap: 6px;
  }
  .contract_tag {
    margin: 0;
  }
  .cards {
    width: auto;
  }
}
