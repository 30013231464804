.popup_form {
  display: flex;
  flex-direction: column;
  min-width: 300px;
}

.form_group {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.subtitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.divider {
  border-bottom: 1px solid var(--border-dark);
}
