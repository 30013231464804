.rightBarContainer {
  height: calc(100vh - 36px);
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 0;
  margin-top: 36px;
  top: 0;
  position: fixed;
  overflow-y: auto;
  scrollbar-width: none;
}

.rightBarContainer::-webkit-scrollbar {
  display: none;
}
.iconWrapper {
  position: relative;
  display: flex;
}

.holdingToken {
  height: 24px;
  width: 24px;
  border-radius: 50%;
}

.chainIcon {
  position: absolute;
  height: 14px;
  width: 14px;
  right: -4px;
  top: -3px;
  border: 1px solid #fff;
  border-radius: 50%;
}

.holdingProtocolTokenIcons {
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.holdingProtocolTokenIcons:not(:first-child) {
  margin-left: -4px;
}

.holdingProtocolTokenHiddenCount {
  color: var(--text-2);
  font-size: 13px;
  margin-left: 2px;
}

.waitlistTokenImage {
  height: 24px;
  width: 24px;
  border-radius: 50%;
}

.holdingsItem {
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}

.holdingsIcon {
  flex-shrink: 0;
}

.holdingsIconNFT {
  border-radius: 0px;
}

.watchlistBanner {
  width: 100%;
  border-radius: 4px;
  background: var(--elevation-1);
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
}
.walletBarContainer.rightBarContainer {
  background-color: var(--base);
  top: 28px;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: auto;
}
.walletBarStep {
  display: flex;
  row-gap: 16px;
  width: 100%;
  flex: 1;
  width: 264px;
}
.walletBarStepNumber {
  min-width: 32px;
  height: 32px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--success-light);
  color: var(--success);
  font-size: 16px;
  font-weight: 600;
  margin-right: 16px;
}

.walletBarStepText {
}
