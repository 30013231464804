.groupedActivity {
  display: flex;
  flex-direction: column;
  background: var(--base-light-only);
  padding: 16px;
  margin: 0;
  border-radius: 12px;
  font-family: Roboto;
  letter-spacing: normal;
}
.noPadding {
  padding: 0;
}
.homePageGroupedActivity {
  margin: 0px;
  border: 0px;
  border-radius: 0px;
}
.homePageGroupedActivity .homePageGroupedActivity {
  border-bottom: 0;
}
.isParent {
  background: transparent;
  border-bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
  border: 0;
}
.isParentContainer {
  background: transparent;
  border-bottom: 0;
  padding: 8px 20px 0 20px;
}
.isParentContainer .isParent {
  padding: 0;
}
.welcomePost {
  padding: 8px 20px 0 20px;
}
.hasParent {
  background: transparent !important;
  border-top: 0;
  padding-top: 0;
}
.homePageGroupedActivity.solidBg:hover {
  background: var(--elevation-1);
}
.groupedActivity.solidBg {
  background: var(--base);
}
.groupedActivity.noMarginTop {
  margin-top: 0;
}
.groupedActivity.noMargin {
  margin-top: 0;
  margin-bottom: 0;
}
.groupedActivity.blockInteraction {
  pointer-events: none;
}

.groupedActivity.nonClickable {
  cursor: auto;
}
.groupedActivity.nested {
  padding: 0;
  border: 0;
  margin: 0;
  background: none;
}
.nested .isParent {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-bottom: 0;
  padding: 0;
}
.groupedActivity.news {
  padding: 0;
  cursor: default;
}

.groupedActivity.welcomePost {
  padding: 0;
}

.showThreadLine {
  padding-bottom: 8px;
}

.interactionContext {
  display: flex;
  align-items: center;
  gap: 8px;
}
.interactionContext .icon {
  flex-basis: 50px;
  display: flex;
  justify-content: flex-end;
}
.interactionContext .icon img {
  height: fit-content;
}
.label {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  color: var(--text-2);
}

.attribution_featured {
  color: #079d39;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.attribution_liked {
  color: var(--text-1);
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.attribution_twitter {
  color: var(--text-1);
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.attribution_invited {
  color: #0648d7;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.attribution_text {
  margin-left: 5px;
}

.actorInfoLink {
  color: inherit;
  margin-left: 5px;
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.profilePageItem.groupedActivity {
  border: 1px solid transparent;
}

.groupedActivity.reposted {
  margin-top: 8px;
  margin-bottom: 0;
  border: 1px solid var(--border-dark) !important;
}

.gmxFeedInfoContainer {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  row-gap: 16px;
}
