.parentImage {
  z-index: 0;
  position: absolute;
}

.container {
  position: relative;
}

.profileImage {
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0 2px #fff;
}

.childImage {
  z-index: 1;
  position: absolute;
  padding: 8px;
}

.appText {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 129px;
  text-align: center;
}

.qrCodeFloating {
  width: 132px;
  height: 151px;
  left: 60px;
  bottom: 0;
  position: fixed;
  margin-bottom: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
}

.blur {
  position: absolute;
  width: 116px;
  height: 124px;
  z-index: 3;
  backdrop-filter: blur(3px);
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blur button {
  background-color: var(--base);
  color: var(--text-1);
  font-size: 12px;
  padding: 5px 8px;
  border: none;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .qrCodeFloating {
    display: none;
  }
}
