
.checkboxWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

.activeWrapper {
    background-color: var(--primary-color12);
    color: var(--primary-color);
}

.disabledWrapper {
    opacity: 0.5;
}

.checkbox {
    opacity: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.checkmark {
    position: absolute;
    right: 25px;
    top: 50%;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: var(--text-white);
    padding: 2px;
    border: 2px solid var(--gradient-divider-base-color-1);
    transform: translateY(-50%);
}

.checkmark.active {
    border-color: var(--primary-color);
    border-width: 6px;
}

.checkmark.disabled {
    opacity: 0.5
}
