.confidences {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.circles {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 5px;
}

.circles > div {
  height: 5px;
  width: 5px;
  border-radius: 1px;
}

.low {
  background-color: var(--error);
}

.medium {
  background-color: var(--warning);
}

.high {
  background-color: var(--success);
}

.default {
  background-color: var(--text-2);
}

.confidence_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;
  text-transform: lowercase;

  /* Text II */

  color: var(--text-2);

  margin-left: 8px;
}

.confidence_text::first-letter {
  text-transform: uppercase;
}

.table_style_font {
  margin-left: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Success */

  color: var(--text-1);
}
