.feed {
  background-color: var(--border-dark);
  width: 100%;
  padding: 0px;
  gap: 0px;
  max-width: 800px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border-top: 0;
  height: fit-content;

  &__filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 32px;
    gap: 12px;
    font-size: 12px;
    line-height: 14px;
    color: #687684;
    border-bottom: 1px solid #6775831a;
    background: var(--base);
    &--options {
      font-weight: 600;
    }
  }
  .filter-options {
    display: flex;
    gap: 10px;
    align-items: baseline;
    &__label,
    &__item {
      color: #687684;
      font-size: 12px;
      font-family: Roboto;
    }
    &__label {
      font-weight: 400;
      letter-spacing: normal;
    }
    &__item {
      background: none;
      padding: 0;
      font-weight: 600;
      letter-spacing: 0.04em;
      padding-bottom: 2px;
      &--active {
        color: #0648d7;
        border-bottom: 1.5px solid #0648d7;
      }
    }
  }

  .fetch-error {
    padding: 50px;
  }

  .admin-wrapper {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    .admin-filters {
      color: #687684;
      letter-spacing: normal;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }

  &__more-activity {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 32px;
    font-size: 14px;
    line-height: 14px;
    color: #0648d7;
    border-bottom: 1px solid #bdc5cd;
    font-weight: 600;
    border-radius: 0% 0% 50% 50%;
    cursor: pointer;
  }
}

@media screen and (max-width: 576px) {
  .feed {
    &__filters {
      display: none;
    }
  }
}
.DraftEditor-root,
.DraftEditor-editorContainer,
.public-DraftEditor-content {
  height: 100%;
  flex-grow: 1;
}
.public-DraftEditorPlaceholder-inner {
  position: absolute;
  color: var(--text-3);
}
.public-DraftEditorPlaceholder-root {
  position: relative;
}
.public-DraftEditor-content {
  color: var(--text-1);
}
