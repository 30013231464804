.button {
  height: 56px;
  border-radius: 6px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  text-align: center;
  letter-spacing: normal;

  color: #ffffff;
}

.primary {
  background: var(--primary-color);
}

.secondary {
  background: #f5f7f9;
}

.disabled {
  opacity: 0.4;
}

.refresh_icon {
  height: 24px;
  width: 24px;
}

.primary_outlined {
  border: 1px solid rgba(6, 72, 215, 1);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  text-align: center;
  letter-spacing: normal;

  color: #0648d7;
  background-color: #ffffff;
}
