.profile_header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main {
  width: 800px;
}

.cards {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  grid-gap: 20px;
}

.profileInfoShimmer {
  display: block;
  background-color: var(--base);
  padding: 0 20px;
}

@media (max-width: 768px) {
  .cards {
    overflow: auto;
    width: 90vw;
  }
  .main {
    width: 95vw;
  }
  .profile_header {
    padding-top: 16px;
    align-items: flex-start;
  }
}

.dot,
.dotLight {
  display: inline-block;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background-color: var(--text-2);
  margin: 0 2px;
}

.follower_info {
  font-size: 13px;
  color: var(--text-2);
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.mutual_contacts_cont {
  background-color: var(--elevation-1);
  padding: 6px 12px;
  border-radius: 6px;
  margin: 8px 0;
}
.follower_info_item {
  display: flex;
  align-items: center;
  gap: 4px;
}

.count {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-1);
}
