.filtersContainer {
  display: flex;
  gap: 4px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: var(--text-3);
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.filterPill {
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 13px;
  color: var(--text-1);
  background-color: var(--base);
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: var(--primary-color10);
  }
}

.isActive {
  background-color: var(--primary-color10);
  font-weight: 500;
  color: var(--primary-color);
}
