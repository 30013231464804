.activityHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.actorDetailsHeader {
  display: flex;
  align-items: baseline;
  gap: 8px;
  width: fit-content;
  line-height: 150%;
}
.actorInfoLink {
  display: flex;
  align-items: center;
  gap: 4px;
  text-decoration: none;
}
.actorName {
  font-size: 15px;
  font-weight: 500;
  max-width: 32vw;
  letter-spacing: 0.2px;
  color: var(--text-1);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.actorName.trimWidth {
  max-width: 85px;
  text-wrap: nowrap;
}
.actorName.mini {
  font-weight: 500;
}
.actorName:hover {
  text-decoration: underline;
  text-decoration-color: var(--text-2);
}
.timeLabel {
  font-weight: 400;
  flex-shrink: 0;
}

.lens,
.farcaster,
.mirror,
.snapshot {
  display: flex;
  gap: 4px;
  color: var(--text-3);
  align-items: center;
}
.lens img,
.farcaster img,
.mirror img,
.snapshot img {
  height: 12px;
  width: 12px;
}
@media (max-width: 768px) {
  .actorName {
    max-width: unset;
  }
}
@media (max-width: 425px) {
  .lens .label,
  .farcaster .label,
  .mirror .label,
  .snapshot .label {
    display: none;
  }
  .actorDetailsHeader {
    flex-wrap: wrap;
  }
}
