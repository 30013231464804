.avatarWrapper {
  position: relative;
}

.presence {
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid var(--base);
  background-color: var(--green);
}

.groupIcon {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid var(--base);
  background-color: var(--base);
  display: flex;
  align-items: center;
  justify-content: center;
}
