.net_worth_card {
  position: relative;
  background-size: cover;
  width: 500px;
  height: 180px;
  padding: 16px 20px;
  border: 1px solid var(--border-dark);
  border-radius: 4px;
  flex-shrink: 0;
  transition: all 0.1s ease-in;
  cursor: pointer;
}
.net_worth_card:hover {
  background-color: rgba(6, 72, 215, 0.03);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.04);
  border: 1px solid var(--card-hover);
}
.shimmerBox {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container_class {
  width: 240px;
  height: 160px;
  border: 1px solid var(--border-dark);
  border-radius: 4px;
}

.net_worth_info {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.top {
  flex-grow: 1;
}
.categories {
  padding: 0;
  margin: 0;
}
.category {
  height: 28px;
  display: flex;
  align-items: center;
}

:global(.Mui-disabled) {
  color: var(--primary-color40) !important;
}
.filterCheckbox {
  padding: 0 !important;
}

.category_value {
  color: var(--text-1);
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 400;
  line-height: 130%; /* 14.3px */
  letter-spacing: normalpx;
  margin-top: 4px;
}

.value {
  display: flex;
  align-items: center;
  gap: 8px;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 13px */

  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Text II */

  color: var(--text-2);
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.value_number {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

@media (max-width: 768px) {
  .net_worth_card {
    flex-shrink: 0;
  }
}

.graph_component_refetch {
  width: 100%;
  display: flex;
  align-items: center;
}

.graphContainer {
  position: absolute;
  bottom: 0;
  right: 0;

  width: 100%;
  height: 100%;
  overflow: hidden;
}
.graphError {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 200px;
  right: 60px;
}
.graph {
  border-radius: 4px;
  right: 0;
  position: absolute;
  bottom: 16px;
}

.graphLoader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.holdingsTile {
  font-size: 11px;
  color: var(--text-1);
}

.holdingsValue {
  font-size: 11px;
  font-weight: 600;
  color: var(--text-1);
}

.dropdownButton {
  cursor: pointer;
  color: var(--text-2) !important;
  z-index: 1;
}

.dropdownList {
  width: 66px !important;
}
