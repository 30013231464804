@keyframes flip {
  0% {
    transform: rotateY(0);
  }

  50% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

.loader {
  height: 10%;
  width: auto;
  max-height: 48px;
  max-width: 48px;
  min-height: 24px;
  min-width: 24px;
  animation: flip 2s infinite;
}