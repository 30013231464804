.profileActivities {
  display: flex;
  flex-direction: column;
}

.filtersPadding {
  padding-top: 16px;
}

.profileActivitiesFilters {
  margin-left: 11px;
  padding: 0 16px;
  height: 48px;
}
