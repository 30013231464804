.image {
  width: 100%;
  height: 120px;
  border-radius: 8px;
}

.flagContainer {
  height: 30px;
  padding: 4px 12px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  font-weight: 600;
  column-gap: 4px;
  cursor: pointer;
}

.redFlag {
  background-color: var(--error-light);
  color: var(--error);
}

.greenFlag {
  background-color: var(--success-light);
  color: var(--success);
}

.text {
  font-size: 13px;
  line-height: 20px;
  color: var(--text-2);
  font-weight: 400;
}

.showMoreText {
  color: var(--primary-color);
  font-weight: 400;
}
