.people_list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.people_list_horizontal {
  display: flex;
  flex-direction: column; /* Changed from row to column */
  flex-grow: 1;
  height: 100%;
}

.people_list::-webkit-scrollbar {
  width: 0.5em;
  background-color: #f5f5f5;
}

.people_list::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.people_list::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.people_list:last-child {
  padding-bottom: 40px;
}
