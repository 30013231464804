.introCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ded8f5;
  border-radius: 12px;
  padding: 20px;
  position: relative;
  width: 100%;
  height: 150px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.backBtn {
  cursor: pointer;
}

.introText {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 15px;
  width: 60%;
}

.icons {
  display: flex;
  gap: 12px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: rgba(104, 118, 132, 0.12);
  margin: 20px 0;
  position: relative;
}

.optionsSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-top: 30px;
}

.option {
  display: flex;
  align-items: center;
  gap: 16px;
  background: #fff;
  border: 1px solid #68768412;
  border-radius: 12px;
  padding: 20px;
  width: 100%;
  cursor: pointer;
}

.icon {
  width: 45px;
  height: 45px;
}

.eth {
  position: absolute;
  top: 20px;
  right: 80px;
}

.k {
  position: absolute;
  top: 80px;
  right: 110px;
}

.sol {
  position: absolute;
  top: 70px;
  right: 29px;
}

.optionText {
  display: flex;
  flex-direction: column;
}

.proceedButton {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0648d7;
  color: #fff;
  border-radius: 12px !important;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
  border: none;
  margin-top: 20px;
  height: 48px !important;
  margin-bottom: 20px;
}

.divider {
  width: 100%;
  height: 1px;
  background: var(--text-2);
  opacity: 0.24;
}
