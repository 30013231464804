.dropdownTile {
  color: var(--text-1);
  font-family: "Roboto";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 19.5px */
  letter-spacing: normal;
  padding: 4px 10px;
  width: 100%;
  cursor: pointer;
}

.small {
  padding: 4px 4px !important;
  font-size: 11px !important;
}

.small .dropdownTile {
  font-size: 11px;
  color: var(--text-2);
  line-height: 14px;
}

.dropdownTile:hover {
  background: var(--elevation-2);
}

.dropdownBox {
  width: 300px;
  position: relative;
  padding: 6px 0px;
}

.openedDropdown {
  border-color: rgba(60, 60, 178, 0.346) !important;
}

.openedDropdown svg {
  transform: rotate(180deg);
}
.searchInput {
  padding: 2px 8px;
  border: 1px solid var(--border-dark);
  border-radius: 4px;
  font-size: 14px;
  height: 32px;
  width: 100%;
  color: var(--text-1);
  background-color: var(--base);
}

.searchInput:focus {
  border: 1px solid var(--border-dark);
}
