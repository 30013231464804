.graphContainer {
  position: relative;
}
.tooltipContainer {
  height: 80px;
  position: absolute;
  box-sizing: border-box;
  font-size: 12px;
  text-align: left;
  z-index: 1000;
  top: 12px;
  left: 12px;
  pointer-events: none;
  background: var(--base);
  color: black;
  border-color: var(--text-1);
  backdrop-filter: blur(16px);
  padding: 12px;
  border: 1px solid var(--border-dark);
  border-radius: 8px;
}

.ohlc {
  height: auto;
}
.name {
  color: var(--text-1);
}

.price {
  font-weight: 600;
  font-size: 16px;
  margin: 4px 0px;
  color: var(--text-1);
}

.timeStamp {
  color: var(--text-2);
}
.attribution {
  position: absolute;

  left: 10px;
  top: 0px;
  z-index: 3; /* place above the charts */
  padding: 10px 0px 0px 12px;
  font-size: 12px;
  backdrop-filter: blur(10px);
}
.attributionText {
  color: var(--text-3);
}
