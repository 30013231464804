.tokenPriceWrapper {
  display: flex;
  gap: 4px;
  align-items: left;
  color: var(--text-1);
  font-size: 15px;
  /* margin-left: 16px; */
}

.tokenChangePercentage {
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: normal;
}
.up {
  color: var(--success);
}
.down {
  color: var(--error);
}
