.groupedActivityEngagements {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0 16px;
  padding: 16px 0 8px;
  border-bottom: 1px solid var(--border-dark);
  font-family: Roboto;
  font-size: 13px;
  line-height: 150%;
  color: var(--text-2);
  flex-direction: column;

  @media screen and (max-width: 768px) {
    align-items: flex-start;
    gap: 16px;

    .engagements {
      padding-left: 8px;
    }
  }
}

.activityEngagementWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid var(--border-dark);
  margin-top: 12px;
  padding-top: 12px;
}
.currentEngagements {
  width: 100%;
  padding-left: 8px;
}
.engagements {
  display: flex;
  gap: 20px;
}
.actionButtons {
  display: flex;
  /* gap: 25px; */
  /* margin-right: 12px; */
  margin-top: 0;
}
.button {
  padding: 0;
  background: none;
  color: var(--text-2);
}
.button img {
  height: 14px;
  width: auto;
}

.interactionItem {
  display: flex;
  gap: 4px;
  color: var(--text-2);
}
.label {
  font-weight: 400;
  font-size: 13px;
}
.count {
  font-weight: 600;
  font-size: 13px;
}
