.content {
  display: flex;
  flex-direction: row;
  height: 256px;
  gap: 102px;
}

.headingText {
  color: var(--text-1);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  /* 25.2px */
  letter-spacing: normal;
}

.headingElement {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 40px;
  margin-top: 24px;
}

.headingDiv {
  width: 271px;
  height: 50px;
}

.qrElement {
  margin-top: 8px;
  margin-left: 40px;
}

.scanQRText {
  color: var(--text-2);
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 19.5px */
  letter-spacing: normal;
}

.qrcode {
  flex-shrink: 0;
  margin-left: 40px;
  margin-top: 20px;
  position: relative;
}

.mobilePreviewImage {
  width: 288px;
  height: 256px;
  flex-shrink: 0;
}

.cross {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  position: relative;
  margin-right: 20px;
  margin-top: 20px;
  background: var(--base);
}

.mobilePushNotificationTile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: var(--base);
  border: 1px solid var(--border-dark);
  border-radius: 6px;
  margin-top: 20px;
}
