.example_right_panel {
  padding: 20px 0;
}
.close_button {
  margin-left: 16px;
}
.heading {
  color: var(--text-1);
  font-size: 20px;
  font-family: Roboto;
  font-weight: 600;
  letter-spacing: normal;
  margin-top: 16px;
  padding: 0 16px;
}

.sub_heading {
  color: var(--text-2);
  font-size: 15px;
  font-family: Roboto;
  line-height: 150%;
  letter-spacing: normal;
  margin-top: 4px;
  padding: 0 16px;
  margin-bottom: 16px;
}

.command_list {
  padding: 16px 20px;
  border-top: 1px solid var(--border-dark, rgba(104, 118, 132, 0.1));
  background: var(--background, #f8f8f9);
}

.query_card {
  border-radius: 4px;
  border: 1px solid var(--border-dark, rgba(104, 118, 132, 0.1));
  background: var(--base, #fff);
  padding: 16px 12px;
  margin-bottom: 12px;
}

.description {
  color: var(--text-2);
  font-size: 13px;
  font-family: Roboto;
  line-height: 150%;
  letter-spacing: normal;
  margin-top: 4px;
}
