.table {
  display: flex;
  flex-direction: column;
  border-collapse: collapse;
  width: 100%;
  padding-bottom: 16px;
}
.table.noPaddingBottom {
  padding-bottom: 0;
}
.table .tableRow {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  gap: 12px;
  min-height: 20px;
  padding: 0 20px;
}

.borderBottom:not(:last-child) {
  border-bottom: 1px solid var(--border-dark);
}

.tableRow:not(:last-of-type) {
  margin-bottom: 16px;
}

.table .tableHeader {
  border-bottom: 1px solid var(--border-dark);
  margin-bottom: 16px;
  padding: 0 20px 8px 20px;
  line-height: 150%;
}

.tableHeader.paddingTop {
  padding-top: 8px;
}

.tableHeader.background {
  background: var(--elevation-1);
}

.tableHeader.noBorder {
  border: none;
}

.tableRow.noPadding {
  padding: 0;
}
.tableRow.noMarginBottom {
  margin-bottom: 0;
}
.table .tableHeader {
  padding-bottom: 8px;
  line-height: 150%;
}
.tableRow.alignToBaseline {
  align-items: baseline;
}
.tableRow.alignToFlexStart {
  align-items: flex-start;
}

.tableHeader .tableCell {
  font-size: 12px;
  font-weight: 500;
  color: var(--text-2);
  text-transform: uppercase;
  letter-spacing: 0.08em;
}

.tableTitle {
  color: var(--text-1);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: normal;
  text-transform: none;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  line-height: 150%;
}
.subTitle {
  display: flex;
  color: var(--text-3);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
}
.tableCell.shrink {
  flex-basis: 4%;
}
.tableCell.longRow {
  flex-basis: 700px;
}
.tableCell.smallRow {
  flex-basis: 15%;
}
.tableCell {
  flex-basis: 25%;
  display: flex;
  align-items: center;
  color: var(--text-1);
  flex-wrap: wrap;
}
.tableCell:nth-child(2) {
  flex-grow: 1;
}

.alignRight {
  text-align: right;
  justify-content: flex-end;
}

.alignLeft {
  text-align: left;
  justify-content: flex-start;
}
.alignCenter {
  text-align: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .table {
    width: 100%;
    overflow: auto;
  }
  .tableCell {
    flex-shrink: 0;
  }
  .tableCell:nth-child(2) {
    flex-grow: 0;
  }
}
