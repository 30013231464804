.trending-card-sale {
  padding: 0 !important;
}

.trending-sale-container .sale-container {
  width: 120px;
  height: fit-content;
  padding: 0;
  margin-top: 8px;
}

.sale-action-container {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.sale-info {
  display: flex;
  align-items: center;
}

.sale-time {
  align-self: flex-end;
}

.sale-image {
  padding: 0;
  width: 144px;
  height: 144px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  z-index: -1;
}

.sale-avatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.sale-chain-logo {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  align-self: center;
  justify-self: center;
}

.sale-value {
  margin-right: 4px;
  margin-left: 2px;
}

.sale-price-container {
  display: flex;
  align-items: center;
  column-gap: 6px;
  margin-top: 2px;
}
