.metamask {
  display: flex;
  flex: row;
  align-items: center;
  padding: 0 0;
  margin: 12px 0;
  position: relative;
}

.metamask_address {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.metamask_connected {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 110%;
  letter-spacing: -2%;
  color: var(--text-2);
}

.address {
  font-size: 15px;
  font-family: Roboto;
  line-height: 150%;
  letter-spacing: normal;

  color: var(--text-1);
}

.matamask_logo_img {
  height: 20px;
  width: 20px;
  margin-right: 12px;
}

.metamask_connected_round {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--success);
  left: 15px;
  top: 7px;
  border: 1px solid var(--base);
}
