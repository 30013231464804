.rightPanel {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--elevation-1);
  position: relative;
}
.header {
  background: var(--base);
  border-bottom: 1px solid var(--elevation-2);
  padding: 16px 20px 16px 20px;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  /* or 23px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.listContainer {
  padding: 16px 20px;
}
