.nftItem {
  flex-shrink: 0;
  width: 174px;
  background: var(--base);
  border: 1px solid var(--border-dark);
  border-radius: 4px;
  overflow: hidden;
}
.nftItems {
  display: grid;
  grid-template-columns: repeat(auto-fill, 174px);
  grid-auto-rows: minmax(0, 1fr);
  gap: 20px;
}

.expanded {
  background: rgba(6, 72, 215, 0.1);
  overflow: unset;
  position: relative;
}
.expandedArrow {
  position: absolute;
  bottom: -14px;
  left: 50%;
  transform: translateX(-50%);
}
.extraImages {
  background: var(--base);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, minmax(50%, 1fr));
  gap: 1px;
  overflow: hidden;
  height: 145px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.viewAll {
  font-family: Roboto;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: normal;
  color: var(--text-2);
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0;
  background: none;
}

.placeholder {
  background: var(--elevation-2);
  height: 100%;
  width: 100%;
}

.rightPane .extraImages {
  display: none;
}
@media (max-width: 768px) {
  .nftItem {
    width: 100%;
    display: flex;
  }
  .extraImages.hideImage {
    display: none;
  }
  .extraImages {
    width: 64px;
    height: 64px;
  }
}
