.accordionWrapper {
  margin: 12px 0;
  border-radius: 6px;
  padding: 0px;
  background: var(--base);
  border: 1px solid var(--border-dark);
}

.accordionHeader {
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  cursor: pointer;

  color: var(--text-1);
  font-size: 13px;
  font-family: Roboto;
  line-height: 150%;
  letter-spacing: normal;

  height: 44px;
  padding: 0 20px;
}

.collapseExpandIcon {
  background: none;
  color: var(--text-3);
  width: 18px;
  height: 18px;
}

.helpIcon {
  background: #ebebeb;
  height: 16px;
  width: 16px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  font-weight: normal;
}

.body {
  margin: 10px 20px;
  display: none;
  overflow: auto;
}

.expanded {
  display: block;
}
