.secondaryButton {
  font-family: "Roboto";
  height: 32px;
  width: auto;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background-color: var(--elevation-2);
  color: var(--text-2);
  cursor: pointer;
  position: relative;
  font-size: 13px;
  font-weight: 600;
  padding: 6px 12px;
  color: var(--primary-color);
}

.walletList {
  width: 100%;
  margin: 0 auto;
  padding: 16px;
}

.walletListWrapper {
  display: flex;
  justify-content: center;
}
