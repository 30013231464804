.activityEngagements {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  position: relative;
}
.activityEngagements.padding {
  padding: 0px 20px 16px 20px;
}
.activityActionButton {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  color: var(--text-2);
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0px;
  padding: 0;
  background: none;
  z-index: 2;
}
.iconWrapper.hasRoundedButtons,
.iconWrapper.hasRoundedButtons:hover {
  height: 32px;
  width: max-content;
  border: 1px solid var(--border-light);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.iconWrapper {
  display: flex;
  padding: 6px;
  height: 32px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%; /* 18px */
  letter-spacing: normal;
  text-wrap: nowrap;
}
.count {
  width: 40px;
  text-align: left;
  font-size: 12px;
}
.commentIcon,
.likeIcon {
  stroke: var(--text-2);
  font-weight: 500;
}

.shareIcon,
.bookmarkIcon {
  font-weight: 500;
  fill: var(--text-2);
}

.activityActionButton:hover {
  color: var(--success);
}

.commentButton:hover {
  color: var(--primary-color);
}
.likeButton:hover {
  color: var(--error);
}
.shareButton:hover {
  color: var(--success);
}
.bookmarkButton:hover {
  color: var(--method);
}

.likeButton.active {
  color: var(--error);
}
.likeButton.active svg {
  fill: var(--error);
  stroke: var(--error);
}

.bookmarkButton.active {
  color: var(--method);
}

.bookmarkButton.active svg {
  fill: var(--method);
}
.shareButton.active {
  color: var(--success);
}

.shareButton.active svg {
  fill: var(--success);
}

.activityActionButton:hover .iconWrapper {
  background: var(--elevation-2);
}

.activityActionButton:hover .likeIcon {
  stroke: var(--error);
}

.activityActionButton:hover .commentIcon {
  stroke: var(--primary-color);
}

.activityActionButton:hover .shareIcon {
  fill: var(--success);
}

.activityActionButton:hover .bookmarkIcon {
  fill: var(--method);
}

.generatedCommentsRow {
  display: flex;
  align-items: center;
  overflow-x: auto;
  column-gap: 8px;
}

.generatedCommentsRow::-webkit-scrollbar {
  display: none;
}

.engagementsRow {
  padding-right: 10px;
}

@media (max-width: 768px) {
  .engagementsRow {
    flex-wrap: wrap;
  }
}
