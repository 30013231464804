.card-swap {
  width: 146px;
  max-width: 146px;
  min-width: 146px;
  height: 208px;
}

.swap-content {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.swap-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-bottom: 8px;
}

.swap-token-logo {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 4px;
}

.swap-token-info {
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
}

.swap-time {
  margin-top: 8px;
}

.swap-actor-name {
  display: inline-block;
  width: 100%;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
