.copyable {
  display: flex;
  align-items: center;
  &__button:hover {
    opacity: 1;
  }
  &__button,
  &__copied {
    display: flex;
    height: 16px;
    padding-right: 0;
    background: none;
    &--icon {
      height: 16px !important;
      width: 16px !important;
      color: var(--text-1);
    }
  }
  &__copied {
    color: #0648d7;
    cursor: auto;
  }
  &__opaque {
    opacity: 1;
    padding: 0;
  }
}
