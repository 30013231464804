.tokenInfo {
  display: flex;
  gap: 10px;
}
.tokenInfo.noShrink {
  flex-shrink: 0;
}
.tokenNameWrapper {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--text-1);
}
.tokenName {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  color: var(--text-1);
  font-family: "Roboto";
}
.tokenIconWrapper {
  position: relative;
}
.tokenIcon.tokenIcon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
.chainIcon.chainIcon {
  position: absolute;
  height: 12px;
  width: 12px;
  right: -2px;
  top: -3px;
}
