.tokenInfo {
  display: flex;
  align-items: center;
  color: var(--text-1);
}
.infoWrapper {
  color: var(--text-1);
}
.isTrending .infoWrapper {
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: normal;
}
.isTrending .protocolIcon {
  height: 20px;
  width: 20px;
  margin-right: 6px;
}

.protocolIconWrapper {
  position: relative;
  display: flex;
}
.protocolIcon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 8px;
}
.overlay {
  margin: 0;
}
.overlay:not(:first-child) {
  margin-left: -4px;
}
