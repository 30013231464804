.avatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 4px;
}

.profile_dropdown {
  width: 200px;
  height: 213px;
  background-color: var(--navBg);
  /* border: 1px solid var(--border-dark); */
  padding: 0 15px;
  display: flex;
  flex-direction: column;
}

.divider {
  border-bottom: 1px solid var(--border-dark);
  height: 1px;
  width: 100%;
  margin-bottom: 16px;
}

.dropdown_item {
  margin-bottom: 16px;
  color: var(--text-1);
  font-size: 15px;
  font-family: Roboto;
  line-height: 150%;
  letter-spacing: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.logout {
  color: var(--error);
  font-size: 15px;
  font-family: Roboto;
  line-height: 150%;
  letter-spacing: normal;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 12px;
}
