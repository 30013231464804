.groupedActivityWrapper {
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
  font-size: 15px;
}
.newTransactions {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-bottom: 4px;
}
.groupedActivityWrapper.noPaddingTop {
  padding-top: 0;
}
.groupedActivityWrapper.newsReply {
  padding: 0px 20px 16px 20px;
}
.newsWrapper {
  padding: 16px 20px;
  /* background-color: var(--newsBg); */
  border-bottom: 1px solid var(--border-dark);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.actor {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 8px;
  margin-bottom: 8px;
}

.showThreadLine {
  padding-bottom: 8px;
}

.threadDivider {
  width: 16px;
  height: 40px;
  margin-top: 8px;
}
.threadDivider.newsDivider {
  margin-left: 35px;
  margin-top: -16px;
}
.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
}

.activitySummary {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.fallbackActivitiesContainer {
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.avatarWrapper {
  position: relative;
}
.avatar {
  margin-top: 4px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.avatar.mini {
  height: 25px;
  width: 25px;
}
.avatarBadge {
  position: absolute;
  right: -4px;
  top: 2px;
  padding: 3px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: var(--text-3);
  backdrop-filter: blur(8px);
  border: 1px solid var(--base);
}

.avatarBadge svg {
  display: block;
  margin: auto;
  height: 100%;
  width: auto;
}
.postImagesWrapper {
  display: flex;
  gap: 8px;
  margin-top: 16px;
  margin-bottom: 8px;
  max-width: 680px;
  overflow-x: auto;
}
.postImage {
  height: 120px;
  width: 120px;
  object-fit: cover;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  cursor: auto;
}
.twitter_news {
  color: var(--text-1);
}

.reposted {
  border-radius: 8px;
  border: 1px solid var(--border-dark);
}

.followFeedButton {
  position: absolute;
  bottom: 0;
  right: -8px;
}
