.ghost-gallery-feed-item {
  &__details {
    display: flex;
    gap: 13px;
    margin-bottom: 30px;
    .ghost {
      margin: 0;
    }
    &--info {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .ghost {
        width: 100px;
      }
    }
  }
  &__body {
    .ghost {
      width: 100%;
      &:last-of-type {
        width: 80%;
      }
    }
  }
}
