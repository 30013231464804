.btn {
  cursor: pointer;
  background: var(--primary-color);
  border-radius: 4px;
  padding: 10px 32px 10px 32px;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 27px */

  letter-spacing: 0.2px;

  /* Text White */

  color: #ffffff;
  min-width: 100px;
}

.helpMessage {
  margin-top: 8px;
  /* Dark Yellow background with 8px padding all around */
  background: rgb(255, 227, 172);
  padding: 8px;
  /* Rounded border */
  border-radius: 4px;
  /* Display flex */
  display: flex;
  align-items: center;
  color: var(--text-1);
  font-style: normal;
  font-size: 12px;
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.large {
  cursor: pointer;
  background: var(--primary-color);
  border-radius: 4px;
  padding: 10px 32px 10px 32px;
  font-family: "Outfit";
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  /* identical to box height, or 27px */

  letter-spacing: normal;

  /* Text White */

  color: #ffffff;
  min-width: 100px;
}
