/* src/components/V2/NewOnboarding/WalletLoader/WalletLoader.module.css */
.walletLoaderContainer {
    display: flex;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #0000;
  }
  
  .walletLoaderContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .walletLoaderSpinner {
    margin-bottom: 30px;
  }
  
  .rotatingImage {
    width: 260px;
    height: 260px;
  }
  
  .walletLoaderText h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .walletLoaderText p {
    font-size: 16px;
    color: #666;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  