.connectButton {
  font-family: "Roboto";
  height: 38px;
  width: auto;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: var(--elevation-2);
  color: var(--text-2);
  cursor: pointer;
  position: relative;
  font-size: 13px;
  font-weight: 500;
  padding: 6px 12px;
  color: var(--primary-color);
}
