.searchBar {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.searchBarPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-3);
  margin-right: 8px;
  column-gap: 8px;
}
.searchBarLoading {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.searchResultsContainer {
  position: absolute;
  top: 38px;
  border: 1px solid var(--border-dark);
  padding: 0px 12px;
  width: 100%;
  list-style: none;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.08);
  background: var(--base);
  border-radius: 4px;
  max-height: 300px;
  overflow: auto;
  z-index: 8;
}
