.container {
  display: flex;
  background: #f8f8f9;
  min-height: 100vh;
}

.sidebar {
  background: #f8f8f9;
  min-width: 320px;
  width: 23%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px;
}

.logo {
  width: 80px;
  height: 80px;
  box-shadow: 0px 4.27px 12.8px 0px #08173814;
  border-radius: 16px;
}

.contentHeaderActions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 16px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 60px 20px 8%;
  min-width: 600px;
  max-width: 600px;
}

.mainContent {
  margin-top: 40px;
  width: 100%;
}

.talkToUs {
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(104, 118, 132, 1);
  background: rgba(104, 118, 132, 0.08);
  border-radius: 8px;
  padding: 6px 15px;
  cursor: pointer;
  margin-left: auto;
}

.paginationContainer {
  background: #f0f2f5;
  padding: 5px 8px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.liveChat {
  background-color: var(--success-light);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.linksContainer {
  display: flex;
  gap: 8px;
}
.logoutContainer {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
@media only screen and (max-width: 576px) {
  .content {
    margin: 20px 12px 20px 12px;
    max-width: 500px;
    min-width: 0;
    overflow: scroll;
  }
  .contentHeaderActions {
    margin-top: 0;
  }
  .linksContainer {
    display: flex;
    /* flex-direction: column; */
    gap: 8px;
  }
  .contentHeaderActions {
    align-items: flex-end;
    justify-content: flex-start;
    column-gap: 12px;
  }
  .logoutContainer {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 8px;
  }
}
