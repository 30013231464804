.protocolInfo {
  display: flex;
  align-items: center;
  gap: 10px;
  background: 0;
  padding: 0;
}
.protocolNameWrapper {
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
}
.protocolName {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  color: var(--text-1);
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.rightPane {
  color: var(--text-1);
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: normal;
}
.protocolIconWrapper {
  position: relative;
  display: flex;
}
.protocolIcon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.chainIcon {
  position: absolute;
  height: 14px;
  width: 14px;
  right: -4px;
  top: -3px;
  border: 1px solid #fff;
  border-radius: 50%;
}
