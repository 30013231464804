.follow_form {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  min-width: 300px;
  /* Note: backdrop-filter has minimal browser support */
  padding: 20px;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.subtitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.info_icon {
  width: 16px;
  height: 16px;
  margin: 2px 8px;
  color: var(--text-2);
  cursor: pointer;
}

.info_container {
  border-radius: 6px;
  min-width: 300px;
  padding: 16px;
}

.info_text {
  font-size: 12px;
  color: var(--text-1);
  padding: 8px 16px;
  font-family: "Roboto";
}

.form_group {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
}

.error_group {
  display: flex;
  flex-direction: column;
}

.error {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;
  /* or 16px */

  letter-spacing: normal;

  /* Text II */
  margin: 0;
  padding: 0;

  color: var(--error);
}

.twitter_information {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;
  /* or 16px */

  letter-spacing: normal;

  /* Text II */
  margin: 0;
  padding: 0;

  color: var(--text-2);
}

.img {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  margin-right: 4px;
}

.input_image_adornment {
  display: flex;
  justify-content: center;
  align-items: center;
}

.done {
  background: var(--primary-color);
  border-radius: 4px;
  width: 100%;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  text-align: center;
  letter-spacing: normal;

  /* Text White */

  color: #ffffff;

  height: 36px;
}

.disabled {
  opacity: 0.6;
}

.new_bundle_button {
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 13px;
  color: var(--primary-color);
  background-color: var(--base);
  border: 1px solid var(--border-dark);
}

.bundle_checkbox {
  border-radius: 4px;
  font-family: "Roboto";
  font-size: 13px;
  padding: 4px 8px;
  background-color: var(--base);
  border: 1px solid var(--border-dark);
}
