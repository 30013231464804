.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 4px;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 5px 5px 7px 16px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.container::-webkit-scrollbar {
  display: none;
}

.filter_tag {
  cursor: pointer;
  display: flex;
  padding: 4px 8px;
  border-radius: 20px;
  align-items: center;
  display: flex; /* Added */
  justify-content: center; /* Added */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  background: var(--elevation-3);
  letter-spacing: normal;
  color: var(--text-2);
}

.selected_filter_tag {
  background: var(--primary-color10);
  font-size: 11px;
  color: var(--primary-color);
}
