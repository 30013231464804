.nameAndTwitterBox {
  background: var(--elevation-1);
  border-bottom: 1px solid var(--border-dark);
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  width: 100%;
}

.nameBox {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: 100%;
}
.twitterBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.txnListBox {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.form_group {
  flex: 1;
  width: 100%;
}

.form_group > * {
  width: 100%;
}

.submitButton {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0px 16px;
  min-width: 80px;
  background: #0648d7;
  justify-content: center;
  border-radius: 0px 8px 8px 0px;
  height: 58.88px;
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
}

.endInput {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.profile_image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-top: 2px;
  margin-right: 2px;
  z-index: 1;
}

.verticalDivider {
  width: 1px;
  height: 14px;
  margin-top: 4px;
  background: rgba(104, 118, 132, 0.1);
}

.cursorPointer {
  cursor: pointer;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 32px;
  height: 100%;
}

.twitterBio {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* or 20px */

  letter-spacing: 0.01em;

  /* Text II */

  color: var(--text-1);

  margin-top: 6px;

  word-break: break-word;
}
.twitterBio a {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* or 20px */

  letter-spacing: 0.01em;

  /* Text II */

  color: var(--primary-color);
  cursor: pointer;
}

.twitterBio a:hover {
  text-decoration: underline;
}
