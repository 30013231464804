.headerContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: column;
  background: var(--base);
  border-bottom: 1px solid var(--elevation-2);
  padding: 24px 20px 0px 20px;
}

.crossIcon {
  margin-right: 8px;
  margin-bottom: 16px;
  cursor: pointer;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  padding-bottom: 20px;
  letter-spacing: normal;
  color: var(--text-1);
}

.submitButton {
  font-size: 15px;
  font-weight: 600;
  border-radius: 0;
  width: 100%;
  height: 56px;
  color: var(--text-white);
  background: var(--primary-color);
  margin-top: auto;
}
.submitButton.disabled {
  opacity: 0.6;
  cursor: auto;
}
