.yesValue, .noValue, .otherValue {
    background-color: rgba(7, 157, 57, 0.1);
    color: var(--success);
    padding: 4px;
    font-size: 11px;
    font-weight: 500;
    display: inline-block;
    border-radius: 4px;
}

.noValue {
    background-color: rgba(226, 76, 76, 0.1);
    color: var(--error);

}

.otherValue {
    background-color: var(--primary-color10);
    color: var(--primary-color);
}

.textRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.textLeft {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.innerRow {
    border-bottom: 1px solid var(--border-dark);
    padding: 8px;
}

.innerRow:last-child {
    border-bottom: none;
}

.table {
    background-color: var(--elevation-1);
    border-radius: 8px;
    padding: 16px;
}