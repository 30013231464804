.community {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  padding-top: 24px;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  /* or 23px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.sub_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
  margin-top: 4px;
}

.tabs {
  margin-top: 16px;
  padding-bottom: 4px;
  border-bottom: 1px solid var(--border-dark);

  position: -webkit-sticky;
  position: sticky;
  top: 48px;
  padding-top: 12px;
  backdrop-filter: blur(16px);
  z-index: 3;
  display: flex;
  flex-direction: row;
}

.tab {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);

  margin-right: 20px;
  padding-bottom: 4px;
}

.active {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);

  border-bottom: 3px solid var(--primary-color);
}

.tabs_container {
  margin-top: 16px;
}

.follow_all {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  text-align: right;
  letter-spacing: normal;

  /* Primary */

  color: var(--primary-color);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.following_all {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  text-align: right;
  letter-spacing: normal;

  /* Primary */

  color: var(--text-2);
  display: flex;
  align-items: center;
}

.tabs_header_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-dark);
  cursor: pointer;

  position: sticky;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 5;
}

.glass {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.popup_content {
  background-color: var(--navBg);
  padding: 20px;
  border: 1px solid var(--border-dark);
  border-radius: 8px;
  width: 560px;
  grid-gap: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 24px rgba(104, 118, 132, 0.06);
  backdrop-filter: blur(12px);
}

.popup_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  /* or 27px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.popup_note {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* or 22px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.popup_subnote {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
  margin-top: 8px;

  display: flex;
  align-items: center;
}

.got_it_button {
  background: #0648d7;
  border-radius: 40px;
  width: 124px;
  height: 36px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  text-align: center;

  /* Text White */

  color: var(--text-white);

  margin-top: 16px;
}

.circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 1px solid var(--success);
  margin-right: 8px;
}

@media (max-width: 768px) {
  .community {
    width: 100%;
  }
}
