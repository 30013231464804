.container {
  width: 800px;
  margin: auto;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 27px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);

  padding-top: 20px;
}

.information {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);

  margin-top: 4px;

  align-self: flex-start;
}

.commands {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--primary-color);

  margin-top: 4px;

  align-self: flex-end;
  cursor: pointer;
  display: flex;
  font-weight: 600;
}

.screenerLeftContainer {
  width: 800px;
}

.informationParent {
  display: flex;
  justify-content: space-between;
}
