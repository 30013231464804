.header {
  position: fixed;
  right: 0;
  width: calc(100vw - 250px);
  z-index: 6;
  backdrop-filter: blur(var(--backdrop-filter));

  @media (max-width: 1400px) {
    width: calc(100vw - 70px);
  }
  @media (max-width: 768px) {
    width: 100vw;
  }
}
.biggerSearch {
  width: calc(100vw - 70px);
}

.searchContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 52px;
  gap: 16px;
  z-index: 6;
  width: 100%;

  @media (max-width: 1400px) {
    margin-left: 16px;
  }
  @media (max-width: 768px) {
    margin-left: 0px;
  }
}

.ribbon_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding-right: 20px;
  background: var(--warning);
  color: var(--text-white, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 18px */
  letter-spacing: 0.96px;
  text-transform: uppercase;
}

.backAndHeaderContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 52px;
  gap: 16px;
  border-bottom: 1px solid var(--border-dark);
  padding-right: 16px;
  width: 100%;
  z-index: 6;
}

.backButton {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 52px;
  z-index: 7;
  cursor: pointer;
  padding-left: 12px;
}
.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  letter-spacing: normal;
  color: var(--text-1);
  cursor: pointer;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.normalCursor {
  cursor: auto;
}
.arrow_back_icon {
  cursor: pointer;
  color: var(--text-1);
  margin-right: 16px;
}

.right_bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  width: 300px;
  min-width: 300px;
}

.theme {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  cursor: pointer;
}
.theme svg {
  color: var(--text-1);
}

.connect_wallet {
  background: var(--primary-color);
  border-radius: 4px;
  width: 100%;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: normal;

  /* Text White */

  color: var(--text-white);

  height: 28px;
}
