.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--base);
  border: 1px solid var(--border-dark);
  border-radius: 8px;
  overflow: auto;
}

.noDataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 12px;
  color:var(--text-2)
}

.postContainer {
  border-bottom: 1px solid var(--border-dark);
  padding: 15px;
  margin-bottom: 10px;
  position: relative;
}

.imagesContainer {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.imagePreview {
  width: 32px;
  height: 32px;
  border-radius: 4px;
}

.date {
  color: var(--text-2);
  font-size: 12px;
  display: flex;
  gap: 4px;
  align-items: center;
}
.content {
  color: var(--text-1);
}

.actions {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  gap: 4px;
}

.actions button {
  background-color: transparent;
  border: none;
}
.truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-all;
}
