.container {
  width: 100%;
  background: var(--base);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.feed-container {
  min-width: 340px !important;
  height: 100%;
  margin-bottom: 0;
}
.feed-container .bio {
  height: 60px;
}
.headerRow {
  display: flex;
}

.feedHeaderRow {
  /* flex-direction: column; */
}

.ghost_container {
  width: 100%;
  padding: 16px 20px;
  border: 1px solid var(--border-dark);
  background-color: var(--base);
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}

.profile_item {
  display: flex;
  flex-direction: row;
}

.profile_image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-top: 2px;
  margin-right: 2px;
  z-index: 1;
}

.profile_details {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.feed-container .follow_button {
  min-width: fit-content;
}

.follow_button {
  padding-top: 2px;
  min-width: 108px;
  align-items: flex-start;
  justify-content: flex-end;
  display: flex;
}

.profile_details_top {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.feed-container .profile_details_top {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;
}

.profile_name {
  display: flex;
  align-items: center;
  grid-gap: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}
.profile_name:hover {
  text-decoration: underline;
}

.net_worth {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
  margin-left: 8px;
}

.image_container {
  position: relative;
  height: 42px;
  width: 44px;
  margin-right: 16px;
  display: flex;
  align-items: center;
}
.feed-container .image_container {
  margin-right: 8px;
}
.feed-container .net_worth {
  margin-left: 0;
}

.social_icon {
  margin-left: 2px;
  position: absolute;
  top: 0;
  right: 0;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid var(--base);
  z-index: 2;
}

.twitter_icon {
  margin-left: 2px;
  position: absolute;
  top: 0;
  right: 0;
  background: #1da1f2;
  border-radius: 50%;
  display: flex;
  height: 17px;
  width: 17px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--base);
  z-index: 2;
}

.emojiIcon {
  position: absolute;
  bottom: -7px;
  right: 0;
  width: 26px;
  padding: 2px;
  height: 26px;
  z-index: 2;
  border: 2px solid var(--base);
  border-radius: 50%;
  background: #e1e5e8;
  display: flex;
  justify-content: center;
  margin-left: -3px;
  align-items: center;
}

.lensIconContainer,
.chainIconContainer {
  margin-left: 2px;
  position: absolute;
  top: 0;
  right: 0;
  background: var(--text-3);
  border-radius: 50%;
  display: flex;
  height: 17px;
  width: 17px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--base);
  z-index: 2;
  backdrop-filter: blur(8px);
  fill: "white";
}
.chainIconContainer {
  background: none;
}
.search_chain_icon {
  height: 100%;
  width: 100%;
}

.lensIcon {
  height: 11px;
  width: 11px;
}
