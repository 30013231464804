.messaging.str-chat .str-chat__thread {
  margin: 0;
  background: var(--base);
}

.custom-thread-header {
  border: none;
  background: var(--elevation-1);
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
}

.custom-thread-header__left {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.custom-thread-header__left-title {
  font-family: "Roboto";
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color: var(--text-1);
  margin-right: 10px;
}

.custom-thread-header__left-count {
  font-family: "Roboto";
  font-size: 14px;
  mix-blend-mode: normal;
  opacity: 0.5;
}

.messaging.str-chat .str-chat__thread {
  border-left: 1px solid var(--border-dark);
  border-radius: 0px 10px 0 0;
}

.str-chat__thread .str-chat__messaging-input {
  background: none !important;
}

.messaging.str-chat .str-chat__thread-list {
  height: unset;
  padding: 0;
  padding-top: 20px;
}

.messaging.str-chat .str-chat__thread-list .str-chat__message {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 0px;
}

.str-chat__thread-list .str-chat__list.str-chat__list--thread {
  padding: 0 !important;
}

.str-chat__thread-list .str-chat__li.str-chat__li--top {
  margin-top: 0;
}

.str-chat__thread-list .str-chat__message:first-of-type {
  border-bottom: 1px solid var(--border-dark);
  padding-bottom: 20px;
}

.str-chat__thread-list li .str-chat__message:first-of-type {
  border-bottom: none !important;
  padding-bottom: 0px;
}

.str-chat__thread .str-chat__messaging-input {
  margin-top: 0px !important;
}

.str-chat__message-replies-count-button:focus {
  border: none;
  outline: none;
}

.str-chat__thread-start {
  display: none;
}

.messaging.str-chat .str-chat__thread-list .str-chat__reverse-infinite-scroll {
  padding-top: 20px;
}

.str-chat__thread .str-chat__messaging-input {
  margin-top: 10px;
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.04);
}

.str-chat__thread .str-chat__input--emojipicker {
  top: 0%;
}

.str-chat__thread .emoji-mart {
  width: 300px !important;
}

@media screen and (max-width: 640px) {
  .str-chat__thread
    .str-chat__messaging-input
    .messaging-input__button.emoji-button {
    display: none;
  }
}
