.value-type {
  display: flex;
  align-items: center;
  &__trimmed-number {
    width: 105px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__link {
    padding: 0;
    background: none;
    color: #0648d7;
    font-style: italic;
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;
  }
  &__object {
    width: 105px;
    padding: 0;
    font-family: monospace;
    font-size: 13px;
    font-weight: 700;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__hash {
    width: 105px;
    padding: 0;
    font-family: monospace;
    font-size: 13px;
    color: #0648d7;
    font-weight: 700;
  }
  &__muted {
    background: none;
    color: #b6bdc4;
  }
  &__text {
    color: #141619 !important;
  }
}
