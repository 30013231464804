.audioPlayer {
  display: flex;
  width: 100%;
}

.artwork {
  width: 120px;
  height: 120px;
  margin-right: 20px;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: var(--elevation-2);
}

.nftInfo {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.audioControls {
  flex-shrink: 0;
  gap: 8px;
  display: flex;
}

.audioControlsTop {
  margin-top: 8px;
  display: flex;
  flex-grow: 1;
}

.audioControlsBottom {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.audioSeekBar {
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  height: 16px;
  border-radius: 4px;
  margin: 0 8px;
  display: flex;
  align-items: center;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 7px;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 4px;
    background-color: var(--primary-color10);
  }

  &:hover:after {
    background-color: var(--light-primary-color);
  }
}

.audioSeekBarTick {
  background-color: var(--primary-color);
  height: 2px;
  border-radius: 4px;
}

.title {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 17px;
  color: var(--text-1);
}

.subtitle {
  font-size: 12px;
  color: var(--text-2);
}
