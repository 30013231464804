.container {
  margin-top: 16px;
}
.accordion {
  margin: 5px 0;
  border: 1px solid var(--base);
}
.arguments {
  color: var(--text-1);
  font-family: "Roboto";
  font-size: 13px;
  line-height: 150%;
  letter-spacing: normal;
}
.input {
  border: 1px solid var(--border-dark);
  background: transparent;
  width: 100%;
  color: var(--text-1);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: normal;
  padding: 4px 16px;
}
.submitButton {
  background: var(--primary-color);
  padding: 4px 12px;
  border-radius: 2px;
  width: fit-content;
  color: var(--text-1);
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: normal;
  height: 29px;
  width: 60px;
}
.error {
  color: var(--error);
}
.responseTitle {
  font-family: "Roboto";
  line-height: 150%;
  letter-spacing: normal;
  font-size: 13px;
  font-weight: 500;
  color: var(--text-2);
}
.responseContent {
  display: flex;
}
.successIcon {
  color: var(--success);
}
.errorMessage {
  color: var(--error);
}
/* .accordionHeader {
  padding: 8px;
  font-size: 12px;s
.accordionBody {
  position: relative;
  z-index: 1;
} */

.popup_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  /* or 27px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.popup_note {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* or 22px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.popup_subnote {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
  margin-top: 8px;

  display: flex;
  align-items: center;
}

.add_to_wallet_button {
  background: #0648d7;
  border-radius: 40px;
  width: 156px;
  height: 36px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  text-align: center;

  /* Text White */

  color: var(--text-white);

  margin-top: 16px;
}
