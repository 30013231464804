.searchInputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 18px;
  height: 32px;
  background: var(--background);
  border-radius: 40px;
  border: 1px solid transparent;
  gap: 8px;
}
.searchInputContainer.focus {
  border-color: var(--primary-color);
}
.searchInputContainer .searchInput {
  display: flex;
  position: relative;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
  height: 100%;
}
.searchInputContainer .searchInput input {
  border: none;
  background: transparent;
  width: 100%;
  color: var(--text-1);
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0px;
  padding: 0px;
  height: 100%;
}
.searchInputContainer .searchInput input,
.searchInputContainer .searchInput input::placeholder {
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: normal;
}
.searchInputContainer .searchInput input::placeholder {
  color: var(--text-3);
}
.searchInputContainer .searchInput input:focus {
  outline: none;
  color: var(--text-1);
}
.searchInputContainer .searchOptions {
  display: flex;
  align-items: center;
  gap: 17px;
}
.searchInputContainer .searchOptions .shortcutKey {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 13px;
  line-height: 130%;
  text-align: right;
  letter-spacing: normal;
  color: var(--text-3);
}
.searchInputContainer .searchOptions .advancedFiltersButton {
  padding: 0;
  background: transparent;
}
.searchInputContainer .searchOptions .advancedFiltersButton .filtersIcon {
  height: 12px;
  width: 12px;
  display: flex;
}

.clearIcon {
  cursor: pointer;
}
