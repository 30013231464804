.container {
  padding-top: 12px;
}
.input {
  flex: 1;
  height: 52px;
  width: 100%;
  padding: 12px 16px;
  border: 1px solid transparent;
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #1b1f22;
  background-color: var(--elevation-1);
  transition: all 0.2s ease;
}

.input:focus {
  border: 1px solid var(--primary-color);
  background-color: #fff;
  box-shadow: 0px 4px 8px 0px rgba(6, 72, 215, 0.12);
}
