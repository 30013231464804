.app_layout {
  height: 100vh;
  display: flex;
  flex-direction: row;
  width: 100vw;
}
.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100vw;
}

.bgGradient {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  left: 0;
  background-color: var(--gradient-base-color-1);
}

.bgGradient2 {
  position: fixed;
  height: 100vh;
  width: calc(100vw - 250px);
  z-index: 3;
  left: 250px;
  background: rgba(0, 0, 0, 0.25);

  @media (max-width: 1400px) {
    left: 70px;
    width: calc(100vw - 70px);
  }

  @media (max-width: 768px) {
    left: 0px;
    width: 100vw;
  }
}

.flexBox {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 1400px) {
    margin-left: 86px;
  }
  @media (max-width: 768px) {
    margin-left: 0px;
  }
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 768px) {
  .main {
    padding-left: 0px;
  }
}

.feedContainer {
  max-width: 800px;
  margin-top: 16px !important;
  padding-top: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  min-height: calc(100vh - 48px) !important;
  padding: 0 !important;
}
@media screen and (max-width: 768px) {
  .feedContainer {
    padding-top: 60px;
  }
}
.noPadding {
  padding: 0 !important;
}
.borderContainer {
  border-radius: 8px;
}
.autoHeight {
  min-height: auto !important;
}
