.overlayComponent {
  position: absolute;
  width: 100%;
  height: 150px;
}

.img1 {
  position: absolute;
  top: 22px;
  left: 38px;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background: var(--base);
}

.img2 {
  position: absolute;
  top: 104px;
  left: 112px;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background: var(--base);
}

.img3 {
  position: absolute;
  top: 22px;
  left: 185px;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background: var(--base);
}
