.walletTile {
  color: var(--text-1);
  font-family: "Roboto";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 19.5px */
  letter-spacing: normal;
  padding: 4px 10px;
  width: 100%;
  cursor: pointer;
}

.walletTile:hover {
  background: var(--elevation-2);
}

.walletBox {
  width: 120px;
  padding: 6px 0px;
}
