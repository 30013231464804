.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  gap: 16px;
}

.heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;

  letter-spacing: normal;
  color: var(--text-1);
}

.walletCountText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  /* identical to box height, or 26px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
  padding-top: 24px;
}

.basicInfoColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
}

.walletsHeaderRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.walletDeleteRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 24px;
}

.verifyWalletText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  padding-bottom: 2px;
  /* identical to box height, or 20px */

  text-align: right;
  letter-spacing: normal;
  text-transform: none;

  /* Primary */

  color: var(--primary-color);
}
