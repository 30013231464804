.walletsBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 0px 20px;
  font-family: Roboto;
}

.table {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  background: var(--base);
  border: 1px solid var(--border-dark);
  border-radius: 6px;
  font-family: Roboto;
}

.tableHeader {
  display: flex;
  width: 100%;
  font-size: 11px;
  font-weight: 500;
  padding: 8px 20px 8px 12px;
  color: var(--text-2);
  padding-bottom: 12px;
  border-bottom: 1px solid var(--border-dark);
}

.tableRow {
  display: flex;
  padding: 2px 20px 2px 12px;
  cursor: pointer;
}

.checkboxCol {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addressCol {
  width: 50%;
}
.addressText {
  font-size: 13px;
  color: var(--text-2);
}
.walletNameCol {
  width: calc(30% - 30px);
}
.networthCol {
  width: 20%;
  text-align: right;
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: var(--primary-color);
  border-radius: 24px;
  padding: 4px 12px 4px 12px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  text-align: center;
  color: white;

  &:disabled {
    background: var(--elevation-2);
    color: var(--text-3);
    cursor: default;
  }
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--text-2);
}

.shareMessage {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  padding: 16px 20px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: var(--elevation-2);
  color: var(--text-2);
}

.pendingMessage {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  padding: 16px 20px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: var(--base);
  color: var(--warning);
}

.secondaryButton {
  background: var(--error);
}
