.container {
  padding: 8px;
  cursor: pointer;
}
.checkbox {
  padding: 0 !important;
}

.checkbox.Mui-checked + .MuiFormControlLabel-label {
  color: var(--text-1) !important;
}

.checkbox .MuiSvgIcon-root {
  font-size: 18px;
  padding: 0;
}

.checkbox .Mui-checked {
  background-color: var(--elevation-2);
  color: var(--primary-color);
}

.checkedLabel {
  background-color: var(--primary-color10);
  color: var(--primary-color) !important;
  font-weight: 500 !important;
}

.MuiCheckbox-root {
  color: white;
  padding: 0;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: var(--primary-color);
  padding: 0;
  transition: all 0.3s ease;
}
.checked {
  color: var(--primary-color) !important;
}
