/* src/components/V2/Onboarding/DownloadApp/DownloadApp.module.css */
.mainContent {
  width: 100%;
  text-align: center;
}

.contentWrapper {
  min-height: 320px;
  border-radius: 12px;
  position: relative;
}
.profileImageContainer {
  position: relative;
}
.profileImage {
  position: absolute;
  width: 76px;
  height: 76px;
  top: 82px;
  left: 72px;
  border-radius: 80px;
}

.profileDetails {
  width: 260px;
  position: absolute;
  top: 172px;
  left: 72px;
}

.aliases {
  margin-bottom: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
  flex-wrap: wrap;
  max-width: 85%;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.dot,
.dotLight {
  display: inline-block;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background-color: var(--text-2);
  margin: 0 8px;
}

.dotLight {
  background-color: var(--text-3);
}

.continueButton {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-color);
  color: #fff;
  border-radius: 12px;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
  border: none;
  margin-top: 15px;
  column-gap: 10px;
  border-radius: 12px !important;
  height: 58px !important;
}

.continueButton[disabled] {
  opacity: 0.3;
}

.header {
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: left;
}

.headerCont {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.stepTitle {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 28px;
  color: #68768499;
  margin: 0;
  margin-bottom: 13px;
}

.mainTitle {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 28px;
  color: #1b1f22;
  margin: 0;
}

.qrCodeCont {
  background: #ffffff;
  border-radius: 12px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.qrCodeSection {
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.steps {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.stepContent {
  margin-left: 5px;
  font-size: 13px;
  font-weight: 400;
}

.infoBanner {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 8px;
  border-radius: 4px;
}

.stepsTitle {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #1b1f22;
  text-align: left;
}

.step {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.availabilitySection {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
}

.availabilityText {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #1b1f22;
  margin: 0 10px;
  width: 362px;
}

.storeIcons {
  display: flex;
  justify-content: center;
  gap: 20px;
  cursor: pointer;
  margin-bottom: 10px;
}

.doneButton {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0648d7;
  color: #fff;
  border-radius: 12px;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
  border: none;
  margin-top: 15px;
  border-radius: 12px !important;
  height: 48px !important;
}

.loadingSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.loadingText {
  margin-left: 10px;
  font-size: 16px;
  color: var(--text-2);
}

@media only screen and (max-width: 576px) {
  .qrCodeSection {
    width: 100%;
    flex-direction: column;
  }
  .steps {
    margin-left: 0px;
  }
}

.stepNumber {
  background-color: #000;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gifWrapper {
  position: fixed;
  top: 16px;
  right: 16px;
}

.gifImage {
  width: auto;
  max-height: 90vh;
}
