@import "zxStyles/functions.scss";

.container {
  background-color: cv(base);
  margin-bottom: 8px;
  position: relative;
  overflow-x: visible;
}

.actorIcon {
  border-radius: 8px !important;
}

.background {
  position: absolute;
  height: 90px;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
}

.header {
  padding: 16px 16px 0 16px;
  z-index: 2;
  background-size: 90px;
  position: relative;
}

.content {
  position: relative;
  z-index: 2;
  padding: 0 16px 16px 16px;
}

.grid {
  width: 100%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 8px;
  row-gap: 8px;
}

.item,
.extraItem {
  border-radius: 12px;
  border: 1px solid var(--border-dark);
  padding: 12px;
  width: 100%;
}

.extraItem {
  width: 100%;
  margin-top: 4px;
}

.row {
  display: grid;
  grid-template-columns: 336px 1fr;
  column-gap: 12px;
  width: 100%;
}

.graphContainer {
  border: 1px solid cv(border-dark);
  border-radius: 8px;
  padding: 8px 8px 0 8px;
}

.transferContainer {
  padding: 8px;
  border-radius: 8px;
}

.transferProfileImage {
  border-radius: 20px;
  width: 20px;
  height: 20px;
}

.profit_dark {
  background: linear-gradient(
    90deg,
    rgba(7, 157, 57, 0.084) 0%,
    rgba(7, 157, 57, 0.28) 100%
  );
}

.profit_light {
  background: linear-gradient(
    90deg,
    rgba(7, 157, 57, 0.084) 0%,
    rgba(7, 157, 57, 0.28) 100%
  );
}

.loss_dark {
  background: linear-gradient(
    90deg,
    rgba(226, 76, 76, 0.108) 0%,
    rgba(226, 76, 76, 0.36) 100%
  );
}

.loss_light {
  background: linear-gradient(
    90deg,
    rgba(226, 76, 76, 0.084) 0%,
    rgba(226, 76, 76, 0.28) 100%
  );
}
