.currentEngagementWrapper {
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: center;
}

.smallWrapper {
  width: fit-content;
}

.leftSection {
  display: flex;
  flex-direction: row;
}

.rightSection {
  display: flex;
  flex-shrink: 0;
  flex-wrap: nowrap;
  color: var(--text-2);
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: normal;
}

.rightSectionItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.divider {
  margin-inline: 8px;
}

.generatedCommentsRow {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  flex-wrap: nowrap;
  gap: 8px;
  overflow-x: auto;
  flex-grow: 1;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}
