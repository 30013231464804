.container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.searchContainer {
  width: 320;
  display: flex;
  flex-direction: row;
}

.title {
  color: var(--text-2);
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 19.5px */
  letter-spacing: normal;
}

.avatar {
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.name {
  color: var(--text-1);
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: normal;
}

.impersonate {
  color: var(--primary-color);
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 19.5px */
  letter-spacing: normal;
}
.selectWallet {
  color: var(--text-3);
  font-family: "Roboto";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 19.5px */
  letter-spacing: normal;
}

.crossPointer {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px;
}

.verticalDivider {
  background: var(--border-dark);
  width: 1px;
  height: 14px;
  margin-right: 10px;
}

.selectWalletPointer {
  cursor: pointer;
}
