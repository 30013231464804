.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: var(--base);
  padding: 32px;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.secondaryButton {
  background: none;
  border: none;
  color: #0648d7;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

.secondaryButton:hover {
  text-decoration: underline;
}

.pillsContainer {
  display: flex;
  justify-content: center;
  gap: 12px;
  flex-wrap: wrap;
  font-size: 14px;
  color: #687684;
}

.pill {
  display: flex;
  align-items: center;
  background: var(--elevation-2);
  border-radius: 24px;
  padding: 2px 10px;
  gap: 5px;
}

.pillText {
  color: var(--text-2);
  text-transform: capitalize;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 24px;
  border-radius: 50%;
}

.icon {
  width: 16px;
  height: 16px;
}
