.trending_query_card {
  padding-top: 18px;
  border-top: 1px solid var(--border-dark);
  padding-bottom: 18px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile_image {
  height: 24px;
  width: 24px;
  border-radius: 50%;
}

.profile {
  display: flex;
  flex-direction: row;
}

.profile_name {
  color: var(--text-1);
  font-size: 15px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: normal;
  margin-left: 10px;
}

.body {
  margin-top: 8px;
}

.headline {
  color: var(--text-1);
  font-size: 15px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: normal;
}
