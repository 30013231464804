.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 100%;
}

.divider {
  height: 1px;
  width: 100%;
  background: var(--border-dark);
}

.squareRotate {
  background: var(--text-2);
  opacity: 0.16;
  transform: rotate(-45deg);
  width: 6px;
  height: 6px;
}

.square {
  width: 6px;
  height: 6px;
  background: var(--text-2);
  opacity: 0.16;
}

.circle {
  width: 6px;
  height: 6px;
  background: var(--text-2);
  opacity: 0.16;
  border-radius: 50%;
}
