.container {
}

.title {
  padding-bottom: 16px;
}

.list-container {
  margin-top: 16px;
}

.list-item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--elevation-2);
}
