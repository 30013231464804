.adornment {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  text-align: right;
  letter-spacing: normal;
}

.available {
  color: var(--success);
  display: flex;
  align-items: center;
}

.not_available {
  height: 20px;
  width: 20px;
  color: var(--error);
  align-items: center;
}

.alignment {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.circle_check {
  margin-left: 4px;
  stroke-width: 1px;
  font-weight: 300;
  height: 20px;
  width: 20px;
}
