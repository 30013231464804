.user_avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.avatarWrapper {
  position: relative;
}
.avatar {
  margin-top: 4px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.avatarBadge {
  position: absolute;
  right: -4px;
  top: 2px;
  padding: 3px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: var(--text-3);
  backdrop-filter: blur(8px);
  border: 1px solid var(--base);
}

.avatarBadge svg {
  display: block;
  margin: auto;
  height: 100%;
  width: auto;
}

.info {
  margin-left: 16px;
}

.name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  letter-spacing: normal;
  color: var(--text-1);
  display: flex;
  align-items: center;
  gap: 4px;
}

.aliases {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.presence {
  position: absolute;
  top: 4px;
  right: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid var(--base);
  background-color: var(--green);
}

.lastActive {
  color: var(--text-2);
  font-size: 10px;
}
