.carousel-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel-items {
  display: flex;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease;
}

.carousel-item {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  outline: none;
}

.carousel-pagination {
  display: flex;
  gap: 12px;
  padding: 2px;
  flex-shrink: 0;
  box-sizing: border-box;
  transform-origin: center;
  margin-top: 16px;
}

.pagination-indicator {
  background-color: var(--text-3);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  padding: 2px;
  flex-shrink: 0;
  box-sizing: border-box;
  transform-origin: center;
}

.pagination-indicator.active {
  background-color: var(--text-1);
  transform: scale(2);
}

.carousel-wrapper.fade .carousel-items {
  position: relative;
}

.carousel-wrapper.fade .carousel-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.slide:hover .carousel-arrows {
  opacity: 1;
}

.carousel-wrapper.fade .carousel-item.active {
  position: relative;
  opacity: 1;
}

.image {
  height: 150px;
  width: 100%;
  object-fit: contain;
}

.carousel-arrows {
  position: absolute;
  background-color: var(--elevation-3);
  top: 40%;
  border-radius: 32px;
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  opacity: 0;
  transition: opacity 0.2s ease;
  cursor: pointer;
  z-index: 10;
}

.carousel-arrow:hover {
  background-color: var(--elevation-1);
}

.carousel-arrows-right {
  position: absolute;
  right: 0;
}
.carousel-arrows-left {
  position: absolute;
  left: 0;
}
.carousel-arrow {
  width: 32px;
  opacity: 0.8;
  transition: all 0.2s ease;
}
.carousel-arrow:hover {
  opacity: 1;
}
.carousel-arrow.left-arrow {
  left: 0;
}

.carousel-arrow.right-arrow {
  right: 0;
}
