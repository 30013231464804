.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px 0 20px;
}

.header .labelContainer {
  display: flex;
  align-items: baseline;
  gap: 8px;
  font-weight: 600;
  letter-spacing: normal;
}
.header .labelContainer .label {
  font-size: 15px;
  color: var(--text-1);
}

.header .labelContainer .totalUsdValue {
  font-size: 15px;
  color: var(--text-2);
}

.header .updatedAt {
  font-size: 13px;
  font-weight: 400;
  color: var(--text-3);
  background-color: transparent;
  font-family: "Roboto", sans-serif;
  letter-spacing: normal;
  display: flex;
  align-items: center;
  gap: 6px;
}
