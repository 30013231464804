.mainContent {
  margin-top: 16px;
  width: 100%;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  height: 100%;
}

.container {
}

.disabled {
  cursor: not-allowed !important;
}

.header {
  margin-bottom: 20px;
}

.mainTitle {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 28px;
  letter-spacing: normal;
  line-height: 36px;
  color: #1b1f22;
  margin-bottom: 4px;
}

.subtitle {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: normal;
  line-height: 160%;
  color: #687684;
}

.socialsContainer {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  flex-direction: column;
}

.socialButton {
  width: 160px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 12px;
  cursor: pointer;
  padding: 20px;
  position: relative;
  transition: transform 0.3s;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}

.socialButton:hover {
  transform: translateY(-5px);
}

.farcasterButton {
  background: #855dcd;
  color: #fff;
}

.twitterButton {
  background: rgba(27, 31, 34, 1);
  color: #fff;
}

.twitter_icon {
  margin-bottom: 5px;
}

.lensButton {
  background: #42673b;
  color: #fff;
}

.socialIconWrapper {
  margin-bottom: 10px;
}

.whyAddSocialsContainer {
  display: flex;
  align-items: flex-start;
  background: rgba(104, 118, 132, 0.08);
  border-radius: 12px;
  padding: 16px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.whyAddSocialsContainer img {
  margin-right: 8px;
}

.whyAddSocialsTitle {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 160%;
  color: #1b1f22;
  margin-bottom: 4px;
}

.continueButton {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0648d7;
  color: #fff;
  border-radius: 12px;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
  border: none;
  margin-top: 0;
}

.socialText {
  font-size: 15px;
  font-family: "Roboto";
  font-weight: 500;
  letter-spacing: -0.3;
  height: 0.11;
  color: white;
}

.lensLogo,
.farcasterLogo {
  width: 20px;
  height: 20px;
}

.row {
  border-radius: 12px;
  padding: 12px 16px;
  width: 100%;
  cursor: pointer;
}
.twitterRow {
  background-color: var(--text-1);
}

.lensRow {
  background-color: var(--lens-connect);
}
.connected {
  background-color: var(--elevation-2);
}
