.container {
  border-radius: 6px;
  padding: 16px;
  min-width: 300px;
}

.profilePic {
  border-radius: 50%;
  height: 48px;
  width: 48px;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  /* identical to box height, or 26px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.networth_bundle_row {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
  display: flex;
  justify-content: space-between;

  padding: 8px 0;
  border-bottom: 1px solid var(--border-dark);
  border-top: 1px solid var(--border-dark);
}

.bundleIcon {
  height: 16px;
  width: 22px;
  margin-top: 4px;
}

.cursorDiv {
  cursor: pointer;
}

.addressImage {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background: var(--elevation-2);
  display: flex;
  justify-content: center;
  background-image: url("assets/svg/address_image.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.wrapDiv {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
}

.networth {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}
