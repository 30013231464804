.button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  height: 32px;
  /* identical to box height, or 16px */

  text-align: center;

  /* Text White */

  color: var(--text-2);
  background-color: transparent;
}

.follow_button {
  /* border: 1px solid var(--text-2); */
  background-color: var(--elevation-2);
  border-radius: 8px;
  padding: 0 12px;
  min-width: 86px;
  transition: background-color 0.2s ease;
  white-space: nowrap;
}

.smallButton {
  min-width: 72px;
  padding: 4px 12px;
  border-radius: 36px;
  font-size: 14px;
  line-height: 22px;
}
.darkButton {
  background-color: rgba(255, 255, 255, 0.08) !important;
}
.follow_button_open {
  background-color: var(--primary-color);
  opacity: 0.6;
  border-radius: 20px;
}

.spinner_container {
  color: var(--text-white);
  width: 86px;
  height: 32px;
  background-color: var(--primary-color);
  opacity: 0.6;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.follow_edit_button {
  display: flex;
  flex-direction: row;
  grid-gap: 8px;
  height: 32px;
  align-items: center;
}

.edit_icon {
  font-size: 14px;
  color: var(--text-1);
  cursor: pointer;
}
.follow {
  border: 1px solid transparent;
  color: var(--text-white);
  background-color: var(--primary-color);
}

.follow_hovered {
  border: 1px solid transparent;
  color: var(--text-white);
  background-color: rgba(6, 72, 215, 0.8);
}

.unfollow {
  border: 1px solid var(--error);
  color: var(--error);
}

.fullWidth {
  width: 100% !important;
}

.feed_button {
  background-color: var(--primary-color);
  width: 20px;
  height: 20px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--base);
}
.feed_followed_button {
  background-color: var(--social-bg);
}

.feed_button {
  transition: all 0.2s ease;
}
