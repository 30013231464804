.container {
  background-color: var(--base);
  width: 540px;
  padding: 16px;
  border-radius: 8px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.popupOption {
  border: 1px solid transparent;
  cursor: pointer;
  padding: 12px 14px;
  border-radius: 8px;
  background-color: var(--elevation-2);
}

.popupOptionIcon {
  width: 20px;
  height: 20px;
}
