.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 145px;
  position: relative;
}

.extraImageType {
  height: auto;
}

.nftImage {
  background: var(--elevation-2);
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.imageWrapper:has(.fallbackImage) {
  background-color: var(--elevation-2);
  width: 100%;
  align-items: center;
  justify-content: center;
}

.fallbackImage {
  background: none;
  height: 30px;
  width: 30px;
  object-fit: unset;
}
.rightPane.imageWrapper {
  width: 64px;
  height: auto;
}
.rightPane.imageWrapper:has(.fallbackImage) {
  width: 64px;
}
.nftImage video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.playButton {
  position: absolute;
  height: 2rem;
  width: 2rem;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: var(--secondary-color);
  border-radius: 50%;
}
@media (max-width: 768px) {
  .imageWrapper {
    width: 64px;
    height: 64px;
  }
  .extraImageType {
    height: auto;
    width: auto;
  }
  .imageWrapper:has(.fallbackImage) {
    width: 64px;
  }
}
