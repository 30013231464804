.activityStats {
  display: flex;
  color: var(--text-2);
  align-items: center;
  gap: 8px;
  padding: 0;
  background: none;
  width: fit-content;
}
.stats {
  display: flex;
  align-items: center;
  line-height: 150%;
}
.activityStatsItem,
.expandActivities {
  padding: 0;
  background: none;
  color: var(--text-2);
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
}

.divider {
  margin: 0 4px;
}
.expandActivities {
  display: flex;
  align-items: center;
  gap: 8px;
}
.rightChevronIcon {
  display: flex;
  align-items: center;
}
.rightChevronIcon img {
  height: 7px;
}

@media (max-width: 768px) {
  .activityStats {
    flex-wrap: wrap;
  }
}
