.profile_graphs {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 8px;
}

.existing-data-chart-container {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.existing-data-chart-container .duration_panel {
  display: flex;
  justify-content: flex-end;
}

.right_panel {
  display: flex;
  align-items: center;
  height: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  width: 100%;
  border-bottom: 1px solid var(--border-dark);
}
.header.paddingTop {
  padding: 10px 20px;
}
.tab {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
  margin-right: 16px;
  cursor: pointer;
}

.tab_active {
  font-weight: 600;
  color: var(--text-1);
  cursor: default;
}

.duration {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 16px */

  text-align: center;
  letter-spacing: normal;

  /* Text III */

  color: var(--text-3);
  margin-left: 12px;
  cursor: pointer;
}

.duration_active {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 16px */

  text-align: center;
  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
  cursor: default;
  /* border-bottom: 1px solid var(--text-2); */
}

.live {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  line-height: 160%;
  color: var(--primary-color);
  top: 10px;
  left: 20px;
  width: 55px;
  height: 25px;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
}
