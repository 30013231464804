.modalWrapper {
  position: absolute;
  left:0;
  top:0;
  bottom:0;
  right:0;
  display:flex;
  justify-content: center;
  align-items: start;
}
.modalContainer {
  position: relative;
  margin-top: 10%;
  left: -40px;
  width: 700px;
  background-color: var(--base);
  border-radius: 6px;
}

.modalTitleWrapper {
  margin: 16px 20px 0;
  position: relative;
}
.close {
  display: flex;
  background: none;
  padding: 0;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 10;
}
.close svg {
  stroke: var(--text-1);
}
.title {
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: normal;
  color: var(--text-1);
}
.modalContentWrapper {
  margin: 16px 20px;
}

@media (max-width: 768px) {
  .modalContainer {
    width: 95vw;
    left: 50%;
  }
}
