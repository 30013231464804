.expandedProtocol {
  border: 1px solid var(--border-dark);
  background: var(--base);
  border-radius: 6px;
  padding: 12px 24px 0;
  color: var(--text-1);
}
.expandedProtocol:not(:last-child) {
  margin-bottom: 16px;
}
