.basicInfoRowTile {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.basicInfoTitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
  flex: 1;
  overflow: auto;
}
.basicInfoValue {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  color: var(--text-1);
  flex: 3;
  overflow: auto;
}
