.screener_query {
  display: flex;
  grid-gap: 32px;
}

.notEditMode {
  flex-direction: column;
}

.editMode {
  flex-direction: row;
}

.queryContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 320px;
  background: var(--base);
  border: 1px solid var(--border-dark);
  border-radius: 6px;
  padding: 20px;
}

.queryInputContainer {
  flex: 1;
  height: 100%;
}

.queryButtonContainer {
  display: flex;
  flex-direction: column;
}

.screener_text {
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 22.5px */
  letter-spacing: normal;
}

.screener_query_button {
  display: flex;
  min-width: 128px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 36px;
  color: var(--text-white);
  background-color: var(--primary-color);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: normal;
  border-radius: 18px;
}

.disabledButton {
  background-color: var(--primary-color60);
}

.button_wrapper {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.query_example_box {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.query_example {
  width: 800px;
  display: flex;
  flex-direction: row;
  grid-gap: 16px;
}

.query_example_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
  margin-bottom: 16px;
}

.query_example_text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 180%;
  /* or 27px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.error {
  color: var(--error);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: normal;
  margin: 10px 0;
}

.cancelText {
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  padding-left: 16px;

  color: var(--primary-color);
}

.addQueryBtn {
  display: flex;
  color: var(--Primary, var(--Primary, #0648d7));
  text-align: right;
  font-family: "Roboto";
  font-size: 13px;
  background: var(--base);
  font-style: normal;
  width: fit-content;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: normal;
}

.runQueryButton {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  font-size: 15px;
  width: 128px;
  height: 36px;
  background-color: var(--Primary, #0648d7);
  color: #fff;
  font-weight: 600;
  line-height: 150%;
  border-radius: 22px;
}
