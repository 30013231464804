.refetch {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  background-color: transparent;
}

.refresh {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  text-align: center;
  letter-spacing: normal;

  color: #0648d7;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.refresh_icon {
  height: 14px;
  width: 14px;
}

.header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  /* identical to box height, or 24px */

  text-align: center;
  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 130%;
  /* identical to box height, or 17px */

  text-align: center;
  letter-spacing: normal;

  margin-top: 10px;

  /* Text II */

  color: var(--text-2);
}
