.downloadButton {
  display: flex;
  background: none;
  padding: 0;
  color: var(--text-2);
}

.downloadButton:hover {
  color: var(--text-1);
}
