.rightPanel {
  width: 800px;
  font-family: "Roboto";
  background-color: var(--base);
}
.header {
  display: flex;
  flex-direction: column;
  background: var(--base);
  border-bottom: 1px solid var(--elevation-2);
  padding: 24px 20px 0px 20px;
}

.holdingsButton {
  gap: 4px;
}

.shimmer {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  padding-bottom: 24px;
  margin-top: 16px;
}

.shimmer_line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 20px;
}

.swapButton {
  width: 100%;
  border-radius: 24px;
  height: 36px;
  margin-top: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: var(--primary-color);
  border-radius: 24px;
  padding: 4px 12px 4px 12px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  text-align: center;
  color: var(--base);
}

.swapExternalLink {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: white;
  }
}

.allHoldings::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.sendButton {
  font-family: "Roboto";
  height: 32px;
  width: auto;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background-color: var(--elevation-2);
  color: var(--text-2);
  cursor: pointer;
  position: relative;
  font-size: 13px;
  font-weight: 600;
  padding: 6px 12px;
  color: var(--primary-color);
}

.tokenSelectorList {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-top: 8px;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
}

.tokenSelectorItem {
  background-color: var(--elevation-2);
  border-radius: 30px;
  padding: 4px 8px 4px 4px;
  cursor: pointer;
  flex-shrink: 0;
  max-width: 150px;
  flex-wrap: nowrap;
}

.selectedTokenSelectorItem {
  background-color: var(--primary-color10);
  font-weight: 500;
}

.tokenImageSmall {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
