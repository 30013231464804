.edit_form_heading {
  color: var(--text-1);
  font-size: 18px;
  font-family: Roboto;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: normal;

  margin-top: 10px;
}

.icon_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border-left: 1px solid var(--border-dark);
  padding-left: 8px;
}
