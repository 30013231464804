.container {
  display: flex;
  flex-direction: column;
}
.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dp {
  width: 40px;
  height: 40px;
  border-radius: 40px;
}

.bio {
  padding-top: 8px;
  min-height: 42px;
}

.dot {
  display: inline-block;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background-color: var(--text-3);
  margin: 0 8px;
}
