.avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.subtitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}
.newsContainer {
  padding: 12px 16px;
  border-radius: 10px;
  border: 1px solid var(--card-hover);
  background: rgba(6, 72, 215, 0.04);
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .detailsRow {
    flex-wrap: wrap-reverse;
  }
}
