.button {
  background-color: var(--elevation-2);
  border: none;
  outline: none;
  border: 1px solid var(--border-dark);
  color: var(--text-2);
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  padding: 4px 12px;
  border-radius: 30px;
  text-transform: none;
  display: flex;
  align-items: center;
}

.container {
  z-index: 2;
  cursor: pointer;
}

.iconWrapper {
  background-color: var(--elevation-2);
  display: flex;
  align-self: center;
  justify-content: center;
  padding: 6px;
  border-radius: 8px;
}
