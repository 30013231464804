.container {
  border-radius: 8px;
  padding: 8px 12px;
  animation: all 0.2s ease;
}

.profit_dark {
  background: linear-gradient(
    90deg,
    rgba(7, 157, 57, 0.084) 0%,
    rgba(7, 157, 57, 0.28) 100%
  );
}

.profit_light {
  background: linear-gradient(
    90deg,
    var(--success-lighter) 0%,
    var(--success-light) 100%
  );
}

.loss_dark {
  background: linear-gradient(
    90deg,
    rgba(226, 76, 76, 0.084) 0%,
    rgba(226, 76, 76, 0.28) 100%
  );
}

.loss_light {
  background: linear-gradient(
    90deg,
    var(--error-lighter) 0%,
    var(--error-light) 100%
  );
}
.toggle {
  cursor: pointer;
}

.expandable {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition:
    max-height 300ms ease-in-out,
    opacity 300ms ease-in-out;
}

.expandable.expanded {
  max-height: 100px; /* Adjust as needed */
  opacity: 1;
}
