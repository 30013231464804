.invite-node-container {
  position: relative;
  padding: 12px;
  padding-bottom: 0;
}
.invite-graph_avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.react-flow__node-default,
.react-flow__node-input,
.react-flow__node-output,
.react-flow__node-group {
  background-color: var(--base);
  border: 1px solid var(--border-light);
}

.invite-graph_add {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  cursor: pointer;

  &:hover {
    background-color: var(--elevation-1);
  }
}

.invite-graph_add-count {
  position: absolute;
  top: -8px;
  right: -8px;
  padding: 2px 4px;
  min-width: 18px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  background-color: var(--error);
}

.react-flow__panel.react-flow__attribution {
  display: none;
}

.x-followers-count {
  background-color: var(--elevation-1);
  padding: 4px 0;
}
