div.descriptionBox {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  /* font-size: 14px; */
  line-height: 22px;
  word-wrap: break-word;
  white-space: pre-wrap;
  /* or 157% */

  /* b */

  color: var(--text-1);
}

div.descriptionBox a {
  color: var(--primary-color);
  word-break: break-all;
}
div.descriptionBox li {
  white-space: initial;
}

/* Show See more / See less on the same line */
/* Commenting  this, as it converts ordered lists to inline element */
/* div.descriptionBox,
div.descriptionBox *:last-child {
  display: inline;
} */

.expand {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  /* or 157% */

  color: var(--text-2);
  cursor: pointer;
}

.itemsImage {
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.profileInitial {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
}

.profileSeries {
  width: 26px;
  height: 26px;
  border-radius: 50%;
}

.profileInitial img,
.profileSeries img {
  height: 26px;
  width: 26px;
  border-radius: 50%;
}
