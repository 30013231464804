.pools {
  display: flex;
  gap: 8px;
}
.pools.poolsOverflow {
  flex-direction: column;
}
.pools.poolsOverflow .poolSymbols {
  flex-wrap: wrap;
}
.poolIcons {
  display: flex;
  align-items: center;
}

.poolSymbols {
  display: flex;
}
.rightPane {
  color: var(--text-2);
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: normal;
}
.poolSymbol {
  flex-shrink: 0;
}

.poolSymbol:not(:last-child)::after {
  content: " + ";
  margin: 0 4px;
}
