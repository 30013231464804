.addressText {
  font-size: 13px;
  color: var(--text-1);
  font-family: "Roboto", sans-serif;
}

.walletRow {
  cursor: pointer;

  &:hover {
    background-color: var(--primary-color10);
  }
}

.selectedUserInput {
  border: 1px solid var(--border-dark);
  padding: 6px 8px;
  border-radius: 4px;
}
.selectedUserInputInner {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 0px;
}

.userAvatar {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 0.25rem;
}

.balanceText {
  cursor: pointer;
}

.balanceText:hover {
  text-decoration: underline;
}

.maxButton {
  cursor: pointer;
  color: var(--primary-color);
  font-size: 11px;
  font-weight: 600;
  background: transparent;
  padding: 0;
}

.chainIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.tokenIcon {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
}

.popup_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  /* or 27px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.popup_note {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* or 22px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.popup_subnote {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
  margin-top: 8px;

  display: flex;
  align-items: center;
}

.add_to_wallet_button {
  background: var(--primary-color);
  border-radius: 40px;
  width: 156px;
  height: 36px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  color: white;
  text-align: center;
  margin-top: 16px;
}

.sendButton {
  flex-shrink: 0;
  background: var(--primary-color);
  border-radius: 40px;
  width: calc(100% - 32px);
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  gap: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  text-align: center;
  color: white;
  margin: 8px 16px;

  &:disabled {
    opacity: 0.5;
  }
}

.filterPill {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  color: var(--text-1);
  background-color: var(--base);
  cursor: pointer;
  margin-bottom: 8px;

  &:hover {
    background-color: var(--primary-color10);
  }
}

.isActive {
  background-color: var(--primary-color10);
  font-weight: 500;
  color: var(--primary-color);
}

.sendFormWrapper {
  /* max-height: calc(100vh - 200px); */
  overflow-y: auto;
}
