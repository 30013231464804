.menu {
  box-shadow: 0px 2px 24px var(--elevation-2);
  color: var(--text-1);
}
.menu ul {
  padding: 0;
  border: 1px solid var(--border-dark);
  background: var(--base);
}
.menu li:hover {
  background: var(--elevation-1);
}
.menuItem {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 11px;
  line-height: 130%;
  letter-spacing: normal;
  color: var(--text-1);
}
