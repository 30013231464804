.modalContainer {
  position: absolute;
  top: 50%;
  left: calc(50% + 25px);
  transform: translate(-50%, -50%);
  width: 820px;
  height: 600px;
  background-color: var(--base);
  font-family: Roboto;
  border-radius: 6px;
}

.dropdownButton {
  cursor: pointer;
  color: var(--text-2) !important;
  z-index: 1;
  width: 60px;
}

.dropdownList {
  width: 66px !important;
}

.categories {
  padding: 0;
  margin: 0;
  display: flex;
  column-gap: 8px;
}
.category {
  height: 28px;
  display: flex;
  align-items: center;
}

:global(.Mui-disabled) {
  color: var(--primary-color40) !important;
}
.filterCheckbox {
  padding: 0 !important;
}

.category_value {
  color: var(--text-1);
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 400;
  line-height: 130%; /* 14.3px */
  letter-spacing: normalpx;
  margin-top: 4px;
}

.graphContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}
