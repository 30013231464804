.net_worth_card_token {
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  align-items: center;
}

.icon {
  height: 14px;
  width: 14px;
  margin-right: 8px;
}

.name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: normal;
  color: var(--text-2);
  text-align: center;
}
