.nftHoldings {
  font-family: Roboto;
}
.nftCollectionItem {
  padding: 8px 20px;
}
.nftCollectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  gap: 16px;
}
.info {
  display: flex;
  gap: 12px;
  align-items: center;
  flex-shrink: 0;
}

.nftAvatar {
  position: relative;
}
.avatar {
  height: 28px;
  width: 28px;
  border-radius: 50%;
}
.name {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: normal;
  color: var(--text-1);
}
.borderWrapper {
  border-bottom: 1px solid var(--border-dark);
  height: 1px;
  width: 100%;
  display: flex;
  align-items: center;
}
.stats {
  display: flex;
  align-items: center;
  color: var(--text-2);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: normal;
  flex-shrink: 0;
}

.categoryItems {
  display: grid;
  grid-template-columns: repeat(auto-fill, 174px);
  grid-auto-rows: minmax(0, 1fr);
  gap: 20px;
}

.nftItem {
  flex-shrink: 0;
  width: 174px;
  background: var(--base);
  border: 1px solid var(--border-light);
  border-radius: 4px;
  overflow: hidden;
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 145px;
  width: 174px;
}
.nftImage {
  background: var(--elevation-2);
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.infoWrapper {
  padding: 12px 16px;
}
.title {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: normal;
  color: var(--text-1);
  max-height: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.subTitle {
  font-size: 11px;
  font-weight: 400;
  letter-spacing: normal;
  color: var(--text-2);
}

.rightPane.nftHoldings {
  margin: 16px 20px;
}
.rightPane .categoryItems {
  display: flex;
  flex-direction: column;
}
.rightPane .imageWrapper {
  width: 64px;
  height: 64px;
}
.rightPane .imageWrapper:has(.fallbackImage) {
  width: 64px;
}

.rightPane .nftItem {
  display: flex;
  width: 100%;
  height: 56px;
}
.rightPane .title {
  width: 200px;
}
.rightPane .subTitle {
  width: 50px;
}
