.box {
  border: 1px solid var(--border-dark);
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 0px 6px 6px;
  height: 28px;
}

.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 130%;
  padding-left: 6px;
  /* identical to box height, or 17px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.crossIcon {
  width: 27.42px;
  height: 6.83px;
  cursor: pointer;
  padding-left: 10.59px;
  padding-right: 10px;
}

.chainIcon {
  height: 16px;
  width: auto;
  border-radius: 50%;
}

.editBox {
  cursor: pointer;
  border: 1px solid var(--primary-color);
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 8px 6px 8px;
  height: 28px;
}

.editText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 130%;
  /* identical to box height, or 17px */

  letter-spacing: normal;

  /* Primary */

  color: var(--primary-color);
}

.editIcon {
  padding-bottom: 2px;
  font-size: 16px;
  color: var(--primary-color);
}
