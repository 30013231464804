.rightPanel {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 37.5%;
  background: var(--elevation-1);
  border-radius: 10px;
  padding-bottom: 86px;
  position: fixed;
  overflow: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.header {
  display: flex;
  flex-direction: column;
  background: var(--base);
  border-bottom: 1px solid var(--elevation-2);
  padding: 24px 20px 24px 20px;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  padding-bottom: 20px;
  letter-spacing: normal;
  color: var(--text-1);
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: var(--text-2);
}

.sentMessage {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: var(--text-2);
}
