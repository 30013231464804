.pnlList {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow-y: auto;
  row-gap: 8px;
  padding: 2px 0 6px 0;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
}

.tokenContainer {
  padding: 8px;
  border-radius: 8px;
}

.pnlListMore {
  padding: 8px;
  background-color: var(--elevation-1);
  display: flex;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
}

.profit_dark {
  background: linear-gradient(
    90deg,
    rgba(7, 157, 57, 0.084) 0%,
    rgba(7, 157, 57, 0.28) 100%
  );
}

.profit_light {
  background: linear-gradient(
    90deg,
    rgba(7, 157, 57, 0.084) 0%,
    rgba(7, 157, 57, 0.28) 100%
  );
}

.loss_dark {
  background: linear-gradient(
    90deg,
    rgba(226, 76, 76, 0.084) 0%,
    rgba(226, 76, 76, 0.28) 100%
  );
}

.loss_light {
  background: linear-gradient(
    90deg,
    rgba(226, 76, 76, 0.084) 0%,
    rgba(226, 76, 76, 0.28) 100%
  );
}

.pnlListHeading {
  white-space: nowrap;
  margin-bottom: -9px;
}

.tokenImage {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.tokenImageSmall {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.pnlItem {
  padding: 8px;
  flex-shrink: 0;
  width: 200px;
  display: flex;
  align-items: center;
  gap: 8px;
  background: var(--elevation-2);
  border-radius: 8px;
  position: relative;
  cursor: pointer;
}
.fullWidth {
  width: 100%;
}
.pnlState {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  color: var(--text-2);
  background-color: var(--elevation-2);
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 11px;
  font-weight: 500;
}

.pnlStateUnrealizedOpen {
  color: var(--primary-color);
  background-color: var(--primary-color10);
}

.pnlStateUnrealizedPartial {
  color: var(--warning);
  background-color: var(--warning-light);
}

.pnlExpandedList {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &:has(div) {
    padding: 8px 0;
  }
}

.pnlListTokenSelector {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: nowrap;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
}

.tokenSelectorItem {
  background-color: var(--elevation-2);
  border-radius: 30px;
  padding: 4px 8px 4px 4px;
  cursor: pointer;
  flex-shrink: 0;
  max-width: 150px;
  flex-wrap: nowrap;
}

.selectedTokenSelectorItem {
  background-color: var(--primary-color10);
  font-weight: 500;
}

.transactionsButton {
  background: none;
  border: none;
  color: var(--primary-color);
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 500;
}
