.buttonContainer {
  height: 48px;
  padding: 0px 40px;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  /* identical to box height, or 24px */

  /* Text White */

  color: #ffffff;
}

.follow_all {
  margin-left: 16px;
}

.title_logo {
  width: 28px;
  height: 28px;
  margin-right: 8px;
  color: #1da1f2;
}

.loader {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.refresh_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 56px;
}

.refresh_click_area {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 18px */

  letter-spacing: normal;

  color: #e95844;
  margin-top: 8px;
}

.loader_image {
  height: 48px;
  width: 48px;
}

.refresh_icon {
  height: 72px;
  width: 72px;
  align-self: center;
}

.stackDiv {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.rightButton,
.leftButton {
  position: absolute;
  margin-top: 80px;
  height: 32px;
  width: 32px;
  background: rgba(104, 118, 132, 0.6);
  backdrop-filter: blur(8px);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.rightButton {
  right: 0;
}

.header {
  padding: 12px 20px;
  background: rgba(104, 118, 132, 0.04);
  border-bottom: 1px solid rgba(104, 118, 132, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.headerText {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 21px */

  letter-spacing: 0.01em;

  /* Text II */

  color: #687684;
}

.itemsList {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.followAllButton {
  cursor: pointer;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  text-align: right;
  letter-spacing: 0.01em;
  color: #0648d7;
}

.emptyState {
  padding-top: 116px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid rgba(104, 118, 132, 0.1);
  overflow: hidden;
  border-radius: 8px;
  width: 100%;
}

.emptyContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
}

.backButton {
  padding-left: 24px;
  padding-top: 92px;
}

@media (max-width: 768px) {
  .container {
    padding: 56px 25px 0 0;
  }
  .headerContainer {
    padding: 0;
  }
  .backButton {
    padding-left: 0;
  }
  .listContainer {
    margin: 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 16px;
}

.headerContainer {
  width: 100%;
}

.headerContent {
  text-align: center;
}

.title {
  font-family: "Catamaran", sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 130%;
  color: #1b1f22;
  text-align: left;
}

.subtitle {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 160%;
  color: #687684;
  text-align: left;
}

.searchContainer {
  margin-top: 24px;
  width: 100%;
}

.searchBox {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background: #6876840d;
  border-radius: 12px;
}

.searchIcon {
  color: #68768499;
}

.searchInput {
  border: none;
  background: none;
  outline: none;
  margin-left: 10px;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 160%;
  color: #68768499;
}

.listContainer {
  width: 100%;
  margin-top: 24px;
  background: #fff;
  border-radius: 12px;
  padding: 20px;
  overflow: scroll;
  height: 500px;
}

.proceedButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0648d7;
  color: #fff;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
  border: none;
  margin-top: 30px;
  border-radius: 8px !important;
  height: 58px !important;
}

.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

@media only screen and (max-width: 576px) {
  .container {
    margin-top: 12px;
    padding: 0 12px;
  }
}
