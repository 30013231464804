.container * {
  box-sizing: border-box;
}

.container {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.line {
  background: var(--text-2);
  opacity: 0.25;
  margin-top: 5px;
  height: 1.5px;
  width: 60px;
}

.graph-loading {
  width: 52px;
  height: 30px;
}

.graph-loading__bar {
  width: 6px;
  height: 30px;
  background: var(--text-2);
  opacity: 0.25;
  display: block;
  float: left;
  margin-left: 2px;
  margin-right: 2px;
  animation: graph-loader 2.5s infinite;
}

.graph-loading__bar:nth-of-type(1) {
  animation-delay: 0.2s;
}

.graph-loading__bar:nth-of-type(2) {
  animation-delay: 0.4s;
}

.graph-loading__bar:nth-of-type(3) {
  animation-delay: 0.6s;
}

.graph-loading__bar:nth-of-type(4) {
  animation-delay: 0.8s;
}

.graph-loading__bar:nth-of-type(5) {
  animation-delay: 1s;
}

@keyframes graph-loader {
  0% {
    height: 30px;
    margin-top: 0;
  }
  65% {
    height: 5px;
    margin-top: 25px;
  }
  100% {
    height: 30px;
    margin-top: 0;
  }
}
