.newPostWrapper {
    background: var(--base);
    padding: 14px 16px;

    font-family: Roboto;
    letter-spacing: normal;
    display: flex;
    gap: 20px;
}

.homePage {
    margin: 0px;
    border-bottom: 1px solid var(--border-light);
}

.newPostWrapper.isModal {
    padding: 0;
    border: 0;
}

.newPostWrapper.expanded {
    flex-direction: column;
}

.newPostWrapper.expanded .withImageAndLinks .textBoxWrapper {
    min-height: 150px;
    max-height: 250px;
    overflow: auto;
}

.newPostWrapper.default .contentWrapper {
    flex-grow: 1;
    align-items: center;
}

.newPostWrapper.default {
    justify-content: space-between;
}

.chooseImage {
    display: flex;
    align-items: center;
    padding: 0;
    background: none;
}

.chooseImage.compact {
    margin-top: 2px;
    align-items: flex-start;
}

.chooseImage:hover svg {
    color: var(--text-1);
}

.newPostWrapper.default .chooseEmojiWrapper button,
.newPostWrapper.default .chooseEmojiWrapper button:hover {
    /* height: 19px; */
    pointer-events: none;
}

.newPostWrapper.default .optionIcon {
    cursor: auto;
}

.emojiSelectOverlay {
    position: absolute;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    bottom: 0;
    cursor: pointer;
}

.emojiSelectOverlay:hover {
    background: var(--elevation-2);
}

.contentWrapper {
    display: flex;
    gap: 28px;
}

.contentWrapper .icon svg {
    margin-top: 2px;
    margin-left: 5px;
}

.errorText {
    color: var(--error);
    font-size: 14px;
    letter-spacing: normal;
}

.withImageAndLinks {
    line-height: 23px;
    font-weight: 400;
    font-size: 15px;
    width: 100%;
    word-break: break-word;
    overflow: auto;
}

.newPostWrapper.expanded {
    padding-bottom: 10px;
}

.newPostWrapper.expanded .buttonsWrapper {
    border-top: 1px solid var(--border-dark);
    padding-top: 11px;
}

.buttonsWrapper {
    display: flex;
    justify-content: space-between;
}

.optionsIcons {
    display: flex;
    align-items: center;
}

.optionIcon {
    background: none;
    display: flex;
    align-items: center;
}

.chooseEmojiWrapper button {
    background: var(--base);
    border: none;
    width: auto;
    display: flex;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
}

.chooseEmojiWrapper button:hover {
    background: var(--elevation-2);
}

div[class^="draftJsEmojiPlugin__emojiSelectPopover"] {
    background: var(--base);
    box-shadow: 0 4px 30px 0 var(--elevation-2);
    border: none;
}

.actionButtons {
    display: flex;
    gap: 24px;
    align-items: center;
    margin-left: 8px;
}

.actionButtons button {
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    font-family: Roboto;
}

.discardButton {
    color: var(--text-2);
    background: none;
    align-self: center;
}

.clearButton {
    color: var(--text-2);
    background: none;
    /* align-self: center; */
    font-weight: 400;
}

.postButton {
    color: var(--text-white);
    background: var(--primary-color);
    padding: 8px 16px;
    border-radius: 4px;
}

.postButton:disabled,
.disabled {
    cursor: auto;
    opacity: 0.5;
}

.imagePreviewWrapperParent {
    display: flex;
    gap: 8px;
    margin-top: 16px;
    flex-wrap: wrap;
}

.imagePreviewWrapper {
    position: relative;
}

.imagePreview {
    height: 60px;
    width: 60px;
    object-fit: cover;
}

.errorImage {
    border: 2px solid var(--error);
    border-radius: 4px;
}

.removeButton {
    position: absolute;
    top: -6px;
    right: -6px;
    display: flex;
    padding: 2px;
    border-radius: 50%;
    color: var(--text-1);
    background: var(--base);
    border: 1px solid var(--text-1);
}

.userAvatar {
    height: 34px;
    width: 34px;
    border-radius: 50%;
}

.farcasterDropdown {
    border-radius: 4px !important;
    width: 155px !important;
    border: 1px solid var(--Border-Dark, rgba(104, 118, 132, 0.1)) !important;
    background: var(--Elevation-I, rgba(104, 118, 132, 0.04)) !important;
}

.farcasterList {
    width: 155px !important;
    max-height: 300px;
    overflow-y: auto;
}

.gifBtn {
    background: var(--base);
    border-radius: 50%;
    width: 36px;
    height: 36px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gifBtn:hover {
    background: var(--elevation-2);
}

.scheduledDate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    font-size: 14px;
    color: var(--text-2);
    padding: 10px;
    background-color: var(--primary-color12);
}

.editButton {
    background: none;
    border: none;
    color: var(--primary-color);
    font-size: 14px;
    cursor: pointer;
}

.contentWrapper {
    max-height: 350px;
    overflow: auto;
    scrollbar-color: var(--text-2) transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 4px;
    }
}