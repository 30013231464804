.activityExtraInfo {
  display: flex;
  gap: 2px;
  align-items: center;
}
.chainIconsWrapper {
  display: flex;
  justify-content: center;
}
.chainIcon:not(:first-of-type) {
  margin-left: -5px;
}
.chainIcon {
  height: 20px;
  width: 20px;
  border: 1px solid var(--base);
  border-radius: 50%;
  background: var(--base);
}
.settingsIconWrapper {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
}

.popUpContainer {
  min-width: 160px;
  padding: 8px 16px 8px 16px;
}

@media (max-width: 768px) {
  .activityExtraInfo {
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
  }
}

.crossPostImage {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid var(--base);
  background: var(--base);
}
