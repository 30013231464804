.profile_pic {
  width: 72px;
  height: 72px;
  border-radius: 12px;
  background-color: var(--base);
  object-fit: cover;
}

.clampBio {
  display: -webkit-box;
  /* max-width: 300px; */
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.avatar_panel {
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  gap: 16px;
  align-items: flex-start;
}

.banner_picture {
  width: 100%;
  height: 140px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  object-fit: cover;
}

.left_panel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  margin-top: 8px;
}
.left_panel.no_actor_info {
  margin-top: 0;
  justify-content: center;
}

.right_panel {
  display: flex;
  flex-direction: row;
  min-width: 166px;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.title_row {
  display: flex;
  grid-gap: 6px;
  align-items: center;
  padding-bottom: 2px;
}

.bio {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-1);

  margin-top: 6px;

  word-break: break-word;
}
.alignLeft {
  text-align: left;
}
.noMargin {
  margin: 0;
}
.bio a {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--primary-color);
  cursor: pointer;
}

.bio a:hover {
  text-decoration: underline;
}

.top_panel {
  display: flex;
  flex-direction: row;
}

.profile_info {
  padding-top: 16px;
  display: block;
  background-color: var(--base);
  border-radius: 12px;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: normal;
  color: var(--text-1);
  max-width: 380px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.wallets {
  padding-top: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 130%;
  letter-spacing: normal;
  color: var(--text-2);
}

.active_since {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: normal;
  text-align: left;
  color: var(--text-2);
  display: flex;
  justify-content: space-between;
  padding: 9px 0;
  align-items: center;
}

.text,
.count {
  font-family: "Roboto";
  font-style: normal;
  font-size: 13px;
  line-height: 150%;
  text-align: right;
  letter-spacing: normal;
  color: var(--text-2);
}

.text {
  font-weight: 400;
}

.count {
  font-weight: 600;
  font-size: 13px;
  color: var(--text-2);
}

.inline_margin {
  margin-left: 20px;
}

.follow {
  background: #0648d7;
  border-radius: 20px;
  height: 20px;
  width: 72px;
  color: var(--text-white);

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 150%;
  /* identical to box height, or 16px */

  text-align: center;
}

.follow_panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
  margin-left: 8px;
}

.bundle_cta {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filter_wallet_icon {
  margin-right: 9px;
  filter: invert(51%) sepia(9%) saturate(804%) hue-rotate(169deg)
    brightness(86%) contrast(83%);
}

.aliases {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
  flex-wrap: wrap;
  max-width: 85%;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.single_wallet {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.padding_left {
  padding-left: 4px;
}

.chain_icon {
  height: 18px;
  width: 18px;
  margin-left: 8px;
}

.engagementsWrapper {
  gap: 8px;
}

@media (max-width: 768px) {
  .title {
    max-width: 35vw;
  }
}
@media (max-width: 600px) {
  .top_panel,
  .active_since {
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 16px;
  }
  .right_panel {
    justify-content: flex-start;
  }
}

.invite_details {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--text-2);
  font-size: 13px;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
}

.invited_users_count {
  display: flex;
  color: var(--primary-color);
  font-size: 13px;
  gap: 4px;
  padding: 0;
  font-family: "Roboto";
  background: transparent;
}

.padded_items {
  padding: 0px 20px 8px 20px;
}

.mutual_contacts_cont {
  padding: 16px 20px 0px 20px;
}

.mutual_contacts {
  padding: 10px 0px;
  font-family: "Roboto";
  font-size: 11px;
  font-weight: 400;
  line-height: 19.5px;
  letter-spacing: normal;
  text-align: left;
}

.follower_info {
  display: flex;
  align-items: center;
  padding-top: 2px;
}

.follower_info_item {
  display: flex;
  gap: 4px;
}

.dot,
.dotLight {
  display: inline-block;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background-color: var(--text-2);
  margin: 0 8px;
}

.dotLight {
  background-color: var(--text-3);
}

.cards {
  padding: 0 20px 16px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 800px;
  box-sizing: border-box;
  grid-gap: 16px;
}
.edit_profile_button {
  padding: 7px 16px !important;
}
