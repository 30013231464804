.page-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.page-content {
  border: 1px solid var(--elevation-2);
  overflow-x: auto;
  margin: 0 auto;
  background-color: var(--base);
  overflow-y: scroll;
  margin-left: -160px;
  width: 800px;
}

.page-right-bar {
  position: fixed;
  top: 52px;
  right: 0;
  width: 320px;
  border: 1px solid var(--elevation-2);
  background-color: var(--base);
  height: calc(100vh - 52px);
  overflow-y: scroll;
}

.page-right-bar::-webkit-scrollbar {
  display: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;
  width: 100%;
  margin-top: 4px;
}

.header-left {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.header-image {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.header-text {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.header-links {
  display: flex;
  align-items: center;
  column-gap: 16px;
  justify-self: flex-end;
}

.description-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 16px;
}

.holder-tab-container {
  margin-top: 24px;
}
.holder-tab-content {
  width: 100% !important;
}

.sale-card {
  padding: 0;
}

.recent-swaps-container {
  width: 100%;
  overflow-x: auto;
  display: flex;
  column-gap: 8px;
}

.price-info-container {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-left: 12px;
}

.price-tab-content {
  background: var(--base);
  /* border: 1px solid var(--elevation-2); */
  border-radius: 4px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.price-tab-container {
  margin-bottom: 24px;
}

/* loader */
.loader-container {
  display: grid;
  grid-template-columns: 7fr 3fr;
  height: 90vh;
  width: 100%;
}

.loader-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* background-color: red; */
}

.loader-header-buttons {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.loader-left,
.loader-right {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  border-right: 1px solid var(--elevation-2);
  padding-right: 12px;
}

.loader-tabs {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.loader-charts {
  height: 380px;
}

.loader-charts .loader-chart {
  transform: none;
  --webkit-transform: none;
}
