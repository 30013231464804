.main {
  flex: 1;
  width: 100%;
}

.title {
  margin: 20px 0px;
}

.title h1 {
  font-family: "Outfit", sans-serif;
  font-weight: 700;
  font-size: 28px;
  color: #68768499;
  margin: 0;
}

.title h2 {
  font-family: "Outfit", sans-serif;
  font-weight: 700;
  font-size: 28px;
  color: #1b1f22;
  margin-top: 8px;
}

.walletInfo {
  background-color: #6876840d;
  padding: 15px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 30px;
  position: relative;
  overflow: auto;
}

.walletProfile {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.wallet {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.walletName {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #1b1f22;
  margin-left: 5px;
}

.walletAddress {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #687684;
  margin-left: auto;
}

.avatar {
  width: 28px;
  height: 28px;
  border-radius: 8px;
  margin-right: 8px;
}

.walletRestrictions {
  padding: 20px;
  border-radius: 12px;
  margin-top: 30px;
  background: url("/src/assets/svg/wallet-error-bg.svg") no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
}

.walletRestrictions h3 {
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  font-size: 17px;
  color: #1b1f22;
}

.contentText {
  color: rgba(27, 31, 34, 1);
}

.retryButtonContainer {
  width: 100%;
}

.contentTextDone {
  color: #687684;
}

.points {
  padding-left: 0;
  min-height: 150px;
}

.restrictionItem {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
}

.text {
  color: black;
  font-size: 15px;
}

.restrictionItemValid {
  color: #587381;
}

.restrictionItemInvalid {
  color: #d9534f;
}

.retrySection {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.retryButton {
  background-color: #0648d7;
  width: 100%;
  min-width: 500px;
  height: 52px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 17px;
  color: #fff;
  border: none;
  border-radius: 12px;
  cursor: pointer;
}

.talkToUs {
  color: #0648d7;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 17px;
  margin-top: 15px;
  text-decoration: none;
  cursor: pointer;
}

.shimmerCont {
  display: flex;
  flex: 1;
  overflow: auto;
  position: relative;
  border-radius: 12px;
  min-height: inherit;
}

.walletCount {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: rgba(27, 31, 34, 1);
  margin-left: 4px;
}

@media only screen and (max-width: 576px) {
  .retryButton {
    min-width: 0;
    max-width: 400px;
  }
}
