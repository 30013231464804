.image {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);

  margin-left: 8px;
}

.profile_component {
  display: flex;
  flex-direction: row;
  align-items: center;
}
