@import "zxStyles/functions.scss";

.container {
  background-color: cv(elevation-1);
  color: cv(text-2);
  padding: 10px 12px;
  border-radius: 8px;
  width: 160px;
  height: 38px;
  cursor: pointer;
}

.menu {
  background-color: cv(elevation-1);
}

.menuItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 4px;
  width: 130px;
}
