.header {
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  align-items: center;
  cursor: pointer;
  letter-spacing: normal;
}

.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.green {
  background-color: var(--success-light);
  color: var(--success);
}

.red {
  background-color: var(--error-light);
  color: var(--error);
}

.content {
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid var(--elevation-1);
}
