.selected_node_transfers {
  margin-top: 20px;
  position: relative;
}

.rightPane {
  margin: 0;
  width: 100%;
}

.row {
  cursor: pointer;
}

.row:hover {
  background-color: var(--elevation-3);
}
