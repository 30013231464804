.profileInitial {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
}
.profileSeries {
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 50%;
  z-index: 2;
  border: 1px solid var(--base);
  background: var(--base);
}
