@import "zxStyles/functions.scss";

.container {
  background-color: cv(elevation-1);
  color: cv(text-2);
  padding: 10px 12px;
  border-radius: 8px;
  width: 80px;
  height: 38px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}

.active {
  background-color: cv(text-2);
}

.menu {
  background-color: cv(elevation-1);
}
.items {
  max-height: 400px;
  overflow-y: scroll;
}
.menuItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 4px;
  width: 200px;
}

.submit {
  padding: 8px 16px;
  width: 100%;
}

.closebtn {
  cursor: pointer;
  background-color: cv(elevation-1);
  border-radius: 32px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clearbtn {
  font-size: 11px;
  color: cv(primary-color);
  cursor: pointer;
}
