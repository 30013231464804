.popUpContainer {
  padding: 12px 20px;
  display: flex;
  flex-direction: column;
  min-width: 348px;
}

.peerIcon {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.devIconContainer {
  position: relative;
  margin-right: 16px;
  margin-top: 1px;
  padding-top: 4px;
  padding-right: 2px;
  height: 27px;
  width: 27px;
}

.devIcon {
  border: 1px solid var(--success);
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.devImpersonatorIcon {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 1px solid var(--success);
  display: flex;
  align-items: center;
}

.icon {
  height: 12px;
  width: 12px;
  border-radius: 50%;
}
