.groupAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--border-dark);
  cursor: pointer;

  &:hover {
    border-color: var(--primary-color60);
  }
}

.groupAvatarWrapper {
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
}
.groupAvatarFallback {
  display: flex;
  width: 40px;
  height: 40px;
  border: 1px solid var(--border-dark);
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: var(--primary-color60);
  }
}
