.arguments {
  word-break: break-all;
}

.code {
  border-top: 1px solid var(--border-dark);
  border-left: 1px solid var(--border-dark);
  background: var(--elevation-1);
  padding: 12px 16px;
  padding-right: 24px;
  max-height: 280px;
  overflow-x: auto;
  overflow-y: auto;
  border-radius: 4px 0 0 0px;
  scrollbar-width: thin;
}

.code::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.code::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.code:hover::-webkit-scrollbar-thumb {
  background-color: var(--text-3);
  border-radius: 20px;
}

.code::-webkit-scrollbar-track {
  background: transparent;
}
.codeText {
  color: var(--text-1);
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 23.4px */
  letter-spacing: normal;
  white-space: pre-wrap;
  word-wrap: break-word;
}
