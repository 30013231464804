.right_panel {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 7;
  display: flex;
  flex-direction: row;
}

.rightPanelGlass {
  flex: 1;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
}

.rightPanelContent {
  height: 100%;
  width: 37.5%;
  background: var(--base);
  overflow: auto;
  box-shadow: 0px 0px 16px var(--border-dark);
}

@media (max-width: 768px) {
  .rightPanelGlass {
    display: none;
  }
  .rightPanelContent {
    width: 100%;
  }
}

.panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-width: 500px;
  background: var(--base), var(--elevation-1);
  border-radius: 10px;
  /* padding-bottom: 86px; */
  overflow: auto;
}
.panel input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.panel input[type="number"] {
  -moz-appearance: textfield;
}
.header {
  display: flex;
  flex-direction: column;
  background: var(--base);
  border-bottom: 1px solid var(--elevation-2);
  padding: 24px 20px 24px 20px;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  padding-bottom: 20px;
  letter-spacing: normal;
  color: var(--text-1);
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: var(--text-2);
}
