@import "zxStyles/functions.scss";
.container {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
.card {
  background-color: cv(base);
  padding: 12px;
  height: auto;
  border-radius: 8px;
}

.liquidity {
  border-right: 1px solid cv(border-light);
}

.buySellBar {
  width: 120px;
  height: 3px;
  border-radius: 2px;
}
.bar {
  width: 100%;
  height: 3px;
}
.buyBar {
  width: 100%;
  background-color: cv(success);
  height: 3px;
  border-radius: 3px;
}

.sellBar {
  background-color: cv(error);
  height: 3px;
  border-radius: 3px;
}
