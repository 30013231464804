.container {
  padding: 8px 0px;
  padding-right: 16px;
}

.bundle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 16px */

  letter-spacing: normal;

  color: var(--text-2);
  background-color: inherit;
}
.bundle:hover {
  text-decoration: underline;
  text-decoration-color: var(--text-2);
}

.discard {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;
  /* identical to box height, or 16px */

  letter-spacing: normal;
  text-transform: uppercase;

  /* Grey */

  color: #687684;
  background-color: inherit;
  margin-left: 8px;
  width: 50px;
}

.description {
  flex-grow: 1;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  letter-spacing: normal;

  /* Grey */

  color: var(--text-2);
}

.hoverCard {
  min-width: 300px;
  min-height: 80px;
}

.list_item {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */

  letter-spacing: normal;

  /* Grey */

  color: var(--text-1);
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
}

.dot {
  margin-right: 8px;
  margin-left: 8px;
}

.list {
  margin: 0;
  padding: 0;

  margin-top: 16px;
  list-style-position: outside;
  list-style-type: disc;
}

.verticalDivider {
  width: 1px;
  height: 20px;
  background: var(--border-dark);
}

.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}
