.token-container {
  display: flex;
  flex-direction: column;
  column-gap: 4px;
  overflow-y: auto;
}

.token-details {
  display: flex;
  justify-content: space-between;
}

.token-details-left {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.token-details-right {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.token-image {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.token-name {
  display: inline-block;
  max-width: 60px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.token-price-chart-container {
  margin-left: 28px;
  display: flex;
  justify-content: space-between;
}

.token-price-details {
  display: flex;
  column-gap: 4px;
  align-items: center;
  justify-self: flex-end;
  text-align: right;
}
