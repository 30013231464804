.envelopeContainer {
  position: relative;
  height: 308px;
  width: 568px;
  display: flex;
  align-items: stretch;
}

.envelope {
  height: 100%;
  width: 568px;
  height: 308px;
  z-index: 1;
  object-fit: fill;
  background: var(--background);
  box-shadow: 0px -2px 16px 0px #ffffff03;
}

.envelopeItems {
  position: absolute;
  top: 0;
  height: 308px;
  width: 568px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 40px;
}

.divider {
  width: 16px;
  height: 1px;
  background: var(--text-2);
  opacity: 0.24;
}

.welcome {
  color: var(--text-2);
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 21px;
  letter-spacing: 1.4px;
  word-wrap: break-word;
}

.name {
  color: var(--text-1);
  font-size: 24px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 36px;
  word-wrap: break-word;
}

.dividerLong {
  width: 100%;
  height: 1px;
  margin: 0px 20px;
  background: var(--border-dark);
}

.commenterProfilePic {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}
.newCommentWrapper {
  align-self: baseline;
}

.commentsList {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.addCommentButton {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: var(--elevation-1);
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid var(--border-dark);
}

.joinedInvitedByTile {
  background: var(--success);
  border: 1px solid var(--border-dark);
  border-radius: 1px;
}
.commentsWrapper {
  width: 100%;
}
.comment {
  align-items: center;
}
.mobile_welcome_wrapper {
  display: none;
}
.welcomeBio {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;
  letter-spacing: normal;
  color: var(--text-1);
  margin-top: 4px;
  word-break: break-word;
  text-align: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 33px;
}

.welcomeBio a {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;
  letter-spacing: normal;
  color: var(--primary-color);
  cursor: pointer;
}

.welcomeBio a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .envelopeContainer {
    max-height: 250px;
  }
  .envelope {
    width: 100%;
    object-fit: initial;
  }
  .newCommentWrapper .commenterProfilePic {
    margin-top: 8px;
  }
  .newCommentWrapper {
    max-width: 72vw;
    align-self: unset;
  }
  .commentsWrapper {
    max-width: 78vw;
    flex-wrap: wrap;
    padding: 8px 4px;
  }
  .comment {
    background: var(--elevation-1);
    padding: 4px 8px;
    border-radius: 40px;
  }
  .paddingTop,
  .paddingBottom,
  .name,
  .welcomeBio,
  .welcomeWrapper {
    visibility: hidden;
  }
  .envelopeItems {
    height: auto;
    width: 100%;
    flex-direction: column-reverse;
    padding: 8px 4px;
    align-items: center;
  }
  .mobile_welcome_wrapper {
    display: flex;
  }
  .actorName {
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-basis: 18%;
  }
  .descriptionWrapper {
    flex-basis: 60%;
  }
  .timeLabel {
    white-space: pre;
  }
}
