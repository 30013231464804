.rightPanel {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: var(--elevation-1);
  border-radius: 10px;
  margin-bottom: 20px;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: var(--elevation-1);
  padding: 16px 20px 16px 20px;
  border-radius: 10px;
  gap: 16px;
}

.header {
  display: flex;
  flex-direction: column;
  background: var(--base);
  padding: 0;
}
.header .wrapper {
  display: flex;
  flex-direction: column;
  padding: 24px 20px 0 20px;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  /* or 23px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.subtitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;

  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.crossIcon {
  margin-right: 8px;
  margin-bottom: 16px;
  cursor: pointer;
  height: 16px;
  width: 16px;
}

.tabbar {
  background: "transparent";
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  bottom: 0;
  gap: 20px;
  left: 0;
  right: 0;
  padding: 0 20px;
  border-bottom: 1px solid var(--border-dark);
}

.tabbarlink {
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */
  letter-spacing: normal;
  /* Text II */
  color: var(--text-2);
  padding-bottom: 4px;
}

.active {
  cursor: pointer;
  border-bottom: 4px solid var(--primary-color);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;
  align-items: bottom;

  /* Text I */

  color: var(--text-1);
}

.panelDetailsPadding,
.portfolioWrapper {
  padding: 16px 20px 16px 20px;
}
