.net_worth_card_token {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  align-items: center;
  gap: 4px;
}

.icon.icon {
  height: 20px;
  width: 20px;
  margin-right: 2px;
  border-radius: 50%;
}

.chain.chain {
  height: 10px;
  width: 10px;
}

.name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
}

.text_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.value {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  text-align: right;
  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
  margin-left: 24px;
  width: 50px;
}

.values {
  display: flex;
}
