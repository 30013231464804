.icon {
  display: flex;
  align-items: center;
  height: 18px;
  width: 18px;
}
.imageIcon {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
}

.socials {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.verticalDivider {
  display: flex;
  height: 20px;
  width: 1px;
  background: var(--border-dark);
}

.round {
  border-radius: 24px;
}
