@import "zxStyles/functions.scss";

.container {
  margin: 12px;
  display: flex;
  column-gap: 12px;
  margin-left: 12px;
  width: 100%;
}

@media only screen and (max-width: 1400px) {
  .container {
    width: calc(100vw - 100px);
    margin-left: -170px;
    justify-content: space-between;
  }
}

.content {
  background-color: cv(base);
  padding: 12px;
  height: auto;
  border-radius: 8px;
}

.content {
  flex: 1;
}
.rightBar {
  width: 264px;
}
