.wallets {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding-bottom: 56px;
  font-family: Roboto;
}

.walletsBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 16px 0px;
  background: var(--base);
  border: 1px solid var(--border-dark);
  border-radius: 6px;
  font-family: Roboto;
}

.header {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: top;
}

.header .labelContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  letter-spacing: normal;
}
.header .labelContainer .label {
  font-size: 15px;
  color: var(--text-1);
}
.header .labelContainer .totalUsdValue {
  font-size: 15px;
  color: var(--text-2);
}

.header .updatedAt {
  font-size: 13px;
  font-weight: 400;
  color: var(--text-3);
  letter-spacing: normal;
}

.header .labelContainer .text_count {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text II */

  color: var(--text-2);
}

.iconContainer {
  display: "flex";
  align-items: "center";
}

.icon {
  width: 20px;
  height: 20px;
  fill: var(--text-1);
  color: var(--text-1);
}
