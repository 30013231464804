.searchResultsContainer {
  position: absolute;
  top: 38px;
  border: 1px solid var(--border-light);
  padding: 4px 0;
  width: 100%;
  list-style: none;
  box-shadow: 0px 0px 16px var(--elevation-1);
  background: var(--base);
  border-radius: 4px;
  z-index: 5;
}
.noResultsItem {
  padding: 8px 16px;
  display: flex;
  gap: 12px;
  font-family: "Roboto";
  align-items: center;
  font-size: 13px;
  color: var(--text-2);
  letter-spacing: 0;
}
.noResultIcon {
  display: flex;
}

.noResultImage {
  height: 70px;
  width: 70px;
}
