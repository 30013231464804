.table {
  display: flex;
  flex-direction: column;
  border-collapse: collapse;
  width: 100%;
}

.table .tableList {
  font-size: 12px;
  color: var(--text-2);
  letter-spacing: 0.08em;
  height: auto;
  margin-bottom: 8px;
}

.reset_button {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.table .tableRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  gap: 16px;
  padding: 8px 10px 8px 20px;
  &:hover {
    background-color: var(--primary-color10);
  }
}

.table .tableHeader {
  font-size: 12px;
  color: var(--text-2);
  font-weight: 500;
  letter-spacing: 0.08em;
  border-bottom: 1px solid var(--border-dark);
  text-align: left;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  padding: 8px 20px;
}

.table .tableRow .tableCell {
  padding: 10px 0;
  color: var(--text-1);
}
.tableCell:nth-of-type(1) {
  flex-basis: 17.3%;
}
.tableCell:nth-of-type(2) {
  flex-basis: 32%;
}
.tableCell:nth-of-type(3) {
  flex-basis: 9.8%;
}
.tableCell:nth-of-type(4) {
  flex-basis: 16.6%;
}
.tableCell:nth-of-type(5) {
  flex-grow: 1;
}
.alignRight {
  text-align: right;
}
.alignLeft {
  text-align: left;
}
