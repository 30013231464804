.mediaTypeImage {
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.fallbackImage {
  width: auto;
  height: 60px;
}
.mediaTypeVideo {
  height: 100%;
  width: 100%;
}

.mediaPlaceholder {
  height: 100%;
  width: 100%;
}

.mediaPlaceholder .label {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  color: #fff;
  background: #0000003d;
  display: flex;
  align-items: center;
  justify-content: center;
}
