.mainContent {
  width: 100%;
  flex-grow: 1;
  padding: 24px 0;
  background: #f8f8f9;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainTitle {
  font-family: "Roboto";
  font-weight: 600;
  font-size: 20px;
  letter-spacing: normal;
  line-height: 130%;
  color: #1b1f22;
}

.walletsSection {
  width: 100%;
  margin-top: 20px;
}

.moreWalletsSection {
  width: 100%;
  margin-top: 40px;
}

.walletsSection h2,
.moreWalletsSection h2 {
  font-family: "Catamaran", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #1b1f22;
  margin-bottom: 16px;
}

.walletInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border: 2px solid #6876841a;
  border-radius: 12px;
}

.walletAddress {
  display: flex;
  align-items: center;
}

.walletAddress span {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #1b1f22;
}

.walletAmount {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: #687684ff;
}

.continueButton {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0648d7;
  color: #fff;
  border-radius: 12px;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
  border: none;
  margin-top: 15px;
  border-radius: 12px !important;
  height: 58px !important;
}

.banner {
  width: 100%;
  margin-bottom: 20px;
}

@media only screen and (max-width: 576px) {
  .walletsSection {
    margin-top: 0px;
  }
}
