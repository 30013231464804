.container {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  width: 100%;
  overflow-x: auto;
  padding: 0 16px;
}
.container::-webkit-scrollbar {
  display: none;
}

.tokenInfo {
  padding-top: 2px;
}

// .groupWrapper {
//   display: flex;
//   gap: 16px;
//   margin-top: 12px;
//   padding-bottom: 16px;
//   overflow-x: auto;
// }

// .groupWrapper::-webkit-scrollbar {
//   height: 4px;
// }
// .groupWrapper::-webkit-scrollbar-thumb {
//   background: var(--elevation-2);
//   border-radius: 100px;
// }
// .groupWrapper::-webkit-scrollbar-thumb:hover {
//   background: var(--text-3);
// }

// .group {
//   background-color: var(--base);
//   padding: 16px;
//   border-radius: 8px;
//   border: 1px solid var(--border-color);
//   width: 324px;
//   flex-shrink: 0;
// }
// .group:first-child {
//   margin-left: 16px;
// }
// .group:last-child {
//   margin-right: 16px;
// }

// .activity {
//   padding: 16px 0;
//   margin-top: 8px;
//   border-top: 1px solid var(--border-color);
// }

// .profileImage {
//   width: 28px;
//   aspect-ratio: 1;
//   border-radius: 50%;
// }

// .profileImageBig {
//   width: 36px;
//   aspect-ratio: 1;
//   border-radius: 50%;
// }

// .truncate {
//   display: -webkit-box;
//   -webkit-box-orient: vertical;
//   -webkit-line-clamp: 1;
//   overflow: hidden;
//   word-break: break-all;
// }

// .chainIcon {
//   width: 20px;
//   height: 20px;
// }
