.deleteDialog {
  font-family: "Roboto";
  letter-spacing: normal;
}
.dialogTitle {
  font-size: 15px;
  font-weight: 600;
  color: var(--text-1);
}
.dialogContent {
  font-size: 14px;
  color: var(--text-2);
}
.dialogButton {
  font-family: "Roboto";
  background: none;
  padding: 8px 12px;
  font-size: 15px;
  font-weight: 500;
}
.cancelButton {
  color: var(--text-1);
}
.confirmButton {
  color: var(--error);
}

.discardButton {
  color: var(--primary-color);
  background: none;
  font-weight: 400;
}

.saveButton {
  color: var(--base);
  background-color: var(--primary-color);
  padding: 8px 16px;
  border-radius: 8px;
}

.saveButton [disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
