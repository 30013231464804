.add_to_existing_profile_form {
  display: flex;
  flex-direction: column;
  width: 300px;

  border-radius: 6px;

  background: var(--navBg);
  backdrop-filter: blur(16px);

  /* Border Dark */

  border: 1px solid var(--border-dark);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(5px);
  /* Note: backdrop-filter has minimal browser support */

  padding: 20px;

  min-height: 300px;

  /* Note: backdrop-filter has minimal browser support */
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  letter-spacing: normal;

  /* Text I */

  color: var(--text-1);
  margin-left: 4px;
}
.icon {
  color: var(--text-1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.search_icon {
  color: var(--text-3);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.profiles {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  /* identical to box height, or 15px */

  letter-spacing: 0.08em;
  text-transform: uppercase;

  /* Text II */

  color: var(--text-2);

  margin-top: 16px;
  margin-bottom: 8px;
}

.search_results {
  max-height: 200px;
  overflow-y: scroll;
}
