.rightPanel {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 37.5%;
  background: var(--elevation-1);
  padding-bottom: 100px;
  position: fixed;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.header {
  display: flex;
  flex-direction: column;
  background: var(--base);
  border-bottom: 1px solid var(--elevation-2);
  padding: 24px 20px 0px 20px;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  padding-bottom: 8px;
  letter-spacing: normal;
  color: var(--text-1);
  flex-grow: 1;
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--text-2);
}

.notificationDot {
  border-radius: 9px;
  border: 2px solid var(--base);
  background: var(--error);

  font-size: 12px;
  font-weight: 600;
  color: white;
  padding: 2px 10px;
  flex-shrink: 0;
  border-radius: 14px;
}

.spanDot {
  border-radius: 50%;
  height: 4px;
  width: 4px;
  background: var(--text-2);
}

.tab {
  font-size: 13px;
  background-color: var(--base);
  border-radius: 4px;
  font-weight: 500;
  padding: 8px;
  cursor: pointer;
  color: var(--text-2);
}

.selected {
  background-color: #e1e8f7;
  color: var(--primary-color);
}

.userAvatar {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.userAvatarSmall {
  height: 24px;
  width: 24px;
  border-radius: 50%;
}

.chevronIcon {
  height: 12px;
  width: 12px;
}

.manageBundles {
  border: 1px solid var(--border-dark);
  border-radius: 24px;
  padding: 0px 16px 0px 12px;
}
