@import "zxStyles/functions.scss";
.filterContainer {
  display: grid;
  grid-template-columns: 4fr 1fr;
  column-gap: 16px;
  margin-bottom: 12px;
}

.allContainer {
  grid-template-columns: 4fr 1fr 0.5fr;
}

.filterPills {
  display: flex;
  align-items: flex-end;
  gap: 8px;
  flex-wrap: nowrap;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
}

.filterPill {
  background-color: cv(elevation-2);
  padding: 8px;
  border-radius: 4px;
  color: cv(text-2);
  font-size: 12px;
  min-width: 120px;
  // width: max-content;
  height: fit-content;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 4px;
}
